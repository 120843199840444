import React from 'react'
import PropTypes from 'prop-types'
import { t } from '../../packs/lib/i18n.js'
import { truncate } from '../../packs/lib/string.js'
import axios from '../../packs/lib/axios.js'

/*eslint-disable no-unused-vars*/
import ShowChatContent from './ShowChatContent.jsx'
import InviteBox from '../../packs/components/InviteBox'
/*eslint-enable no-unused-vars*/

class ShowChat extends React.Component {
  constructor() {
    super()

    this.lastRequestId = null
    this.afterMessageSubmit = this.afterMessageSubmit.bind(this)
    this.requestChatData = this.requestChatData.bind(this)

    this.state = {
      status: 'loading',
      title: t('messages.show_chat.empty_title'),
      requesterId: -1,
      messages: [],
      withCompanyId: null,
      timeSliceId: null,
      online: false,
      meetings_start: '',
      meetings_end: '',
      online_meetings_start: '',
      online_meetings_end: ''
    }
  }

  requestChatData() {
    if (this.lastRequestId) {
      clearTimeout(this.lastRequestId)
      this.lastRequestId = null
    }

    this.setState({ status: 'loading' })

    this.lastRequestId = setTimeout(() => {
      const { id } = this.props
      const url = `/messages/${id}.json`

      axios
        .get(url)
        .then(({ data }) => {
          const {
            title,
            requesterId,
            messages,
            timeSliceId,
            withCompanyId,
            withCompanyName,
            online,
            meetings_start,
            meetings_end,
            online_meetings_start,
            online_meetings_end
          } = data

          if (typeof window !== 'undefined' && typeof window.history.pushState === 'function') {
            if (window.ignorePushState !== true) {
              history.pushState({
                component: 'Messages',
                messageId: this.props.id
              }, title, url.replace('.json', ''))
            }

            const domTitle = document.querySelector('title')
            const titlePrefix = domTitle.innerHTML.split(' | ')[0]
            domTitle.innerHTML = `${titlePrefix} | ${title}`
          }

          this.setState({
            status: 'loaded',
            title,
            requesterId,
            messages,
            timeSliceId,
            withCompanyId,
            withCompanyName,
            online,
            meetings_start,
            meetings_end,
            online_meetings_start,
            online_meetings_end
          })
        })
        .catch(() => {
          this.setState({ status: 'error' })
        })
    }, 250)
  }

  UNSAFE_componentWillMount() {
    this.requestChatData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id === this.props.id) {
      return
    }

    this.requestChatData()
  }

  componentWillUnmount() {
    if (this.lastRequestId) {
      clearTimeout(this.lastRequestId)
    }
  }

  afterMessageSubmit(data) {
    const messages = [].concat(this.state.messages, [data])
    this.setState({ messages })
    this.props.afterMessageSubmit()
  }

  render() {
    let titleLen = 25
    if (typeof window !== 'undefined') {
      const winwid = window.innerWidth

      if (winwid >= 1100) {
        titleLen = 72
      } else if (winwid >= 960) {
        titleLen = 58
      } else if (winwid >= 720) {
        titleLen = 34
      }
    }

    let dates = {
      meetings: {
        start_at: this.state.meetings_start,
        end_at: this.state.meetings_end
      },
      online_meetings: {
        start_at: this.state.online_meetings_start,
        end_at: this.state.online_meetings_end
      }
    }

    return (
      <>
        {
          this.state.status !== 'loading' && this.state.withCompanyId
            ? (
              <InviteBox
                userId={this.state.requesterId}
                companyId={this.state.withCompanyId}
                sliceId={this.state.timeSliceId}
                dates={dates}
                online={this.state.online}
                chat
              />
            )
            : ''
        }
        <section className="c-messages__current-chat c-messages__current-chat--active">
          <header className="c-messages__current-chat__header">
            <button
              type="back"
              className="c-btn c-btn--negative c-btn--sm1 c-btn--borderless"
              onClick={(event) => {
                event.preventDefault()
                this.props.backToList()
              }}
            >&laquo;</button>

            {
              this.state.status === 'loading'
                ? <h1>&hellip;</h1>
                : <h1>{truncate(this.state.title, titleLen)} <span className="c-messages__current-chat-company">(<a href={'/companies/' + this.state.withCompanyId}>{this.state.withCompanyName}</a>)</span></h1>
            }
          </header>

          <main className="c-messages__current-chat__content">
            <div className="c-messages__show-chat">
              <ShowChatContent
                chatId={this.props.id}
                requesterId={this.state.requesterId}
                status={this.state.status}
                messages={this.state.messages}
                afterMessageSubmit={this.afterMessageSubmit}
              />
            </div>
          </main>
        </section>
      </>
    )
  }
}

ShowChat.propTypes = {
  id: PropTypes.number.isRequired,
  backToList: PropTypes.func.isRequired,
  afterMessageSubmit: PropTypes.func.isRequired,
}

export default ShowChat
