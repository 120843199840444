/* global React, PropTypes, i18n, swal */

const t = i18n.t

class TicketReadMoreButton extends React.Component {
  constructor() {
    super()

    this.openText = this.openText.bind(this)
  }

  async openText() {
    await swal({
      customClass: ['ticket-read-more-content'],
      html: this.props.description,
      title: this.props.name,
      type: 'info'
    })
  }

  render() {
    return <div className="u-mt1 ticket-read-more">
      <button className="c-btn c-btn--sm1" onClick={this.openText}>
        {t('ticket_read_more')}
      </button>
    </div>
  }
}

TicketReadMoreButton.propTypes = {
  description: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default TicketReadMoreButton
