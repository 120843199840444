/* global React, i18n, swal, axios */

const t = i18n.t

class DeleteAccountButton extends React.Component {
  constructor() {
    super()

    this.onClick = this.onClick.bind(this)
  }

  refreshPage() {
    window &&
      window.location &&
      window.location.href &&
      window.location.reload()
  }

  async onClick() {
    const confirmation = await swal({
      title: t('delete_account.modal_title'),
      text: t('delete_account.modal_text'),
      type: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('delete_account.confirm'),
      cancelButtonText: t('delete_account.cancel'),
    })

    if (!confirmation.value) {
      return
    }

    try {
      const response = await axios.delete(`/accounts/${this.props.userId}`)

      if (!response.data.success) {
        throw new Error(response.data.error)
      }

      this.refreshPage()
    } catch(err) {
      swal(t('error'), err.message, 'error')
    }
  }

  render() {
    return <button type="alert" className="c-btn c-btn--lg1 c-btn--secondary" style={{float: "right"}} onClick={this.onClick}>
      {t('delete_account.button')}
    </button>
  }
}

DeleteAccountButton.propTypes = {
  userId: PropTypes.number,
}

export default DeleteAccountButton
