import React from 'react'
import Modal from 'react-modal'
import urlParse from 'url-parse'
import PropTypes from 'prop-types'

import * as dom from '../../../lib/dom.js'
import { t } from '../../../lib/i18n.js'
import { truncate } from '../../../lib/string.js'
import RegisterBtn from '../RegisterBtn'

const iconGeolocation = `
  <svg id="location-icon" version="1.1"
  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
  x="0px" y="5px" viewBox="0 0 494.9 499" style="enable-background:new 0 0 494.9 499;"
  xml:space="preserve">
    <defs>
    </defs>
    <g id="layer1_1_" transform="translate(0,-151.4791)" inkscape:groupmode="layer" inkscape:label="Layer 1">
        <path id="path22_1_" inkscape:connector-curvature="0" inkscape:transform-center-x="-1.6682435" inkscape:transform-center-y="-3.030805" sodipodi:nodetypes="scsss" d="
        M228.4,650.4c-7.3,4.2-60.8-166.9-60.8-166.9S-4,443.2,0.1,435.6c8-15,487.9-290.4,494.7-284C501.6,158,243.2,641.9,228.4,650.4z"
        />
    </g>
  </svg>
`

const Speaker = ({ speaker, aTarget }) => (
  <a
    href={speaker.path}
    target={aTarget}
    className="c-fellow-speakers__profile">
    <div className="c-fellow-speakers__profile__img-wrapper">
      <img src={speaker.picture} />
      {
        speaker.is_moderator &&
        <small className="c-fellow-speakers__profile__mod">
          {t('schedule.talk.mod')}
        </small>
      }
    </div>

    <p>{speaker.artistic_name ? speaker.artistic_name : speaker.name}</p>
    {
      speaker.company && !speaker.artistic_name
        ? <small>({speaker.company})</small>
        : ''
    }
  </a>
)

class Talk extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      showVideo: false
    }

    console.log("props", props);
    this.talkBox = React.createRef()
    this.loggedIn = this.loggedIn.bind(this)
  }

  loggedIn() {
    if (this.props.forIframe === true) {
      return false
    }

    return this.props.userSignedIn === true
  }

  renderVideo = (url) => {
    const parsedUrl = urlParse(url, true);
    let videoId;
    let startTime;

    if (parsedUrl.pathname.includes('/live/')) {
      videoId = parsedUrl.pathname.split('/').pop();
    } else if (parsedUrl.query.v) {
      videoId = parsedUrl.query.v;
    } else {
      return <div>Invalid YouTube URL</div>;
    }

    if (parsedUrl.query.t) {
      startTime = parsedUrl.query.t;
    }

    let embedUrl = `https://www.youtube.com/embed/${videoId}`;
    if (startTime) {
      embedUrl += `?start=${startTime}&autoplay=1&controls=0`;
    }

    return (
      <Modal
        isOpen={true}
        onRequestClose={() => this.setState({ showVideo: false })}
        contentLabel="Exemplo Modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          },
          content: {
            maxWidth: '605px',
            maxHeight: '365px',
            margin: 'auto',
            padding: '20px',
            zIndex: 1001,
          },
        }}
      >
        <iframe
          width="560"
          height="315"
          src={embedUrl}
          title="YouTube Video Embed"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Modal>
    );
  }

  render() {
    let {
      id,
      path,
      time,
      day,
      dayMonth,
      title,
      promoPicture,
      description,
      location,
      categories,
      speakers,
      isAttendee,
      csrfToken,
      timeSliceId,
      userCanAttend,
      userSignedIn,
      forIframe,
      placesLeft,
      fullTalkWarning,
      bookingEnabled,
      bookingDisabledWarning,
      videoLink
    } = this.props

    const targetBlank = forIframe === true
    const aTarget = targetBlank ? '_blank' : '_self'
    const truncLimit = this.state.open ? 200 : 70

    return (
      <div ref={this.talkBox} className="c-speaker__talk">
        {isAttendee && this.loggedIn() && <p className="c-alert">{t('schedule.talk.attending')}</p>}
        <div
          onClick={() => {
            dom.toggleClass(this.talkBox.current, 'js-open')
            this.setState({ open: !this.state.open })
          }}
          className="c-speaker__talk-info"
        >
          <div className="c-speaker__talk-group">
            <div className="c-speaker__talk-date-time">
              <div className="c-speaker__talk-month-day">
                <b>{dayMonth}</b>
              </div>
              <div className="c-speaker__talk-time">
                {time}
              </div>
            </div>
            <div className="c-speaker__talk-title">
              <h3>
                { promoPicture && <img src={promoPicture} width="42" /> }
                <span>{ title && truncate(title, truncLimit)}</span>
              </h3>
              {videoLink &&
                <a href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    this.setState({ showVideo: true })
                  }}
                  className="c-btn c-btn__attendee">
                  <span>{t('schedule.talk.view')}</span>
                </a>
              }
              <span className="js-close">&times;</span>
            </div>
          </div>
          <p className="c-speaker__talk-location">
            <span dangerouslySetInnerHTML={{ __html: iconGeolocation }}></span>
            {
              location.link && location.link.trim() !== ''
                ? <span>{location.name} <a href={location.link} target="_blank">(link)</a></span>
                : <span>{location.name}</span>
            }
            <br/>
            <small>{t('schedule.talk.full_occ', { num: location.full_occupation })}</small>
          </p>
        </div>

        <div className="c-speaker__talk-details">
          <div className="c-speaker__talk-description">
            <div className="c-fellow-speakers">
              {
                speakers.map((speaker) => (
                  <Speaker key={speaker.id} speaker={speaker} aTarget={aTarget} />
                ))
              }
              {speakers.length > 3 &&
                  <a href={path} className="c-fellow-speakers__more" target="_blank">{t('schedule.talk.more_speakers')}</a>}
            </div>
            <div className="c-speaker__talk-description-group">
              <p>{description && truncate(description, 180)}</p>
              <p className="c-speaker__talk-tags">
                {categories.map(category => (
                  <span
                    key={category}
                    className="c-speaker__talk-tag">
                    {category}
                  </span>
                ))}
              </p>
              <div className="c-speaker__talk__btns">
                {!videoLink &&
                <RegisterBtn
                  id={id}
                  csrfToken={csrfToken}
                  isAttendee={isAttendee}
                  forIframe={forIframe}
                  timeSliceId={timeSliceId}
                  userCanAttend={userCanAttend}
                  userSignedIn={userSignedIn}
                  fullTalkWarning={fullTalkWarning}
                  placesLeft={placesLeft}
                  bookingEnabled={bookingEnabled}
                  bookingDisabledWarning={bookingDisabledWarning}
                />
                }
                {videoLink &&
                  <a href="#"
                    onClick={(e) => {
                      e.preventDefault()
                      this.setState({ showVideo: true })
                    }}
                    className="c-btn c-btn__attendee">
                    <span>{t('schedule.talk.view')}</span>
                  </a>
                }
                <a href={path} className="c-btn__see-more" target={aTarget}>
                  {t('schedule.read_more')}
                </a>
              </div>
            </div>
          </div>
        </div>

        {this.state.showVideo && this.renderVideo(videoLink)}
      </div>
    )
  }
}

Talk.propsTypes = {
  path: PropTypes.string,
  time: PropTypes.string,
  day: PropTypes.string,
  title: PropTypes.string,
  csrfToken: PropTypes.string,
  isAttendee: PropTypes.string,
  forIframe: PropTypes.boolean,
  timeSliceId: PropTypes.integer,
  userCanAttend: PropTypes.boolean,
  placesLeft: PropTypes.integer,
  description: PropTypes.string,
  fullTalkWarning: PropTypes.string,
  videoLink: PropTypes.string,
  location: PropTypes.object,
  bookingEnabled: PropTypes.boolean,
  bookingDisabledWarning: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  speakers: PropTypes.shape({
    id: PropTypes.integer,
    name: PropTypes.string,
    picture: PropTypes.string,
    path: PropTypes.string,
    company: PropTypes.string,
    company_path: PropTypes.string,
    is_moderator: PropTypes.boolean,
    artistic_name: PropTypes.string,
  })
}

export default Talk
