import axios from '../../packs/lib/axios.js'

const fetchMeetingUserStatus = async (params, meetingId) => {
  let data
  let path = '/time_slices/meetings/' + meetingId + '.json'

  try {
    const response = await axios.get(path, { params })
    data = response.data
  } catch(_e) {
    return { status: 'error' }
  }

  let status
  for (let user in data.users) {
    if (data.users[user].id === params.host) {
      status = data.users[user].status
      break
    }
  }

  return status
}

export default fetchMeetingUserStatus
