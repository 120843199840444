const gatherOnlineInformation = ({ hostId, selectedGuestsIds, allUsers, eventAttendanceType }) => {
  const isOnlineUser = x => x.attendance_type === 'online'

  const onlineHost = isOnlineUser(allUsers.find(x => x.id === hostId))
  const participantIds = selectedGuestsIds.concat([hostId])
  const participants = allUsers.filter(x => participantIds.includes(x.id))
  const allParticipantsAreOnline= participants.every(isOnlineUser)
  const someParticipantsAreOnline = participants.some(isOnlineUser) && !allParticipantsAreOnline
  const onlineEvent = eventAttendanceType === 'online'
  const onlineMeeting = onlineEvent || someParticipantsAreOnline || allParticipantsAreOnline

  return {
    onlineMeeting,
    onlineHost,
    allParticipantsAreOnline,
    someParticipantsAreOnline,
    onlineEvent
  }
}

export default gatherOnlineInformation
