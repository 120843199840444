/* global dom, i18n, swal */

import { DirectUpload } from '@rails/activestorage'

const t = i18n.t

class Uploader {
  constructor(file, input) {
    this.input = input
    this.url = dom.data(this.input, 'direct-upload-url')
    this.file = file
    this.box = dom.parents(this.input, 'js-uploader')
    this.progressBar = this.box.querySelector('.c-form__uploader__progress')
    this.uploader = new DirectUpload(this.file, this.url, this)
  }

  preview() {
    const reader = new FileReader()

    reader.onload = (event) => {
      const dataUrl = event.target.result
      this.box.querySelector('img').setAttribute('src', dataUrl)
    }

    reader.readAsDataURL(this.file)
  }

  fileSize(format = 'mb') {
    const sizeInBytes = this.file.size
    const sizeInKb = sizeInBytes / 1000
    const sizeInMb = sizeInKb / 1000

    if (format === 'kb') {
      return sizeInKb
    }

    if (format === 'mb') {
      return sizeInMb
    }

    return sizeInBytes
  }

  sizeIsValid() {
    const maxSizeStr = dom.data(this.input, 'max-size', '20mb')
    const format = maxSizeStr.replace(/[\d]/gi, '')
    const maxSize = parseInt(maxSizeStr.replace(/[\D]/gi, ''), 10)
    const currentSize = this.fileSize(format)
    const valid = currentSize <= maxSize

    if (!valid) {
      swal(t('attention'), t('file_max_size', { size: maxSizeStr }), 'warning')
    }

    return valid
  }

  typeIsValid() {
    if (this.input.accept.includes("image")) {
      return this.file.type.includes("image")
    }

    return true
  }

  upload() {
    if (!this.sizeIsValid()) {
      return
    }

    if (!this.typeIsValid()) {
      return
    }

    const form = dom.parents(this.input, 'c-form')

    this.uploader.create((error, blob) => {
      this.progressBar.style.width = '0%'

      if (error) {
        return
      }

      this.preview()

      form
        .querySelectorAll(`input[name="${this.input.name}"][type="hidden"]`)
        .forEach((el) => el.parentNode.removeChild(el))

      // Add an appropriately-named hidden input to the form with a
      //  value of blob.signed_id so that the blob ids will be
      //  transmitted in the normal upload flow

      const hiddenField = document.createElement('input')
      hiddenField.setAttribute('type', 'hidden')
      hiddenField.setAttribute('value', blob.signed_id)
      hiddenField.name = this.input.name
      form.appendChild(hiddenField)

      const evt = new Event('uploader:revalidateForm')
      form.dispatchEvent(evt)
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', (event) => {
      this.directUploadDidProgress(event)
    })
  }

  directUploadDidProgress(event) {
    const prog = parseInt(100.0 * event.loaded / event.total, 10)
    this.progressBar.style.width = `${prog}%`
  }
}

const renderUploader = (input) => {
  if (input.getAttribute('multiple') === 'multiple') {
    return
  }

  input.addEventListener('change', () => {
    Array.from(input.files).forEach(file => {
      const uploader = new Uploader(file, input)
      uploader.upload()
    })

    input.value = null
  })
}

document.addEventListener('i18n:onReady', () => {
  const uploaderInputs = document.querySelectorAll('[data-direct-upload-url]')
  dom.forEach(uploaderInputs, renderUploader)
})
