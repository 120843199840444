/* global dom */

import getCheckoutType from './getCheckoutType.js'
import { removeCoupon } from './coupon.js'

const hide = (el) => el.style.display = 'none'
const show = (el) => el.style.display = 'block'

const handleDisabledState = (isDisabled = true) => {
  const order = document.querySelector('.c-ticketeer__order')
  const agreements = document.querySelector('.c-ticketeer__agreements')
  const btn = document.querySelector('.c-form--ticket-choices button[type="submit"]')

  if (isDisabled) {
    [order, agreements, btn].forEach(hide)
    return
  }

  order.style.display = 'flex'
  btn.style.display = 'inline-block'
  ;[agreements].forEach(show)
}

export default function() {
  const items = document.querySelectorAll('.js-payment-method')

  if (items.length === 0) {
    return
  }
  
  const checkoutType = getCheckoutType()
  const summary = document.querySelector('.c-ticketeer__summary')
  const coupon = document.querySelector('.js-payment-method--coupon')
  const paymentProcessors = document.querySelector('.c-ticketeer__processors')
  const couponBtn = coupon.querySelector('button')
  
  removeCoupon()
  
  handleDisabledState(checkoutType === 'disabled')
  
  if (checkoutType === 'couponOnly') {
    [coupon, summary].forEach(show)
    
    if (couponBtn)
      couponBtn.style.display = 'inline-block'

    return
  }

  if (checkoutType === 'free') {
    hide(summary)
    hide(paymentProcessors)
    dom.forEach(items, hide)

    return
  }

  dom.forEach(items, show)
  ;[summary, paymentProcessors].forEach(show)
  couponBtn.style.display = 'inline-block'
}
