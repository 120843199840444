/* global dom */

const simulateOnChange = (element) => {
  if ("createEvent" in document) {
    var evt = document.createEvent("HTMLEvents");
    evt.initEvent("change", false, true);
    element.dispatchEvent(evt);
    return
  }

  element.fireEvent("onchange");
}

dom.ready(() => {
  setTimeout(() => {
    if (window.occupation_area_selected_ids) {
      for (let id of occupation_area_selected_ids) {
        const el = document.querySelector(`[data-select-selector=occupation_area_input_${id}]`)
        if (el) {
          el.checked = true
          simulateOnChange(el)
        }
      }
    }

    if (window.project_type_selected_ids) {
      for (let id of project_type_selected_ids) {
        const el = document.querySelector(`[data-select-selector=project_type_input_${id}]`)
        if (el) {
          el.checked = true
          simulateOnChange(el)
        }
      }
    }
  }, 300)
})
