import parse from 'date-fns/parse'
import format from 'date-fns/format'
import sub from 'date-fns/sub'
import add from 'date-fns/add'

const parseDateString = (string) => parse(string, 'yyyy-MM-dd HH:mm:ss', new Date())

const toShortDate = (datetime) => {
  const date = parseDateString(dateString)

  return format(date, 'dd/MM')
}

const dateToShortDatetime = (date) => format(date, 'dd/MM HH:mm')

const toShortDatetime = (dateString) => {
  const date = parseDateString(dateString)
  return dateToShortDatetime(date)
}

const intDiff = (x, y) =>
  (Math.sign(x) === Math.sign(y))
  ? Math.abs(x - y)
  : Math.abs(x) + Math.abs(y)

const offsetToMinutes = (offset) => {
  const multiplier = offset.includes('-') ? -1 : 1
  const [hours, minutes] = offset.replace(/[\-\+]/g, '').split(':')

  return multiplier * ((parseInt(hours, 10) * 60) + parseInt(minutes, 10))
}

const convertTimezones = (fromDateString, fromOffsetString, toOffsetString) => {
  const fromOffset = offsetToMinutes(fromOffsetString)
  const toOffset = offsetToMinutes(toOffsetString)
  const diff = intDiff(fromOffset, toOffset)
  const fromDate = parseDateString(fromDateString)
  let toDate = fromDate

  if (fromOffset > toOffset) {
    toDate = sub(fromDate, { minutes: diff })
  } else if (fromOffset < toOffset) {
    toDate = add(fromDate, { minutes: diff })
  }

  return toDate
}

export {
  toShortDate,
  toShortDatetime,
  convertTimezones,
  parseDateString,
  dateToShortDatetime
}
