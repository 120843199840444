/* global i18n */

const t = i18n.t

const TableAlert = ({ buyableSpotsCount }) => {
  if (buyableSpotsCount <= 0) {
    return ''
  }

  return <div className="c-alert">
    {t('auto_spot')}
    <div>
      <br/>
      {t('do_you_want_a_prefixed_table', { count: buyableSpotsCount })} <a href="/ticket_choices/new">{t('click_to_upgrade')}</a>
    </div>
  </div>
}

export default TableAlert
