/* global swal, i18n, dom, mmAvailableAt */

const t = i18n.t

const simpleRegisterWarning = async function() {
  const result = await swal({
    title: t('hi_whatsup'),
    type: 'info',
    text: t('to_do_this_simple'),
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: t('take_me_to_registration'),
    footer: `<a href="/auth/users/sign_in">${t('or_login')}</a>`,
    cancelButtonText: 'OK',
  })

  if (result.value) {
    window.location = '/accounts/new'
    return
  }

  return ''
}

const askCompanyPermission = function() {
  swal(t('hi_whatsup'),
    t('ask_company_admin_permission'),
    'info')
}

const customMessageAlert = function(message) {
  swal(t('hi_whatsup'),
    message,
    'info')
}

const mmDisabledWarning = function() {
  swal(t('hi_whatsup'),
    `${t('match_making_available_at')} ${mmAvailableAt}`,
    'info')
}

const noCompanyWarning = async function() {
  const result = await swal({
    title: t('hi_whatsup'),
    type: 'info',
    text: t('need_company_to_invite'),
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: t('take_me_to_company_choices'),
    cancelButtonText: 'OK',
  })

  if (result.value) {
    window.location = '/company_choices/new'
    return
  }

  return ''
}

const registerWarning = async function() {
  const result = await swal({
    title: t('hi_whatsup'),
    type: 'info',
    text: t('to_do_this'),
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: t('take_me_to_registration'),
    footer: `<a href="/auth/users/sign_in">${t('or_login')}</a>`,
    cancelButtonText: 'OK',
  })

  if (result.value && !window.userSignedIn) {
    window.location = '/accounts/new'
    return
  }

  if (result.value) {
    window.location = '/ticket_choices/new'
    return
  }

  return ''
}

const alerts = {
  registerWarning,
  noCompanyWarning,
  mmDisabledWarning,
  simpleRegisterWarning,
  askCompanyPermission
}

const alertBtnClick = (event) => {
  event.preventDefault()
  const target = dom.findTarget(event.target, 'c-btn')
  const alert = dom.data(target, 'alert')
  const customMessage = dom.data(target, 'custom-message')

  if (!alert) {
    return
  }

  if (customMessage && customMessage !== '') {
    customMessageAlert(customMessage)
    return
  }

  if (typeof alerts[alert] === 'undefined') {
    return
  }

  alerts[alert]()
}

const btns = document.querySelectorAll('[data-alert]')

if (btns.length > 0) {
  for (let i = 0; i < btns.length; i += 1) {
    btns[i].addEventListener('click', alertBtnClick)
  }
}
