const dottify = (name) => {
  return name.replace(/\[/gi, '.')
    .replace(/\]/gi, '')
    .replace(/\.$/gi, '')
}

const bracketify = (name) => {
  let hasDot = /\./gi.test(name)
  if (!hasDot) { return name }

  name = name.split('.')
  let firstItem = name.shift()
  name = name.join('][')
  name = `${firstItem}[${name}]`

  return name
}

export {
  dottify,
  bracketify
}
