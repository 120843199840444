/* global i18n */

const t = i18n.t

const GuestName = ({ guest, eventAttendanceType }) => {
  const className = 'c-modal__content__guests__guest-data'
  if (eventAttendanceType === 'physical') {
    return <span className={className}>{guest.company_name}</span>
  }

  if (eventAttendanceType === 'online') {
    return <span className={className}>
      <strong>{guest.company_name}</strong>
      <small>{t('time_zone')}: UTC{guest.time_zone_utc_offset}</small>
    </span>
  }

  return <span className={className}>
    <strong>{guest.company_name}</strong>
    {
      !guest.accept_meetings &&
      <small className="u-c-danger">{t('not_accept_meetings')}</small>
    }
    <small>
      {t(`attendance_type.${guest.attendance_type}`)} -
      {guest.can_be_invited ? `${t('time_zone')}: UTC${guest.time_zone_utc_offset}` : t('cannot_be_invited')}
    </small>
  </span>
}

export default GuestName
