/*eslint-disable no-unused-vars*/
import React from 'react'
import ReactDOM from 'react-dom'
import Omnibox from '../components/Omnibox.jsx'
/*eslint-enable no-unused-vars*/

export default function() {
  const omnibox = document.querySelector('.js-react-omnibox')
  if (omnibox) {
    const term = omnibox.getAttribute('data-term')
    ReactDOM.render(<Omnibox term={term} />, omnibox)
  }
}
