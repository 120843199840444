/* eslint-disable */

/*
 * Table of contents:
 * .index(element)
 * .setData(element)
 * .allData(element)
 * .data(element, name, dataType (optional))
 * .hasClass(element, klass)
 * .addClass(element, klass)
 * .removeClass(element, klass)
 * .toggleClass(element, klass)
 * .findTargetByTagName(element, tagName)
 * .parents(element, klass)
 * .findTarget(element, klass)
 * .forEach(elements, callback)
 * .remove(elements)
 * .removeAll(elements)
 * .ready(callback)
 * .bindForEach(elements, eventName, callback)
 * .getFormFieldValue(element)
 *

/*
 * Get element index inside a div (counting from 0)
 * Example:
 * <div>
 *  <div/> -> 0
 *  <div/> -> 1
 *  ...
 * <div/> -> n
 * </div>
 */
export function index(element) {
  const sib = element.parentNode.childNodes;
  let n = 0;

  for (let i = 0; i < sib.length; i += 1) {
    if (sib[i] === element) {
      return n;
    }

    if (sib[i].nodeType === 1) {
      n += 1;
    }
  }

  return -1;
}

/*
 * Adds a data attribute to an element.
 * Example:
 * setData(el, { la: 'la', di: 'di' } inserts data-la="la" and data-di="di" as
 * el attrs
 */
export function setData(el, obj) {
  if (!el) {
    console.warn("Tryed to setData to blank el. El:", el);
    return;
  }

  Object.keys(obj).forEach(key => {
    const value = obj[key];
    const dataKey = `data-${key}`;

    if (value === false) {
      el.removeAttribute(dataKey);
      return;
    }

    if (value === true) {
      el.setAttribute(dataKey, "true");
      return;
    }

    el.setAttribute(dataKey, value);
  });
}

/*
 * Gets all data-* attributes from el
 * <div data-la="la" data-di="di"></div>
 * yields
 * { la: 'la', di: 'di' }
 */
export function allData(el) {
  const attrs = el.attributes;
  let data = {};

  for (let i = 0; i < attrs.length; i = i + 1) {
    const item = attrs[i];
    const name = item.name;

    if (!name.includes("data-")) {
      continue;
    }

    const key = name
      .replace("data-", "")
      .split("-")
      .reduce((accumulator, currentValue, currentIndex) => {
        if (currentIndex === 0) {
          return currentValue.toLowerCase();
        }

        currentValue =
          currentValue[0].toUpperCase() +
          currentValue.slice(1, currentValue.length);

        return (accumulator += currentValue);
      }, "");
    data[key] = item.value;
  }

  return data;
}

/*
 * Gets a single data-attribute from element
 * By default it coerces the value to string, but you can also
 * use integer and boolean as a dataType
 */
export function data(el, name, dataType = "string") {
  const val = el.getAttribute(`data-${name}`);
  if (typeof val === "undefined") {
    return null;
  }

  if (dataType === "integer") {
    return parseInt(val, 10);
  }
  if (dataType === "boolean") {
    return val === "true";
  }

  return val;
}

// Returns a boolean that says if element has the class
export function hasClass(el, klass) {
  if (el.classList) {
    return el.classList.contains(klass);
  }

  return new RegExp(`(^| )${klass}( |$)`, "gi").test(el.className);
}

// Adds class to element
export function addClass(el, klass) {
  if (hasClass(el, klass)) {
    return false;
  }

  if (el.classList) {
    return el.classList.add(klass);
  }

  const newKlass = `${el.className} ${klass}`;
  el.className = newKlass;

  return newKlass;
}

// Removes class from element
export function removeClass(el, klass) {
  if (!el) {
    return false;
  }

  if (!hasClass(el, klass)) {
    return false;
  }

  if (el.classList) {
    return el.classList.remove(klass);
  }

  const reg = new RegExp(`(^|\\b)${klass.split(" ").join("|")}(\\b|$)`, "gi");
  el.className = el.className.replace(reg, " ");

  return true;
}

// Removes class if present, adds it if not
export function toggleClass(el, klass) {
  hasClass(el, klass) ? removeClass(el, klass) : addClass(el, klass);
}

/* Finds an element using it's tag name
 * It starts at the given element and goes up
 * in the DOM tree untill it finds the given tagName
 */
export function findTargetByTagName(el, tagName) {
  if (!el) {
    return null;
  }

  if (el.tagName === tagName) {
    return el;
  }

  let targetEl = null;
  let parentEl = el.parentNode;

  let loopIndex = 0;

  while (targetEl === null) {
    loopIndex += 1;
    if (loopIndex > 3000) {
      break;
    }
    if (!parentEl) {
      break;
    }

    if (parentEl.tagName === "BODY" && tagName !== "BODY") {
      break;
    }

    if (parentEl.tagName !== tagName) {
      parentEl = parentEl.parentNode;
    } else {
      targetEl = parentEl;
    }
  }

  return targetEl;
}

/*
 * Finds an element's parent by using a class
 * It starts at the first parent element and goes up
 * in the DOM tree untill it finds the given klass
 */
export function parents(el, klass) {
  if (!el) {
    return null;
  }

  let targetEl = null;
  let parentEl = el.parentNode;

  let loopIndex = 0;

  while (targetEl === null) {
    loopIndex += 1;
    if (loopIndex > 3000) {
      break;
    }
    if (!parentEl) {
      break;
    }

    if (parentEl.tagName === "BODY" && !hasClass(parentEl, klass)) {
      break;
    }

    if (!hasClass(parentEl, klass)) {
      parentEl = parentEl.parentNode;
    } else {
      targetEl = parentEl;
    }
  }

  return targetEl;
}

/* Finds an element recursively by using a class
 * It's most common use is to find the clicked element,
 * when the eventListener is at an element with children
 */
export function findTarget(el, klass) {
  if (hasClass(el, klass)) {
    return el;
  }
  return parents(el, klass);
}

/*
 * Loops through a list of node elements
 */
export function forEach(els, callback) {
  for (let i = 0; i < els.length; i += 1) {
    callback(els[i]);
  }
}

/*
 * Removed element from DOM
 */
export function remove(el) {
  if (!el) {
    return;
  }

  el.parentNode.removeChild(el);
}

/*
 * Removes a set of node elements from DOM
 */
export function removeAll(els) {
  forEach(els, remove);
}

/*
 * Execute a function after DOM is ready
 */
export function ready(fn) {
  if (
    document.attachEvent
      ? document.readyState === "complete"
      : document.readyState !== "loading"
  ) {
    fn();
    return;
  }

  document.addEventListener("DOMContentLoaded", fn);
}

/*
 * Binds an event (evt. eg click) fn to a sequence of elements
 */
export function bindForEach(els, evt, fn) {
  forEach(els, el => el.addEventListener(evt, fn));
}

export function getFormFieldValue(element) {
  if (element.nodeName === 'SELECT') {
    return element.options[element.selectedIndex].value
  }

  return element.value
}
