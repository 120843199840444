/* global dom */

import currentTicketInput from './currentTicketInput.js'

export default function() {
  const input = currentTicketInput()
  const data = dom.allData(input)
  
  const free = data.free === 'true'
  const couponOnly = data.couponOnly === 'true'

  if (dom.hasClass(input.parentNode, 'c-ticketeer__option--disabled')) {
    return 'disabled'
  }

  if (couponOnly) {
    return 'couponOnly'
  }

  if (free) {
    return 'free'
  }

  return 'paid'
}
