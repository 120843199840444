// See more about creating validators on ./export.js

import { bracketify } from '../lib/stringFns.js'

export default function(value, options, fieldName) {
  fieldName = bracketify(fieldName)
  const targetFieldName = bracketify(options.targetName)
  const targetMustHaveValue = options.targetMustHaveValue
  const targetChecked = options.targetChecked
  const field = document.querySelector(`[name="${fieldName}"]`)
  const target = document.querySelector(`[name="${targetFieldName}"]`)

  if (!target) {
    return 'presenceIf'
  }

  if (typeof targetChecked !== 'undefined'
      && target.checked != targetChecked) {
      return null
  }

  if (targetMustHaveValue) {
    if (Array.isArray(targetMustHaveValue)) {
      targetMustHaveValue.forEach(element => {
        if (target.value.trim() !== element) {
          return null
        }
      })
    } else {
      if (target.value.trim() !== targetMustHaveValue) {
        return null
      }
    }
  } else {
    if (target.value.trim() === '') {
      return null
    }
  }

  if (field && field.value.trim() !== '') {
    return null
  }

  return 'presenceIf'
}
