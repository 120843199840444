import localforage from 'localforage'
import axios from './axios'

const version = '0044'
const { locale, env } = window
window.i18n = null

export async function init() {
  const cacheKey = `translations_${locale}_${version}`
  const cache = await localforage.getItem(cacheKey)
  const isReadyEvent = document.createEvent('Event')
  isReadyEvent.initEvent('i18n:onReady', true, true)

  if (cache && env !== 'development') {
    window.i18n = cache
    document.dispatchEvent(isReadyEvent)
    return
  }

  const timestamp = (env === 'development') ? new Date().getTime() : version

  try {
    const response = await axios.get(`/translations/${locale}?t=${timestamp}`)
    await localforage.setItem(cacheKey, response.data.javascript)
    window.i18n = response.data.javascript
    document.dispatchEvent(isReadyEvent)
  } catch(err) {
    window.i18n = null
    document.dispatchEvent(isReadyEvent)
  }
}

const replaceStringVars = (string, vars) => {
  const keys = Object.keys(vars)

  keys.forEach((key) => {
    const replace = `%{${key}}`
    const reg = new RegExp(replace, 'g')
    string = string.replace(reg, vars[key])
  })

  return string
}

export function t(key, vars = {}) {
  if (!window.i18n) {
    return key
  }

  let translation = window.i18n
  let keys = key.split('.')

  while (keys.length > 0) {
    if (typeof translation === 'undefined') {
      translation = key
      break
    }

    translation = translation[keys.shift()]
  }

  if (typeof translation === 'undefined') {
    return key
  }

  return replaceStringVars(translation, vars)
}
