/* global dom */

import { UserAgent } from '@sentry/browser/dist/integrations'
import { bracketify } from './stringFns.js'

const clearErrors = (errors) => {
  const dotNames = Object.keys(errors)
  const name = bracketify(dotNames[0])
  let field = document.querySelector(`[name="${name}"]`)

  if (!field) {
    field = document.querySelector(`[name="${name}[]"]`)
  }

  const form = dom.parents(field, 'c-form')
  const errorEls = form.querySelectorAll('.c-form__field__error')
  dom.removeAll(errorEls)

  const parentEls = form.querySelectorAll('.c-form__field--warning')
  dom.forEach(parentEls, (el) => dom.removeClass(el, 'c-form__field--warning'))
}

export default function (errors) {
  if (errors === 'clear_errors') {
    const errorEls = document.querySelectorAll('.c-form__field__error')
    dom.removeAll(errorEls)
    const parentEls = document.querySelectorAll('.c-form__field--warning')
    dom.forEach(parentEls, (el) => dom.removeClass(el, 'c-form__field--warning'))
    return
  }

  const dotNames = Object.keys(errors)

  clearErrors(errors)

  dotNames.forEach((dotName) => {
    const name = bracketify(dotName)

    let fields = document.querySelectorAll(`[name="${name}"]`)

    if (!fields || fields.length === 0) {
      fields = document.querySelectorAll(`[name="${name}[]"]`)
    }

    fields.forEach((field) => {
      const placeErrorIn = dom.data(field, 'place-error-in') || 'c-form__field'
      let parent = dom.parents(field, placeErrorIn)

      if (!parent) {
        return
      }

      dom.addClass(parent, 'c-form__field--warning')
      const fieldErrors = errors[dotName]

      fieldErrors.forEach((error) => {
        const el = document.createElement('SPAN')
        el.setAttribute('class', 'c-form__field__error')
        el.innerHTML = error

        if (field.name === "user[picture]" || field.name === "company[picture]") {
          el.classList.add('c-form__field__error__bigger')
        }

        parent.appendChild(el)
      })
    })
  })
}
