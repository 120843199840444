import * as dom from '../lib/dom.js'
import { t } from '../lib/i18n.js'
import axios from '../lib/axios.js'
import swal from 'sweetalert2'

const htmlModalWrapper = (name, timeSliceId) => {
  return `
    <section class="c-modal c-modal--send-message">
      <div class="c-modal__wrapper">
        ${timeSliceId && `<input type="hidden" name="chat[time_slice_id]" value="${timeSliceId}" />`}

        <header class="c-modal__header">
          <h1>${t('send_message_to', { name })}</h1>

          <button type="close">&times;</button>
        </header>

        <div class="c-modal__content c-send-message">
          <h3 class="js-send-message--counterparts-title">${t('message_counterparts')}</h3>

          <div class="js-send-message--counterparts">
            <p class="c-send-message__empty">${t('loading')}&hellip;</p>
          </div>

          <h3>${t('insert_your_message')}</h3>
          <div class="c-form__field">
            <textarea name="chat[initial_message]"></textarea>
          </div>
        </div>

        <footer class="c-modal__footer u-ta-right">
          <button class="c-btn c-btn--secondary c-btn--lg1" type="close">${t('cancel')}</button>
          <button class="c-btn c-btn--lg1 c-btn--disabled" type="submit" disabled>${t('send_message')}</button>
        </footer>
      </div>
    </section>
  `
}

const closeAllModals = (event) => {
  if (typeof event !== 'undefined') {
    event.preventDefault()
  }

  const modals = document.querySelectorAll('.c-modal')
  dom.forEach(modals, (modal) => dom.remove(modal))
}

const userIds = () => {
  let ids = []
  const modal = document.querySelector('.c-modal')
  const inputs = modal.querySelectorAll('input')

  dom.forEach(inputs, (input) => {
    if (input.getAttribute('type') === 'checkbox') {
      if (!input.checked) {
        return
      }
    }

    ids.push(input.value)
  })

  return ids
}

const initialMessage = () => {
  const modal = document.querySelector('.c-modal')
  const textArea = modal.querySelector('textarea')
  return textArea.value.trim()
}

const timeSliceId = () => {
  const modal = document.querySelector('.c-modal')
  const input = modal.querySelector('[name="chat[time_slice_id]"]')

  if (input) {
    return { time_slice_id: input.value }
  }

  return {}
}

const manageSubmitBtn = () => {
  const modal = document.querySelector('.c-modal')
  const btn = modal.querySelector('button[type="submit"]')

  if (userIds().length === 0 || initialMessage() === '') {
    btn.disabled = true
    dom.addClass(btn, 'c-btn--disabled')
    return
  }

  btn.disabled = false
  dom.removeClass(btn, 'c-btn--disabled')
}

const submit = async function(event) {
  event.preventDefault()

  const modal = document.querySelector('.c-modal')
  const btn = modal.querySelector('button[type="submit"]')

  btn.disabled = true
  dom.addClass(btn, 'c-btn--loading')

  const postData = {
    chat: {
      user_ids: userIds(),
      initial_message: initialMessage(),
      ...timeSliceId()
    }
  }

  const response = await axios.post('/messages', postData)
  const data = response.data

  if (!data.success) {
    dom.removeClass(btn, 'c-btn--loading')
    dom.addClass(btn, 'c-btn--disabled')
    swal(t('error'), data.error, 'error')
    return
  }

  btn.disabled = false
  dom.removeClass(btn, 'c-btn--loading')

  closeAllModals()

  const confirmation = await swal({
    title: t('message_created'),
    text: t('do_you_wish_to_go_to_message'),
    type: 'success',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#155724',
    confirmButtonText: t('yes_take_me_there'),
    cancelButtonText: t('stay_here')
  })

  if (confirmation.value) {
    window.location = data.url
    return
  }
}

const bindEvents = () => {
  const modal = document.querySelector('.c-modal')
  const closeBtns = modal.querySelectorAll('button[type="close"]')
  const textArea = modal.querySelector('textarea')
  const submitBtn = modal.querySelector('button[type="submit"]')

  dom.bindForEach(closeBtns, 'click', closeAllModals)
  textArea.addEventListener('keyup', manageSubmitBtn)
  textArea.addEventListener('blur', manageSubmitBtn)
  submitBtn.addEventListener('click', submit)
}

const loadUsers = async function(companyId, selectedUserIds) {
  let html = ''
  const target = document.querySelector('.js-send-message--counterparts')

  if (selectedUserIds) {
    const title = document.querySelector('.js-send-message--counterparts-title')
    dom.addClass(title, 'u-hidden')

    const ids = selectedUserIds.split(',')
    html = ids
      .map((id) => `<input type="hidden" name="chat[user_ids][]" value="${id}" />`)
      .join('')
  } else {
    const response = await axios.get('/helper_apis/company_available_users', {
      params: {
        company_id: companyId
      }
    })

    if (response.data.length === 0) {
      target.innerHTML = `<p class="c-send-message__empty>${t('no_users_available')}</p>`
      return
    }

    html = response
      .data
      .map((user) => {
        return `<li>
          <div class="c-form__field c-form__field--checkbox">
            <label for="user_message_${user.id}">
              <input id="user_message_${user.id}"
                     type="checkbox"
                     name="chat[user_ids][]"
                     value="${user.id}">
              <span>${user.name}</span>
            </label>
          </div>
        </li>
        `
      })
      .join('')
  }

  target.innerHTML = `<ul class="c-send-message__users u-plain-list">${html}</ul>`

  setTimeout(() => {
    const modal = document.querySelector('.c-modal')
    const inputs = modal.querySelectorAll('input')
    dom.bindForEach(inputs, 'change', manageSubmitBtn)
  }, 200)
}

const openModal = (event) => {
  event.preventDefault()
  closeAllModals()

  const btn = dom.findTarget(event.target, 'js-send-message')
  const companyId = dom.data(btn, 'company-id', 'integer')
  const companyName = dom.data(btn, 'company-name')
  const selectedUserIds = dom.data(btn, 'selected-user-ids')
  const timeSliceId = dom.data(btn, 'time-slice-id')

  const elementBed = document.createElement('DIV')
  elementBed.innerHTML = htmlModalWrapper(companyName, timeSliceId)
  document.body.appendChild(elementBed.children[0])
  bindEvents()
  loadUsers(companyId, selectedUserIds)
}

const btns = document.querySelectorAll('.js-send-message')
dom.forEach(btns, (btn) => {
  btn.addEventListener('click', openModal)
})

