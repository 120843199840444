/* global React, PropTypes, i18n, swal, axios */

/*eslint-disable no-unused-vars*/
import GuestName from './InviteBox/FirstStep/GuestName.jsx'
/*eslint-enable no-unused-vars*/

const t = i18n.t

class EditHostsBox extends React.Component {
  constructor() {
    super()

    this.updateHost = this.updateHost.bind(this)
    this.onStartLoading = this.onStartLoading.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onModalClose = this.onModalClose.bind(this)
    this.onPostUpdate = this.onPostUpdate.bind(this)
    this.isSaveButtonDisabled = this.isSaveButtonDisabled.bind(this)
    this.errors = []
    this.state = {
      status: 'loading',
      data: {
        hostUser: {},
        hostPossibleUsers: [],
        hostInvitedUsers: [],
        checkedUserId: 0,
      },
      errors: {},
    }
  }

  componentDidMount() {
    this.data()
  }

  async data() {
    if (this.state.status !== 'loading') {
      return ''
    }

    try {
      const invitedGuestsResponse = await axios.get(`/time_slices/meetings/${this.props.sliceId}/invited_guests.json`)
      const invitedGuestsData = invitedGuestsResponse.data
      const hostId = invitedGuestsData.host_id
      const availableUsersResponse = await axios.get(`/time_slices/meetings/${this.props.sliceId}/available_users.json`,
        { params: {
          user_id: hostId,
          company_id: this.props.targetCompanyId
        } }
      )
      const availableUsersData = availableUsersResponse.data
      const hostData = availableUsersData.host_company_users.find((x) => x.id === hostId)

      this.setState({
        status: 'loaded',
        data: {
          hostUser: hostData,
          hostPossibleUsers: availableUsersData.host_company_users,
          hostInvitedUsers: invitedGuestsData.host_company_guests,
          checkedUserId: hostId
        },
      })
    } catch (err) {
      this.setState({ status: 'error' })
    }
  }

  onHostChange(guest) {
    const newState = this.state
    newState.data.checkedUserId = guest.id
    this.setState(newState)
  }

  hostGuests() {
    return this.state.data.hostPossibleUsers.filter((x) => x.id !== this.state.data.hostId)
  }

  render_error() {
    return <div className="c-modal__content c-edit_guests">
      <span>{t('error_please_try_later')}</span>
    </div>
  }

  render_loading() {
    return <div className="c-modal__content c-edit_guests">
      <span>{t('loading')}&hellip;</span>
    </div>
  }

  emptyGuestColumn() {
    return <div className="c-modal__content c-invite-modal__edit_guests">
      <div className="c-modal__content__guests">
      </div>
    </div>
  }

  filledGuestColumn(possibleGuestList, title, key) {
    return <div key={key}>
      <h3>{title}</h3>
      <ul>
        {
          possibleGuestList.map((guest) => (
            <li key={`invite-modal-guest-${guest.id}`}>
              <label>
                <input type="radio"
                  onChange={() => this.onHostChange(guest)}
                  checked={guest.id === this.state.data.checkedUserId}
                  disabled={!guest.accept_meetings || !guest.can_be_invited || !guest.available || !guest.match_maker}
                />
                <GuestName guest={guest} eventAttendanceType={window.eventAttendanceType} />
              </label>
            </li>
          ))
        }
      </ul>
    </div>
  }

  hostGuestsColumn() {
    if (this.hostGuests().length === 0) {
      return this.emptyGuestColumn()
    }

    return this.filledGuestColumn(this.state.data.hostPossibleUsers, t('edit_guests.guests_from', { company: this.props.hostCompanyName }), 'host-guests')
  }

  render_complete_page() {
    return <React.Fragment>
      <section className="c-modal c-modal--time-slice" onClick={(event) => this.onModalClose(event)}>
        <div className="c-modal__wrapper" onClick={(event) => event.stopPropagation()}>
          <header className="c-modal__header">
            <h1>
              {t('meeting_from_with', {
                host_company: this.props.hostCompanyName,
                guest_company: this.props.targetCompanyName
              })}
            </h1>

            <button type="close" onClick={this.onClose}>&times;</button>
          </header>
          <h2>{ t('edit_guests.select_new_host') }</h2>
          <div className="c-modal__content c-invite-modal__edit_guests">
            <div className="c-modal__content__guests">
              { this.hostGuestsColumn() }
            </div>
          </div>

          <footer className="c-modal__footer">
            <button className='c-btn c-btn--save u-float-right' type='alert' disabled={ this.isSaveButtonDisabled() } onClick={ this.updateHost }>
              { this.saveButtonText() }
            </button>
          </footer>
        </div>
      </section>
    </React.Fragment>
  }

  saveButtonText() {
    switch (this.state.status) {
    case 'loading':
      return '...'
    case 'loaded':
      return t('edit_guests.button_text_save')
    case 'error':
      return t('edit_guests.button_text_error')
    default:
      return '...'
    }
  }

  isSaveButtonDisabled() {
    return this.state.status !== 'loaded' || this.state.data.checkedUserId === this.state.data.hostUser.id
  }

  async updateHost() {
    const confirmation = await swal({
      title: t('edit_guests.are_you_sure'),
      text: t('edit_guests.removing_yourself'),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('yes_sure'),
      cancelButtonText: t('negative')
    })

    if (confirmation.value) {
      const newState = this.state
      newState.status = 'loading'
      this.setState(newState)

      axios.post(`/time_slices/meetings/${this.props.sliceId}/update_host`, {
        current_host_id: this.state.data.hostUser.id,
        new_host_id: this.state.data.checkedUserId
      }
      ).then(() => {
        this.onPostUpdate()
      }).catch(() => {
        const newState = this.state
        newState.status = 'error'
        this.setState(newState)
      })
    }
  }

  async onPostUpdate() {
    await swal({text: t('edit_guests.host_change_success'), type: 'success'})
    location.reload()
  }

  onStartLoading() {
    const newState = this.state
    newState.status = 'loading'
    this.setState(newState)
    this.data()
  }

  onClose() {
    this.resetState()
    this.props.changeParentState('loading')
  }

  onModalClose(event) {
    this.resetState()
    this.props.closeParent(event)
  }

  resetState() {
    this.setState({
      status: 'hidden',
      data: {
        hostPossibleUsers: [],
        hostInvitedUsers: [],
        targetPossibleUsers: [],
        targetInvitedUsers: [],
        checkedUserIds: [],
      },
      errors: {},
    }
    )
    this.errors = []
  }

  render() {
    switch (this.state.status) {
    case 'hidden':
      return ''
    case 'error':
      return this.render_error()
    case 'loading':
      return this.render_loading()
    default:
      return this.render_complete_page()
    }
  }
}

EditHostsBox.propTypes = {
  companyId: PropTypes.number.isRequired,
  sliceId: PropTypes.number.isRequired,
  hostCompanyName: PropTypes.string.isRequired,
  targetCompanyId: PropTypes.number.isRequired,
  targetCompanyName: PropTypes.string.isRequired,
  changeParentState: PropTypes.func.isRequired,
  closeParent: PropTypes.func.isRequired,
}

export default EditHostsBox
