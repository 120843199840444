import React from 'react'
import PropTypes from 'prop-types'
import { t } from '../../packs/lib/i18n.js'
import axios from '../../packs/lib/axios.js'
import swal from 'sweetalert2'
import { truncate } from '../../packs/lib/string.js'

/*eslint-disable no-unused-vars*/
import ShowModal from '../../packs/components/InviteBox/ShowModal.jsx'
import GTSModal from './GTSModal.jsx'
/*eslint-enable no-unused-vars*/

const TalkData = ({ busy }) => {
  if (busy.type !== 'talk') {
    return ''
  }

  const { talk } = busy

  return <a href={`/talks/${talk.event_id}/${talk.id}`} target="_blank">
    <p><strong>{truncate(talk.title, 27)}</strong></p>
    <p>{talk.starts_at_hour} - {talk.ends_at_hour}</p>
    <p>@{talk.location.name}</p>
  </a>
}

TalkData.propTypes = {
  busy: PropTypes.object.isRequired
}

const DestroyBtn = ({ busy, onClick }) => {
  if (busy.destroyable !== true) {
    return ''
  }

  return <button
    className="c-calendar__cell__rm"
    type="rm"
    onClick={onClick}>
    &times;
  </button>
}

DestroyBtn.propTypes = {
  busy: PropTypes.object.isRequired
}

const Status = ({ busy, klass }) => {
  if (typeof busy.status === 'undefined' || !busy.status) {
    return ''
  }

  if (!busy.type.includes('meeting')) {
    return ''
  }

  const statusKlass = `${klass}__ts__status`

  return <span className={`${statusKlass} ${statusKlass}--${busy.status}`}>
    {
      busy.spot && busy.status === 'confirmed'
        ? busy.spot.includes('http')
          ? `${t(busy.status)} ${t('at_online').toLowerCase()}`
          : `${t(busy.status)} ${t('at_spot', { spot: busy.spot }).toLowerCase()}`
        : t(busy.status)
    }
  </span>
}

Status.propTypes = {
  busy: PropTypes.object.isRequired,
  klass: PropTypes.string.isRequired
}

class BusyCell extends React.Component {
  constructor() {
    super()

    this.destroy = this.destroy.bind(this)

    this.state = {
      modalHidden: true
    }
  }

  // FIXME: Make it better :P
  scopedPlural(str) {
    return `${str}s`
  }

  async destroy(event) {
    event.preventDefault()

    const confirmation = await swal({
      title: t('are_you_sure_question'),
      text: '',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('yes_delete'),
      cancelButtonText: t('cancel')
    })

    if (!confirmation.value) {
      return
    }

    const { busy, host } = this.props
    const url = `/time_slices/${this.scopedPlural(busy.type)}/${busy.id}`

    try {
      const response = await axios.delete(url, {
        params: {
          user_id: host
        }
      })

      if (!response.data.success) {
        throw new Error(response.data.error)
      }

      if (typeof this.props.onRemoveRequest !== 'undefined') {
        this.props.onRemoveRequest(busy.id)
      }
    } catch(err) {
      swal(t('error'), err.message, 'error')
    }
  }

  arrayToClass(arr) {
    return arr
      .filter((x) => x !== '')
      .join(' ')
  }

  render() {
    const { busy, baseKlass } = this.props

    const parentKlass = this.arrayToClass([
      baseKlass,
      (busy.destroyable === true) ? `${baseKlass}--destroyable` : ''
    ])

    const isSoft = typeof busy.affected_types !== 'undefined' &&
                   busy.affected_types.length === 0

    const isClickable = busy.has_modal === true

    const wrapperKlass = this.arrayToClass([
      `${baseKlass}__ts`,
      `${baseKlass}__ts--${busy.type}`,
      isSoft ? `${baseKlass}__ts--soft` : '',
      isClickable ? `${baseKlass}__ts--clickable` : ''
    ])

    return <div
      className={parentKlass}
      onClick={(e) => {
        if (!busy.type.includes('meeting') &&
            busy.type !== 'global_time_slice') {
          return
        }

        if (this.props.disableModals) {
          return
        }

        e.preventDefault()

        this.setState({ modalHidden: false })
      }}
    >
      <div className={wrapperKlass}>
        <DestroyBtn busy={busy} onClick={this.destroy} />
        <Status busy={busy} klass={baseKlass} />

        {
          busy.type === 'talk'
            ? <TalkData busy={busy} />
            : <span>{busy.name}</span>
        }
      </div>

      {
        busy.type.includes('meeting') &&
          <ShowModal
            onClose={() => this.setState({ modalHidden: true })}
            hidden={this.state.modalHidden}
            userId={this.props.host}
            timeSliceId={busy.id}
            userCompany={busy.user_company}
            targetCompany={busy.target_company}
            companyId={busy.company_id}
            hostId={busy.host_id}
            userCompanyId={busy.user_company.id}
            userCompanyName={busy.user_company.name}
            hostCompanyName={busy.host_company_name}
            targetCompanyId={busy.target_company_id}
            targetCompanyName={busy.target_company_name}
          />
      }

      {
        busy.type === 'global_time_slice' &&
          busy.has_modal === true &&
          !this.state.modalHidden &&
          <GTSModal
            busy={busy}
            onClose={() => this.setState({ modalHidden: true })}
          />
      }
    </div>
  }
}

BusyCell.propTypes = {
  host: PropTypes.number.isRequired,
  busy: PropTypes.object.isRequired,
  baseKlass: PropTypes.string.isRequired,
  onRemoveRequest: PropTypes.func,
  disableModals: PropTypes.bool
}

export default BusyCell
