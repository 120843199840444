/* global React, PropTypes, i18n */

const t = i18n.t

const Loading = ({ userCompany, targetCompany, onClose }) => (
  <section className="c-modal c-modal--show-ts" onClick={onClose}>
    <div className="c-modal__wrapper" onClick={(event) => event.stopPropagation()}>
      <header className="c-modal__header">
        <h1>
          {t('meeting_from_with', {
            host_company: userCompany.name,
            guest_company: targetCompany.name
          })}
        </h1>

        <button type="close" onClick={onClose}>&times;</button>
      </header>

      <div className="c-modal__content">
        <p>{t('loading')}&hellip;</p>
      </div>
    </div>
  </section>
)

Loading.propTypes = {
  userCompany: PropTypes.object.isRequired,
  targetCompany: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}

export default Loading
