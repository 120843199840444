// See more about creating validators on ./export.js

import { getFormFieldValue } from '../../lib/dom'

const valueGreaterThan = (greaterValue, lowerValueFieldName) => {
  const select = document.querySelector(`[name="${lowerValueFieldName}"]`)

  if (!select) return null

  const lowerValue = dom.getFormFieldValue(select)
  const isValid = parseInt(greaterValue, 10) > parseInt(lowerValue, 10)

  if (isValid) return null

  return 'valueGreaterThan'
}

export default function (value, options, fieldName) {
  return valueGreaterThan(value, options.field)
}
