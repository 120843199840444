import React, { useEffect, useState } from 'react';
import axios from 'axios';

import AlternativeMeetingForm from './AlternativeMeetingForm';

const t = i18n.t;

export default function ThirdStep({
  creationData,
  onPrevious,
  onNext,
  onSubmit,
  onClose,
}) {
  const [checkingAvailability, setCheckingAvailability] = useState(true);

  useEffect(() => {
    const checkAvailability = async () => {
      const res = await axios.post('/time_slices/meetings/time_slice_availability', {
        time_slice: {
          guests: creationData.guests,
          datetime: creationData.datetime,
          spot_type: creationData.spotType,
          message: creationData.message,
          meeting_type: creationData.meetingType
        }
      });

      if (res.data.slice_is_available) {
        onNext();
      } else {
        setCheckingAvailability(false);
      }
    };
    checkAvailability();
  }, []);

  return (
    <div>
      {checkingAvailability ? (
        <div className="c-modal__content c-invite-modal__step1">
          <span>{t('loading')}&hellip;</span>
        </div>
      ): (
        <AlternativeMeetingForm
          creationData={creationData}
          onSubmit={onSubmit}
          onPrevious={onPrevious}
          onClose={onClose}
        />
      )}
    </div>
  );
}
