/*eslint-disable no-unused-vars*/
import React from 'react'
/*eslint-enable no-unused-vars*/

import { t } from '../../packs/lib/i18n.js'

const EmptyChat = () => {
  return <section className="c-messages__current-chat c-messages__current-chat--select">
    <p>{ t('messages.empty_chat.please_select_chat') }</p>
  </section>
}

export default EmptyChat
