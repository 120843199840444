/* global dom, axios */

const docHeight = () => {
  if (typeof document.height !== 'undefined') {
    return document.height
  }

  return document.body.offsetHeight
}

const currentPosition = () => window.scrollY

const bufferHeight = () => {
  const height = document.querySelector('.js-infinite-scroller > li').offsetHeight
  let factor = 3
  let buffer = height * factor

  while (buffer < window.innerHeight) {
    factor++
    buffer = height * factor
  }

  return buffer
}

const inRequestTriggerArea = () => {
  return currentPosition() >= (docHeight() - bufferHeight())
}

const appendNewItemsToScroller = (newItems) => {
  const scroller = document.querySelector('.js-infinite-scroller')
  const div = document.createElement('DIV')
  const trash = document.createElement('DIV')
  div.innerHTML = newItems

  while(div.hasChildNodes()) {
    if (div.childNodes[0].nodeType == Node.TEXT_NODE) {
      trash.appendChild(div.childNodes[0])
      continue
    }

    scroller.appendChild(div.childNodes[0])
  }
}

const requestNewItems = async function() {
  const scroller = document.querySelector('.js-infinite-scroller')
  const currentPage = scroller.getAttribute('data-page')
  const nextPage = parseInt(currentPage, 10) + 1
  const baseUrl = scroller.getAttribute('data-query-url')
  const request = await axios.get(baseUrl, { params: { page: nextPage, as_partial: 'true'}})
  scroller.setAttribute('data-page', nextPage)

  return request.data
}

const fetchAndAppendNewItems = (function() {
  let shouldRun = true

  return async function() {
    if (!shouldRun) {
      return
    }

    shouldRun = false
    const data = await requestNewItems()
    appendNewItemsToScroller(data)
    shouldRun = data.trim() !== ''
  }

})()

const onScroll = () => {
  if (!inRequestTriggerArea()) {
    return
  }

  fetchAndAppendNewItems()
}

const init = () => {
  window.addEventListener('scroll', onScroll)
}

const scroller = document.querySelector('.js-infinite-scroller')
if (scroller) {
  init()
}
