/* global PropTypes, i18n */
import React from 'react'

/*eslint-disable no-unused-vars*/
import Modal from '../../components/Modal.jsx'
/*eslint-enable no-unused-vars*/

const t = i18n.t

class DuplicatedDocumentModal extends React.Component {
  constructor() {
    super()
    this.state = {
      isOpen: true,
    }

    this.token = this.token.bind(this)
  }

  closeModal() {
    this.setState({isOpen: false})
  }

  token() {
    return document.querySelector('meta[name=csrf-token]').getAttribute('content');
  }

  mainButtonChoices() {
    return <div className="c-modal__content">
      <form
        action="/auth/users/password"
        method="POST"
        role="form"
      >
        <input type="hidden" name="authenticity_token" value={this.token()} />
        <input type="hidden" name="user[email]" value={this.props.email} />
        <button className='c-btn'>{t('duplicated_document_modal.still_have_access')}</button><br /><br />
      </form>
      <form
        action="/accounts/flag_duplicated_user"
        method="post"
        role="form"
      >
        <input type="hidden" name="authenticity_token" value={this.token()} />
        <input type="hidden" name="flagged_email" value={this.props.flaggedEmail} />
        <input type="hidden" name="document_owner_id" value={this.props.docOwnerId} />
        <button className="c-btn" type="submit">{t('duplicated_document_modal.request_access')}</button><br /><br />
      </form>
    </div>
  }

  render() {
    return <Modal
      title={`${this.props.title}: ${this.props.doc}`}
      isOpen={this.state.isOpen}
      componentKlass="c-admin-block"
      onClose={() => { this.closeModal() }}
    >

      <div>
        <h3>{`${t('duplicated_document_modal.preamble')}: ${this.props.obfuscatedEmail}.`}</h3><br />
        <h4>{t('duplicated_document_modal.choose')}</h4>
      </div>
      {this.mainButtonChoices()}
    </Modal>
  }
}

DuplicatedDocumentModal.propTypes = {
  doc: PropTypes.string,
  title: PropTypes.string,
  email: PropTypes.string,
  obfuscatedEmail: PropTypes.string,
  flaggedEmail: PropTypes.string,
  docOwnerId: PropTypes.string
}

export default DuplicatedDocumentModal
