// See more about creating validators on ./export.js

import { getFormFieldValue } from '../../lib/dom'

const valueLessThan = (lowerValue, greaterValueFieldName) => {
  const select = document.querySelector(`[name="${greaterValueFieldName}"]`)

  if (!select) return null

  const greaterValue = dom.getFormFieldValue(select)
  const isValid = parseInt(greaterValue, 10) > parseInt(lowerValue, 10)

  if (isValid) return null

  return 'valueLessThan'
}

export default function (value, options, fieldName) {
  return valueLessThan(value, options.field)
}
