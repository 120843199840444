/* global i18n */

import validate from 'validate.js'
import customValidators from '../validators/export.js'

const t = i18n.t

validate.formatters.custom = function(errors) {
  let errs = {}

  errors.forEach((error) => {
    const attr = error.attribute
    const options = error.options

    let params = {}
    if (typeof options !== 'boolean' && typeof options !== 'string') {
      const optIsObject = Object.prototype.toString.call(options)
      if (optIsObject) {
        params = { ...options }
      } else {
        params[error.validator] = options
      }
    }

    params.field = t(`validators.fields.${attr}`)

    let msg = null

    if (error.validator === 'length') {
      const pKeys = Object.keys(params)
      if (pKeys.includes('minimum') && pKeys.includes('maximum')) {
        msg = t('validators.errors.length_between', params)
      } else if (pKeys.includes('maximum')) {
        msg = t('validators.errors.length_max', params)
      } else {
        msg = t('validators.errors.length', params)
      }
    } else {
      msg = t(`validators.errors.${error.validator}`, params)
    }

    if (typeof errs[attr] === 'undefined') {
      errs[attr] = []
    }

    errs[attr].push(msg)
  })

  return errs
}

validate.validators = Object.assign(validate.validators, customValidators)

export default validate
