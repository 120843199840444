/* global dom */

const onlyNumbers = (event) => {
  const input = event.target
  const limitFromData = input.getAttribute('data-limit')
  const limit = (limitFromData === undefined) ? Infinity : parseInt(limitFromData, 10)
  input.value = input.value.replace(/\D/g, '').slice(0, limit)
}

const mmyyDate = (event) => {
  const input = event.target
  let value = input
    .value
    .replace(/\D/g, '')
    .slice(0, 4)

  if (value.length < 3) {
    input.value = value
    return
  }

  input.value = value.replace(/^(\d{2})/g, '$1/')
}

const numbers = document.querySelectorAll('.js-mask-number')
dom.forEach(numbers, (number) => {
  number.addEventListener('keyup', onlyNumbers)
})

const mmyys = document.querySelectorAll('.js-mask-mmyy')
dom.forEach(mmyys, (mmyy) => {
  mmyy.addEventListener('keyup', mmyyDate)
})

