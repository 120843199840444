/* global dom */

const onClick = (event) => {
  event.preventDefault()
  const parent = dom.parents(event.target, 'c-intro-block')
  dom.remove(parent)
}

const btns = document.querySelectorAll('.js-platform-text-close')
dom.forEach(btns, (btn) => btn.addEventListener('click', onClick))
