import * as dom from '../lib/dom.js'
import localforage from 'localforage'

const cacheKey = 'add_to_home_dismissed'
const isChrome = () => /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
const isMob = () => window.innerWidth < 960
const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream

const dismissed = async function() {
  const cache = await localforage.getItem(cacheKey)

  if (cache) {
    return true
  }

  return false
}


const dismiss = async function(e) {
  e.preventDefault()
  await localforage.setItem(cacheKey, 'true')

  const boxes = document.querySelectorAll('.c-add-to-home')
  dom.forEach(boxes, (box) => dom.addClass(box, 'c-add-to-home--hidden'))
}

const init = async function() {
  const dms = await dismissed()

  if (isChrome() || !isMob() || dms) {
    return
  }

  let popup
  if (isIOS()) {
    popup = document.querySelector('.c-add-to-home--ios')
  } else {
    popup = document.querySelector('.c-add-to-home--other')
  }
  dom.removeClass(popup, '.c-add-to-home--hidden')

  const dismissBtns = document.querySelectorAll('.js-dismiss-add-to-home')
  dom.bindForEach(dismissBtns, 'click', dismiss)
}

init()

