/* global React, i18n, axios, dom */

import DateBox from './DateBox'
import Talk from './Talk'
import localforage from 'localforage'

const t = i18n.t
const FilterLastDateKey = 'FilterLastDate'

/**
 * Funcao que recebe uma data maxima uma minima,
 * e as compara com o dia atual. Se hoje for
 * antes da data minima ela retorna a data
 * minima, se for depois ele retorna a maxima e
 * se for entre uma e outra retorna hoje em formato
 * yyyy-mm-dd.
 * @param {string} minDate | a data minima em formato yyyy-mm-dd
 * @param {string} maxDate | a data maxima em formato yyyy-mm-dd
 */
const getActiveDate = (minDate, maxDate) => {
  if (urlParams().date) {
    return urlParams().date
  }

  const now = Date.now()
  if (now < new Date(minDate).getTime()) {
    return minDate
  } else if (now > new Date(maxDate).getTime()) {
    return maxDate
  } else {
    return new Date(now).toISOString().split('T')[0]
  }
}

const urlParams = () => {
  if (!window || !window.location || !window.location.href) {
    return {}
  }

  const splitUrl = window.location.href.split('?')

  if (splitUrl.length === 1) {
    return {}
  }

  return splitUrl[1].split('&').reduce((obj, item) => {
    const [key, value] = item.split('=')
    obj[key] = value

    return obj
  }, {})
}

const getActiveCat = () => {
  return urlParams().cat || 'all'
}

class Schedule extends React.Component {
  constructor(props) {
    super(props)

    const occupation = props.filters.occupation || 'all'
    const { days } = props
    const minDate = days[0].date
    const maxDate = days[days.length - 1].date
    this.state = {
      activeDate: getActiveDate(minDate, maxDate),
      isLoading: true,
      isButtonLoading: false,
      location: 'all',
      category: getActiveCat(),
      occupation,
      error: false,
      page: 1,
      pageCount: 0,
    }

    this.updateTalks = this.updateTalks.bind(this)
    this.loadMoreTalks = this.loadMoreTalks.bind(this)
    this.setDayData = this.setDayData.bind(this)
    this.filterTalks = this.filterTalks.bind(this)
    this.seeMore = this.seeMore.bind(this)
  }

  async UNSAFE_componentWillMount() {
    let date = await localforage.getItem(FilterLastDateKey)
    this.setDayData(date)
  }

  async setDayData(date) {
    let newDate = this.state.activeDate
    if (date) {
      newDate = date
    }
    let newLang = this.state.lang

    await localforage.setItem(FilterLastDateKey, newDate)

    if (window && window.locale)
      newLang = `${window.locale}`

    this.setState({activeDate: newDate, lang: newLang, isLoading: true, isButtonLoading: true, page: 1}, this.updateTalks)
  }

  loadMoreTalks() {
    const boundThis = this
    const params = {}
    params['page'] = this.state.page
    params['cat'] = this.state.category
    params['location'] = this.state.location
    params['occupation'] = this.state.occupation
    params['date'] = this.state.activeDate
    params['lang'] = this.state.lang
    axios.get(`/talks/${this.props.event_id}.json`, { params: params })
      .then(function ({ data }) {
        const all_talks = boundThis.state.data.talks.concat(data.talks)
        boundThis.setState({
          data: { talks: all_talks },
          isLoading: false,
          isButtonLoading: false,
          page: parseInt(data.page),
          pageCount: parseInt(data.page_count)
        })
      })
      .catch(function (error) {
        console.error(
          'Error fetching data',
          error
        )
        boundThis.setState({
          error: true,
          isLoading: false
        })
      })
  }

  updateTalks() {
    const boundThis = this
    const params = {}
    params['page'] = this.state.page
    params['cat'] = this.state.category
    params['location'] = this.state.location
    params['occupation'] = this.state.occupation
    params['date'] = this.state.activeDate
    params['lang'] = this.state.lang
    axios.get(`/talks/${this.props.event_id}.json`, { params: params })
      .then(function ({ data }) {
        boundThis.setState({
          data,
          isLoading: false,
          isButtonLoading: false,
          page: parseInt(data.page),
          pageCount: parseInt(data.page_count)
        })
      })
      .catch(function (error) {
        console.error(
          'Error fetching data',
          error
        )
        boundThis.setState({
          error: true,
          isLoading: false
        })
      })
  }

  filterTalks (value, filter) {
    this.setState({[filter]: value, page: 1, isLoading: true, isButtonLoading: true}, this.updateTalks)
  }

  seeMore() {
    this.setState({ isButtonLoading: true, page: this.state.page + 1 }, this.loadMoreTalks)
  }

  showSeeMoreButton() {
    return this.state.isButtonLoading || (!this.state.isError && !this.state.isLoading && this.state.page !== this.state.pageCount)
  }

  render () {
    const  { days, locations, categories, user_signed_in, for_iframe } = this.props
    const { error, isLoading, activeDate } = this.state
    const talks = this.state.data ? this.state.data.talks : []

    return (
      <main className="c-schedule u-wrapper">
        <div className="dates-select">
          <label>{t('schedule.select_a_date')}</label>
          <select
            defaultValue={activeDate}
            onChange={(event) => {
              this.setDayData(event.target.value)}
            }>
            <option value={'all'}>{t('all')}</option>
            {days.map(day => (
              <option
                key={day.date}
                value={day.date}>
                {day.human}
              </option>
            ))}
          </select>
        </div>

        <div className="dates">
          <DateBox
            key="all"
            date={t('all')}
            isActive={activeDate === 'all'}
            onClick={() => this.setDayData('all')} />
          {days.map(day => (
            <DateBox
              key={day.date}
              date={day.human}
              isActive={activeDate === day.date}
              onClick={() => this.setDayData(day.date)} />
          ))}
        </div>
        <div className="filters">
          <select onChange={event => this.filterTalks(event.target.value, 'location')}>
            <option value="all">{t('schedule.location')}</option>
            {locations.sort((loc_1, loc_2) => loc_1.name > loc_2.name).map(location => (
              <option
                key={location.id}
                value={location.id}>
                {location.name}
              </option>
            ))}
          </select>
          <select onChange={event => this.filterTalks(event.target.value, 'category')} defaultValue={this.state.category}>
            <option value="all">{t('schedule.track')}</option>
            {categories.map(category => (
              <option
                key={category.id}
                value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          <select
            defaultValue={this.state.occupation}
            onChange={event => this.filterTalks(event.target.value, 'occupation')}>
            <option value="all">{t('schedule.occupation')}</option>
            <option value="vacant">{t('schedule.vacant')}</option>
          </select>
        </div>
        {isLoading &&
          <p className="message">{t('schedule.loading')}</p>
        }
        {!isLoading && error &&
          <p className="message">{t('schedule.error')}</p>
        }
        {
          !isLoading && !error && !talks.length
            ? <p className="message">{t('schedule.no_talks')}</p>
            : ''
        }
        {!isLoading && !error && talks.length
          ? <>
            { talks.map(talk => (
              <Talk
                key={`${talk.title}-${talk.id}`}
                id={talk.id}
                path={talk.path}
                promoPicture={talk.promo_picture}
                userSignedIn={user_signed_in}
                time={`${talk.starts_at_hour}-${talk.ends_at_hour}`}
                dayMonth={talk.day.human}
                title={talk.title}
                description={talk.description}
                isAttendee={talk.is_attendee}
                videoLink={talk.link_video}
                forIframe={for_iframe === true ? true : false}
                timeSliceId={talk.time_slice_id}
                userCanAttend={talk.user_can_attend}
                location={talk.location}
                placesLeft={talk.places_left}
                fullTalkWarning={talk.full_talk_warning}
                bookingEnabled={talk.booking_enabled}
                bookingDisabledWarning={talk.booking_disabled_warning}
                categories={talk.categories
                  .map(cat => cat.name)}
                speakers={talk.speakers
                  .map(speaker => ({
                    id: speaker.id,
                    name: speaker.name,
                    company: speaker.company,
                    company_path: speaker.company_path,
                    picture: speaker.picture,
                    path: speaker.speaker_path,
                    is_moderator: speaker.is_moderator,
                    artistic_name: speaker.artistic_name,
                  }))}
              />
            )) }
            { this.showSeeMoreButton() && <div className="c-schedule-see-more">
              <button className="c-btn btn-see-more" onClick={this.seeMore}>{ this.state.isButtonLoading ? t('pagination_loading') : t('pagination_load_more') }</button>
            </div>
            }
          </>
          : '' }
      </main>
    )
  }
}


export default Schedule
