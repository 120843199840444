/*eslint-disable no-unused-vars*/
import React from 'react'
import ReactDOM from 'react-dom'
import InviteBox from '../components/InviteBox.jsx'
/*eslint-enable no-unused-vars*/

export default function() {
  const inviteBoxes = document.querySelectorAll('.js-react-invite-box')

  for (let i = 0; i < inviteBoxes.length; i += 1) {
    const box = inviteBoxes[i]
    const userId = parseInt(box.getAttribute('data-user-id'), 10)
    const companyId = parseInt(box.getAttribute('data-with-company-id'), 10)
    const sliceId = parseInt(box.getAttribute('data-slice-id'), 10)
    const online = (box.getAttribute('data-online').toLowerCase() === 'true')
    const onlineCanInvite = (box.getAttribute('data-online-can-invite').toLowerCase() === 'true')
    const dates = {
      meetings: {
        start_at: box.getAttribute('data-meetings-start'),
        end_at: box.getAttribute('data-meetings-end')
      },
      online_meetings: {
        start_at: box.getAttribute('data-online-meetings-start'),
        end_at: box.getAttribute('data-online-meetings-end'),
        longRangeDateSelect: box.getAttribute('data-online-meetings-after-meeting') !== "false",
      }
    }
    const hostId = parseInt(box.getAttribute('data-host-id'), 10)
    const userCompanyId = parseInt(box.getAttribute('data-company-id'), 10)
    const hostCompanyName = box.getAttribute('data-company-name')
    const targetCompanyId = parseInt(box.getAttribute('data-target-company-id'), 10)
    const targetCompanyName = box.getAttribute('data-target-company-name')

    ReactDOM.render(<InviteBox
      userId={userId}
      sliceId={sliceId}
      companyId={companyId}
      dates={dates}
      online={online}
      onlineCanInvite={onlineCanInvite}
      hostId={hostId}
      userCompanyId={userCompanyId}
      hostCompanyName={hostCompanyName}
      targetCompanyId={targetCompanyId}
      targetCompanyName={targetCompanyName} />, inviteBoxes[i])
  }
}
