/* global swal, dom, axios, i18n */

const t = i18n.t

const onSubmit = async function(event) {
  event.preventDefault()
  const form = event.target
  const url = form.getAttribute('action')
  const parent = dom.parents(form, dom.data(form, 'parent-class'))
  const button = form.querySelector('button')

  if (dom.hasClass(button, 'c-btn--loading')) {
    return
  }

  dom.addClass(button, 'c-btn--loading')
  button.disabled = true

  const confirmation = await swal({
    title: t('are_you_sure_question'),
    text: t('irreversible_action'),
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: t('yes_delete'),
    cancelButtonText: t('cancel')
  })

  if (!confirmation.value) {
    dom.removeClass(button, 'c-btn--loading')
    button.disabled = false
    return
  }

  const result = await axios.delete(`${url}.json`)

  dom.removeClass(button, 'c-btn--loading')
  button.disabled = false

  if (result.data) {
    swal(
      t('deleted'),
      t('delete_success'),
      'success'
    )

    dom.remove(parent)
    return
  }

  swal(
    t('error'),
    t('delete_error'),
    'error'
  )
}

const forms = document.querySelectorAll('.js-delete-form')
dom.forEach(forms, (form) => form.addEventListener('submit', onSubmit))
