import * as dom from '../../lib/dom.js'
import { init as couponInit } from './ticket-order/coupon.js'
import { init as countrySpecialConditionInit } from './ticket-order/countrySpecialCondition.js'
import './ticket-order/ticketInputs.js'
import './ticket-order/submission.js'
import summaryUpdate from './ticket-order/summaryUpdate.js'
import paymentChoicesMgmt from './ticket-order/paymentChoicesMgmt.js'

couponInit()
countrySpecialConditionInit()

document.addEventListener('i18n:onReady', () => {
  paymentChoicesMgmt()
  summaryUpdate()
})

dom.ready(() => {
  paymentChoicesMgmt()
  summaryUpdate()
})
