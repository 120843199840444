/* global React, PropTypes, i18n */

const t = i18n.t

const EditsAlert = ({ edits }) => {
  if (Object.keys(edits).length === 0) {
    return ''
  }

  return <div className="c-alert c-alert--warning">
    {t('you_have_unsaved_changes')}
  </div>
}

EditsAlert.propTypes = {
  edits: PropTypes.object.isRequired
}


export default EditsAlert
