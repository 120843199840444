/* global dom */

const updateCount = (field, limit) => {
  const parent = field.parentNode
  const counter = parent.querySelector('.js-charlimit-counter')
  const left = limit - field.value.length
  const content = left > 0
    ? `<span>${left}</span>/<span>${limit}</span>`
    : `<span class="u-c-danger">${left}</span>/<span>${limit}</span>`

  if (!counter) {
    field.insertAdjacentHTML('afterend', `
      <div class="js-charlimit-counter u-ta-right">${content}</div>
    `)
    return
  }

  counter.innerHTML = content
}

const setupField = (field) => {
  const limit = dom.data(field, 'charlimit', 'integer')
  updateCount(field, limit)
  field.addEventListener('blur', () => updateCount(field, limit))
  field.addEventListener('keyup', () => updateCount(field, limit))
  field.addEventListener('keydown', () => updateCount(field, limit))
}

const fields = document.querySelectorAll('[data-charlimit]')
dom.forEach(fields, setupField)
