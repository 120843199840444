/* global dom */

const triggers = document.querySelectorAll('.js-tabbed-field__nav')

const onTriggerClick = (event) => {
  event.preventDefault()
  const trigger = dom.findTarget(event.target, 'js-tabbed-field__nav')
  const parent = dom.parents(trigger, 'js-tabbed-field')
  const targetKlass = `.${dom.data(trigger, 'target')}`
  const activeKlass = dom.data(trigger, 'active-class')
  const activeKlassDotted = `.${activeKlass}`
  const targetBaseKlass = targetKlass.split('--')[0]
  const allTargets = parent.querySelectorAll(targetBaseKlass)
  const target = parent.querySelector(targetKlass)
  const activeTriggers = parent.querySelectorAll(activeKlassDotted)

  dom.forEach(activeTriggers, (aTrigger) => dom.removeClass(aTrigger, activeKlass))
  dom.addClass(trigger, activeKlass)
  dom.forEach(allTargets, (aTarget) => dom.addClass(aTarget, 'u-hidden'))
  dom.removeClass(target, 'u-hidden')
}

dom.forEach(triggers, (trigger) => {
  trigger.addEventListener('click', onTriggerClick)
})
