/* global React, PropTypes, i18n */

/*eslint-disable no-unused-vars*/
import CompaniesAutosuggest from './CompaniesAutosuggest.jsx'
/*eslint-enable no-unused-vars*/

const t = i18n.t

const CompanyPick = ({ company, clearSelection }) => {
  if (Object.keys(company).length === 0) {
    return <div className="c-company-picker__pick u-ta-center">
      <p>{t('search_company_above')}</p>
      <p>{t('company_not_found_question')}</p>
      <a href="/companies/new" className="c-btn c-btn--lg1">
        {t('click_here_to_create_company')}
      </a>
    </div>
  }

  return <div className="c-company-picker__pick">
    <input type="hidden" name="company_choice[company_id]" value={company.id} />

    <div className="c-company-picker__pick__company">
      <div className="c-company-picker__pick__company__left">
        <img src={company.logo}/>
      </div>

      <div className="c-company-picker__pick__company__right">
        <p>{company.name}</p>
      </div>

      <button type="remove" onClick={clearSelection}>&times;</button>
    </div>

    <div className="c-form__field">
      <label htmlFor="company_choice_position">{t('company_position')}</label>
      <input id="company_choice_position" type="text" name="company_choice[position]" />
    </div>

    <div className="u-ta-right">
      <button type="submit" className="c-btn c-btn--lg1">{t('confirm_company_rep')}</button>
    </div>
  </div>
}

CompanyPick.propTypes = {
  company: PropTypes.object.isRequired
}

class CompanyPicker extends React.Component {
  constructor() {
    super()

    this.onSelect = this.onSelect.bind(this)
    this.clearSelection = this.clearSelection.bind(this)

    this.state = {
      company: {}
    }
  }

  onSelect(company) {
    this.setState({ company }, () => {
      const el = document.querySelector('#company_choice_position')

      if (el) {
        el.focus()
      }
    })
  }

  clearSelection(event) {
    event.preventDefault()
    this.setState({ company: {} })
  }

  render() {
    const { company } = this.state

    return <React.Fragment>
      <CompaniesAutosuggest onSelect={this.onSelect} />
      <CompanyPick
        company={company}
        clearSelection={this.clearSelection} />
    </React.Fragment>
  }
}

export default CompanyPicker
