import React from 'react'
import PropTypes from 'prop-types'
import { t } from '../lib/i18n.js'
import axios from '../lib/axios.js'
import * as dom from '../lib/dom.js'

/*eslint-disable no-unused-vars*/
import Autosuggest from 'react-autosuggest'
/*eslint-enable no-unused-vars*/

function getSuggestionValue(suggestion) {
  return suggestion.name
}

function renderSuggestion(suggestion) {
  return (
    <div
      className="react-autosuggest__company"
      data-id={suggestion.id}
      data-name={suggestion.name}
      data-picture={suggestion.picture}>
      <img src={suggestion.picture} />
      <span>{suggestion.name}</span>
    </div>
  )
}

class EntityAutosuggest extends React.Component {
  constructor() {
    super()

    this.state = {
      event_id: null,
      value: '',
      suggestions: [],
      isLoading: false
    }

    this.lastRequestId = null
    this.loadSuggestions = this.loadSuggestions.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this)
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this)
  }

  loadSuggestions(value) {
    // Cancel the previous request
    if (this.lastRequestId !== null) {
      clearTimeout(this.lastRequestId)
    }

    if (value.length < 3) {
      return
    }

    this.setState({
      isLoading: true
    })

    const params = Object.assign({
      event_id: this.state.event_id,
      term: value
    }, this.state.params)

    const selectedIds = (typeof this.props.selectedIds !== 'undefined')
      ? this.props.selectedIds.map((x) => parseInt(x, 10))
      : []

    this.lastRequestId = setTimeout(() => {
      axios.get(this.props.url, { params })
        .then((response) => {
          const suggestions = response.data.filter((x) => !selectedIds.includes(x.id))

          this.setState({
            isLoading: false,
            suggestions
          })
        })
    }, 100)
  }

  onChange(event, { newValue }) {
    event.preventDefault()
    const target = dom.findTarget(event.target, 'react-autosuggest__company')

    if (target) {
      const id = target.getAttribute('data-id')
      const name = target.getAttribute('data-name')
      const picture = target.getAttribute('data-picture')

      this.props.onSelect({
        id,
        name,
        picture,
        term: this.state.value
      })

      if (this.props.resetAfterSelect) {
        this.setState({
          value: ''
        })

        return
      }
    }

    this.setState({
      value: newValue
    })
  }

  onSuggestionsFetchRequested({ value }) {
    if (typeof this.props.onRequest !== 'undefined') {
      this.props.onRequest(value)
    }

    this.loadSuggestions(value)
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: []
    })
  }

  UNSAFE_componentWillMount() {
    let stateChanges = {}

    if (typeof this.props.eventId !== 'undefined') {
      stateChanges.event_id = this.props.eventId
    }

    if (typeof this.props.defaultTerm !== 'undefined') {
      stateChanges.value = this.props.defaultTerm
    }

    this.setState(stateChanges)
  }

  shouldComponentUpdate() {
    return true
  }

  render() {
    const { value, suggestions, isLoading } = this.state

    const inputProps = {
      placeholder: t('type_to_load'),
      value,
      onChange: this.onChange
    }

    return (
      <div className="c-companies-autosuggest">
        {
          (isLoading)
            ? <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div> </div> : '' }
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps} />
      </div>
    )
  }
}

EntityAutosuggest.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onRequest: PropTypes.func,
  params: PropTypes.object,
  defaultTerm: PropTypes.string,
  eventId: PropTypes.number,
  url: PropTypes.string.isRequired
}

export default EntityAutosuggest
