import * as dom from '../lib/dom.js'
import axios from '../lib/axios.js'

let timeout = null
let stats = []

const commit = async function() {
  await axios.post('/helper_apis/sponsor_stats', {
    sponsor_stats: {
      event_id: window.eventId,
      stats
    }
  })

  stats = []
}

const track = (action, brand, placement, instantCommit) => {
  stats.push({ action, brand, placement })

  if (instantCommit) {
    commit()
    return
  }

  if (timeout) {
    clearTimeout(timeout)
  }

  timeout = setTimeout(commit, 2000)
}

const trackableViews = document.querySelectorAll('.js-sponsor-track-view')

dom.forEach(trackableViews, (element) => {
  const brand = dom.data(element, 'brand')
  const placement = dom.data(element, 'placement')

  track('view', brand, placement)
})

const trackableClicks = document.querySelectorAll('.js-sponsor-track-click')

dom.bindForEach(trackableClicks, 'click', (event) => {
  const element = dom.findTarget(event.target, 'js-sponsor-track-click')
  const brand = dom.data(element, 'brand')
  const placement = dom.data(element, 'placement')

  track('click', brand, placement)
})
