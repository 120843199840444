// See more about creating validators on ./export.js

import axios from '../../lib/axios.js'
import validate from 'validate.js'
import { bracketify } from '../lib/stringFns.js'

export default function(value, _undef, fieldName) {
  fieldName = bracketify(fieldName)
  const field = document.querySelector(`input[name="${fieldName}"]`)

  if (!field) {
    return Promise.resolve()
  }

  const url = '/helper_apis/user_email_uniqueness'
  value = field.value

  if (field.value.trim() === '') {
    return Promise.resolve()
  }

  const promise = new validate.Promise(function(resolve) {
    axios
      .get(url, { params: { email: value } })
      .then((response) => {
        if (response.data === true) {
          resolve()
          return
        }

        resolve('emailUniqueness')
      })
      .catch(() => {
        resolve('emailUniqueness')
      })
  })

  return promise
}
