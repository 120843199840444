/* global dom */

const tryToRemoveFlashMessages = () => {
  const child = document.querySelector('.c-flash-messages__item')
  const item = document.querySelector('.c-flash-messages')

  if (!item || child) {
    return
  }

  dom.remove(item)
}

const onRemoverClick = (event) => {
  event.preventDefault()
  const target = dom.findTarget(event.target, 'js-parent-remover')
  const parentKlass = dom.data(target, 'parent')
  const parent = dom.parents(target, parentKlass)
  dom.remove(parent)

  tryToRemoveFlashMessages()
}

const removers = document.querySelectorAll('.js-parent-remover')
dom.forEach(removers, (remover) => {
  remover.addEventListener('click', onRemoverClick)
})
