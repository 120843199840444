/* global React, PropTypes, i18n */

const t = i18n.t

class EditMeetingSpot extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newMeetingSpot: '',
      charCount: this.props.state.data.spot.length,
    }

    this.onConfirm = this.onConfirm.bind(this)
  }

  onConfirm(event) {
    event.preventDefault()
    this.props.onNewMeetingSpot(this.state.newMeetingSpot)
  }

  render() {
    const { charCount } = this.state
    const maxChars = 255

    if (this.props.currentView !== 'EditMeetingSpot') {
      return ''
    }

    return (
      <React.Fragment>
        <div className="c-modal__content c-meeting c-meeting--editmeetingspot">
          <a className="c-meeting__back" href="#" onClick={this.props.backToSummary}>&laquo; {t('go_back')}</a>

          <h2>{t('fill_the_new_meeting_room_and_confirm')}</h2>

          {status === 'confirmed' ? (
            <div className="c-alert c-alert--warning">
              {t('changing_room_and_saving_will_make_pending')}
            </div>
          ) : (
            <div className="c-alert c-alert--warning">
              {t('you_must_save_after_picking_room')}
            </div>
          )}

          <textarea
            maxLength={255}
            style={{ width: '100%', height: '4rem' }}
            defaultValue={this.props.state.data.spot}
            onChange={(event) => {
              this.setState({
                newMeetingSpot: event.target.value,
                charCount: event.target.value.length,
              })
            }}
            rows={4}
          />
          <p>{charCount}/{maxChars}</p>
        </div>

        <footer className="c-modal__footer u-ta-right">
          <button className="c-btn c-btn--lg1" type="submit" onClick={this.onConfirm}>
            {t('confirm')}
          </button>
        </footer>
      </React.Fragment>
    )
  }
}

EditMeetingSpot.propTypes = {
  oldMeetingSpot: PropTypes.string,
  onPrevious: PropTypes.func.isRequired,
  currentView: PropTypes.string.isRequired
}

export default EditMeetingSpot
