/*eslint-disable no-unused-vars*/
import React from 'react'
import ReactDOM from 'react-dom'
import DuplicatedDocumentModal from '../components/DuplicatedDocumentModal.jsx'
/*eslint-enable no-unused-vars*/

export default function() {
  const modal = document.querySelector('.js-react-duplicated-document-modal')
  if (modal) {
    const doc = modal.getAttribute('data-document')
    const title = modal.getAttribute('data-title')
    const email = modal.getAttribute('data-email')
    const obfuscatedEmail = modal.getAttribute('data-obfuscated-email')
    const flaggedEmail = modal.getAttribute('data-flagged-email')
    const docOwnerId = modal.getAttribute('data-document-owner-id')
    ReactDOM.render(<DuplicatedDocumentModal
      doc={doc}
      title={title}
      email={email}
      obfuscatedEmail={obfuscatedEmail}
      flaggedEmail={flaggedEmail}
      docOwnerId={docOwnerId}
    />, modal)
  }
}
