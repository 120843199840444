/*eslint-disable no-unused-vars*/
import React from 'react'
import ReactDOM from 'react-dom'
import Messages from '../../components/Messages.jsx'
/*eslint-enable no-unused-vars*/

export default function() {
  const messages = document.querySelector('[data-react-class="Messages"]')
  if (messages) {
    const props = JSON.parse(messages.getAttribute('data-react-props'))
    ReactDOM.render(<Messages {...props} />, messages)
  }
}
