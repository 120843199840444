/* global React, PropTypes, i18n */

const t = i18n.t

class EditOnlineMeetingUrl extends React.Component {
  constructor() {
    super()

    this.state = {
      newOnlineMeetingUrl: ''
    }

    this.onConfirm = this.onConfirm.bind(this)
  }

  onConfirm(event) {
    event.preventDefault()
    this.props.onNewOnlineMeetingUrl(this.state.newOnlineMeetingUrl)
  }

  render() {
    if (this.props.currentView !== 'EditOnlineMeetingUrl') {
      return ''
    }

    return <React.Fragment>
      <div className="c-modal__content c-meeting c-meeting--editonlinemeetingurl">
        <a className="c-meeting__back" href="#" onClick={this.props.backToSummary}>&laquo; {t('go_back')}</a>

        <h2>{t('fill_the_new_online_meeting_room_and_confirm')}</h2>

        {
          status === 'confirmed'
            ? <div className="c-alert c-alert--warning">
              {t('changing_room_and_saving_will_make_pending')}
            </div>
            : <div className="c-alert c-alert--warning">
              {t('you_must_save_after_picking_room')}
            </div>
        }

        <input
          type="text"
          defaultValue={this.props.state.data.spot}
          onChange={(event) => {
            this.setState({
              newOnlineMeetingUrl: event.target.value
            })
          }}
        />
      </div>

      <footer className="c-modal__footer u-ta-right">
        <button className="c-btn c-btn--lg1" type="submit" onClick={this.onConfirm}>{t('confirm')}</button>
      </footer>
    </React.Fragment>
  }
}

EditOnlineMeetingUrl.propTypes = {
  oldOnlineMeetingUrl: PropTypes.string,
  onPrevious: PropTypes.func.isRequired,
  currentView: PropTypes.string.isRequired
}

export default EditOnlineMeetingUrl
