/* global axios, dom, i18n */

let fetchStatesTO = null
let firstStateLoad = true
const t = i18n.t

const fetchStates = async function(countryCode) {
  clearTimeout(fetchStatesTO)
  fetchStatesTO = null

  const stateSelect = document.querySelector('.js-country-state-selector')
  const defaultValue = dom.data(stateSelect, 'default-value')
  stateSelect.options[0] = new Option(`${t('loading')}...`, 'none', false, false)
  stateSelect.options.length = 1

  const url = '/helper_apis/country_states'
  const response = await axios.get(url, { params: { country_code: countryCode } })

  if (response.data.length === 0) {
    stateSelect.options[0] = new Option(t('country_without_states'), 'no_state', false, false)
    stateSelect.options.length = 1
    return
  }

  const states = response.data.map((state) => {
    return new Option(state.name, state.id, false, false)
  })

  const noneOpt = new Option(t('select_state'), 'none', false, false)

  ;[].concat([noneOpt], states).forEach((opt, index) => {
    stateSelect.options[index] = opt
  })

  if (!firstStateLoad) {
    return
  }

  firstStateLoad = false
  stateSelect.value = defaultValue
}

const onChange = (event) => {
  const selector = event.target

  const oldValue = dom.data(selector, 'current-value')
  const newValue = selector.value

  if (oldValue === newValue) {
    event.preventDefault()
    return
  }

  if (fetchStatesTO !== null) {
    clearTimeout(fetchStatesTO)
    fetchStatesTO = null
  }

  fetchStatesTO = setTimeout(() => fetchStates(newValue), 200)
}

document.addEventListener('i18n:onReady', () => {
  const selectors = document.querySelectorAll('.js-country-selector')
  dom.forEach(selectors, (selector) => {
    selector.addEventListener('change', onChange)
    const event = new Event('change')
    selector.dispatchEvent(event)
  })
})
