/*eslint-disable no-unused-vars*/
import React from 'react'
import BusyCell from './BusyCell.jsx'
/*eslint-enable no-unused-vars*/

import PropTypes from 'prop-types'

const CalendarLine = (props) => {
  const {
    title,
    rowIndex,
    lineTitle,
    onTimeSelect,
    cellInstruction,
    cellDisabledByUser,
    parentState,
    onRemoveRequest,
    host
  } = props

  if (lineTitle === 'timeskip') {
    if (rowIndex === 0) {
      return <div className="c-calendar__cell c-calendar__cell--line-title c-calendar__cell--timeskip"></div>
    }

    return <div
      className="c-calendar__cell c-calendar__cell--timeskip">
      ...
    </div>
  }

  if (rowIndex === 0) {
    return <div
      className="c-calendar__cell c-calendar__cell--line-title">
      {lineTitle}
    </div>
  }

  const isTimeSelectable = (typeof onTimeSelect !== 'undefined')
  const cCellInstruction = (typeof cellInstruction !== 'undefined')
    ? cellInstruction.trim()
    : ''
  const cCellDisabledByUser = (typeof cellDisabledByUser !== 'undefined')
    ? cellDisabledByUser.trim()
    : ''
  const klass = 'c-calendar__cell'
  const timeStr = `${title} ${lineTitle}:00`
  const busy = parentState.data.busy_slices[timeStr]
  const isBusy = (typeof busy !== 'undefined' && busy.user_status !== 'canceled')
  const isSoft = isBusy &&
                 typeof busy.affected_types !== 'undefined' &&
                 busy.affected_types.length === 0
  const isDisabled = timeStr < parentState.data.config.opening_datetime ||
                     timeStr >= parentState.data.config.closing_datetime ||
                     (isBusy && busy.type === 'disabled')

  // const availableFrom = parentState.data.config.availableFrom
  // const availableTo = parentState.data.config.availableTo

  if (isDisabled) {
    return <div className={`${klass} ${klass}--disabled css-tooltip`} dataTooltip={cCellDisabledByUser}>
    </div>
  }

  if (!isTimeSelectable && !isBusy) {
    return <div
      className={klass}>
    </div>
  }

  if (isTimeSelectable && !isBusy) {
    return <div
      onClick={(e) => onTimeSelect(e, timeStr)}
      className={`${klass} c-calendar__cell--selectable`}>
      {
        cCellInstruction !== ''
          ? <p className="c-calendar__cell__instruction">{cCellInstruction}</p>
          : ''
      }
    </div>
  }

  if (isTimeSelectable && isSoft) {
    return <div className={klass}>
      <BusyCell
        host={host}
        busy={busy}
        baseKlass={`${klass}__subcell ${klass}__subcell--busy`}
        onRemoveRequest={onRemoveRequest}
        disableModals={props.disableModals}
      />
      <div
        onClick={(e) => onTimeSelect(e, timeStr)}
        className={`${klass}__subcell ${klass}__subcell--selectable`}>
        {
          cCellInstruction !== '' &&
            <p className="c-calendar__cell__instruction">{cCellInstruction}</p>
        }
      </div>
    </div>
  }

  return <BusyCell
    host={host}
    busy={busy}
    baseKlass={klass}
    onRemoveRequest={onRemoveRequest}
    disableModals={props.disableModals}
  />
}

CalendarLine.propTypes = {
  title: PropTypes.string.isRequired,
  host: PropTypes.number.isRequired,
  rowIndex: PropTypes.number.isRequired,
  lineTitle: PropTypes.string.isRequired,
  onTimeSelect: PropTypes.func,
  onRemoveRequest: PropTypes.func,
  cellInstruction: PropTypes.string,
  cellDisabledByUser: PropTypes.string,
  parentState: PropTypes.object.isRequired,
  disableModals: PropTypes.bool
}

export default CalendarLine
