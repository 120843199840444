/*eslint-disable no-unused-vars*/
import React from 'react'
import ReactDOM from 'react-dom'
import TicketReadMoreButton from '../components/TicketReadMoreButton.jsx'
/*eslint-enable no-unused-vars*/

export default function() {
  const target = document.querySelector('[data-react-class="TicketReadMoreButton"]')

  if (target) {
    const props = JSON.parse(target.getAttribute('data-react-props'))
    ReactDOM.render(<TicketReadMoreButton {...props} />, target)
  }
}
