import summaryUpdate from './summaryUpdate'
import { hasCoupon, removeCoupon } from './coupon'

const t = i18n.t

const showSpecialCondition = () => {
    if (!hasCoupon()) {
        const countrySpecialCondition = document.querySelector('#special_condition_container')

        if (countrySpecialCondition) {
            countrySpecialCondition.style.display = 'block'
        }
    }
}

const hideSpecialCondition = () => {
    const countrySpecialCondition = document.querySelector('#special_condition_container')

    if (countrySpecialCondition) {
        countrySpecialCondition.style.display = 'none'
    }
}

const init = () => {
    dom.ready(() => {
        const countrySpecialCondition = document.querySelector('#special_condition')

        if (countrySpecialCondition) {
            countrySpecialCondition.addEventListener('change', () => {
                if (countrySpecialCondition.checked) {
                    removeCoupon()
                }
                
                summaryUpdate()
            })
        }
    })
}

export { 
    init,
    showSpecialCondition,
    hideSpecialCondition
}