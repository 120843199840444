/* global dom, fitVids */

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import '../images/ios-share-icon.png'
import '../images/paypal.png'
import '../images/stripe.png'

import '../css/application.scss'

// Sentry for JS disabled due to quota limits
// import './generics/sentry.js'

import './generics/globals.js'
import './generics/main-menu.js'
import './generics/parent-remover.js'
import './generics/checkbox-group.js'
import './generics/alert-btn.js'
import './generics/fitvids.js'
import './generics/favorites.js'
import './generics/delete-form.js'
import './generics/confirmable-form.js'
import './generics/toggle-user-admin.js'
import './generics/platform-text-close.js'
import './generics/send-message-btn.js'
import './generics/speakers-show.js'
import './generics/infinite-scroll.js'
import './generics/add-to-home.js'
import './generics/enable-push-notifications.js'
import './generics/sponsor-tracking.js'
import './generics/message-alert.js'
import './generics/cached-checkboxes-selectors.js'

import 'date-input-polyfill'
import './pack-forms/generics/masks.js'
import './pack-forms/generics/uploaders.js'
import './pack-forms/generics/country-state-selectors.js'
import './pack-forms/generics/limited-fields.js'
import './pack-forms/generics/tabbed-fields.js'
import './pack-forms/forms/user.js'
import './pack-forms/forms/user-preferences-for-event.js'
import './pack-forms/forms/company.js'
import './pack-forms/forms/project.js'
import './pack-forms/forms/ticket-order.js'
import './pack-forms/forms/company-choices.js'

import automountReact from './generics/automount-react-components.js'

import * as i18n from './lib/i18n.js'

fitVids('.js-fitvids')

dom.addClass(document.querySelector('html'), 'has-js')

i18n
  .init()
  .then(() => {
    automountReact()
  })
