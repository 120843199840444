/* global dom, swal, i18n */

import setValidations from '../lib/setValidations.js'

const t = i18n.t

let constraints = {
  'user.picture': {
    presence: true
  },
  'user.name': {
    presence: true
  },
  'user.email': {
    presence: true,
    email: true,
    emailUniqueness: true
  },
  'user.data.gender': {
    presence: true
  },
  'user.data.birth_date': {
    presence: true,
    format: {
      pattern: /^(\d{4})-(\d{2})-(\d{2})$/
    }
  },
  'user.data.country': {
    presence: true,
    exclusion: {
      within: ['none']
    }
  },
  'user.data.state': {
    presence: true,
    exclusion: {
      within: ['none']
    }
  },
  'user.data.city': {
    presence: true
  },
  'user.data.phone': {
    presence: true
  },
  'user.data.cpf': {
    length: { maximum: 11 },
    presenceIf: {
      targetName: 'user.data.country',
      targetMustHaveValue: 'BR'
    },
    cpfValid: {
      ignoreEmpty: true
    }
  },
  'user.document': {
    presenceUnless: {
      targetName: 'user.data.country',
      targetMustNotHaveValue: 'BR'
    },
    passportValid: {
      ignoreEmpty: true
    }
  }
}

if (!document.querySelector('.c-form--user-edit')) {
  constraints = Object.assign(constraints, {
    'user.password': {
      presence: true,
      length: { minimum: 8 }
    },
    'user.password_confirmation': {
      presence: true,
      equality: 'user.password',
      length: { minimum: 8 }
    }
  })
} else {
  const onlineMeetingInfo = !!document.querySelector('[name="user[user_preferences_for_event][online_meeting_info]"]')

  constraints = Object.assign(constraints, {
    'user.password': {
      length: { minimum: 8 }
    },
    'user.password_confirmation': {
      presenceIf: {
        targetName: 'user.password'
      },
      equality: 'user.password',
      length: { minimum: 8 }
    },
    'user.data.linkedin_profile_url': {
      format: {
        pattern: /^https?:\/\/(www\.)?linkedin\.com\/.+$/
      }
    },
  })

  if (document.querySelector('#user-preferences-header')) {
    constraints = Object.assign(constraints, {
      'user.user_preferences_for_event.available_for_meetings_from': {
        presence: true
      },
      'user.user_preferences_for_event.available_for_meetings_to': {
        valueGreaterThan: {
          field: 'user[user_preferences_for_event][available_for_meetings_from]'
        },
        presenceIf: {
          targetName: 'user.user_preferences_for_event.available_for_meetings_from'
        }
      },
    })
  }

  if (onlineMeetingInfo) {
    constraints['user.user_preferences_for_event.online_meeting_info'] = {
      presenceIf: {
        targetName: 'user.user_preferences_for_event.attendance_type',
        targetMustHaveValue: 'online'
      }
    }
  }
}

if (document.querySelector('.form_field_event_day_slices')) {
  constraints = Object.assign(constraints, {
    'user.event_day_slices': {
      presence: true
    }
  })
}

const toggleFieldsForOnlineEvents = (event) => {
  const fieldsWrapper = document.querySelector('.c-user-preferences-for-event__only-online')
  const currentValue = event.target.value

  if (currentValue === 'online') {
    dom.removeClass(fieldsWrapper, 'u-hidden')
    return
  }

  dom.addClass(fieldsWrapper, 'u-hidden')
}

const onFormError = (e) => {
  swal(
    t('form_errors'),
    t('please_review_form'),
    'warning'
  )

  const eventDaySlices = document.querySelectorAll('.slice_checkbox_event_day')
  const selectSliceDayError = document.getElementById('select_slice_day_error')
  

  if (e['user.event_day_slices']) {
    for (const slice of eventDaySlices) {
      slice.style.border = '1 px solid hsl(354deg, 77%, 56%)'
      selectSliceDayError.style.display = 'block'
    }
  } else {
    for (const slice of eventDaySlices) {
      slice.style.border = null
      selectSliceDayError.style.display = 'none'
    }
  }
}

const onFormSuccess = () => {
  document.querySelector('.c-form--user').submit()
}

const onCountryChange = (event) => {
  let country = event.target.value
  let divFormCPF = document.getElementById('form_field_user_cpf')
  let divFormDocument = document.getElementById('form_field_user_passport')

  if (!divFormCPF && !divFormDocument) {
    return
  }

  if (country == 'BR') {
    divFormCPF.style.display = 'block'
    divFormDocument.style.display = 'none'
    document.getElementById('user_passport').value = ''
  }
  else {
    divFormCPF.style.display = 'none'
    divFormDocument.style.display = 'block'
    document.getElementById('user_cpf').value = ''
  }
}

dom.ready(() => {
  const attendanceTypeField = document.querySelector('[name="user[user_preferences_for_event][attendance_type]"]')
  if (attendanceTypeField) {
    attendanceTypeField.addEventListener('change', toggleFieldsForOnlineEvents)

    const event = new Event('change')
    attendanceTypeField.dispatchEvent(event)
  }

  setValidations('.c-form--user', constraints, onFormSuccess, onFormError)

  const selectSliceDayError = document.getElementById('select_slice_day_error')
  if (selectSliceDayError) {
    selectSliceDayError.style.display = 'none'
  }

  const selectors = document.querySelectorAll('.js-country-selector')
  dom.forEach(selectors, (selector) => {
    selector.addEventListener('change', onCountryChange)
    const event = new Event('change')
    selector.dispatchEvent(event)
  })
})
