import * as dom from '../lib/dom.js'

/*eslint-disable no-unused-vars*/
import React from 'react'
import ReactDOM from 'react-dom'
import Talk from '../components/Schedule/Talk'
/*eslint-enable no-unused-vars*/

export default function() {
  const talks = document.querySelectorAll('[data-react-class="Schedule/Talk"]')
  if (talks.length > 0) {
    dom.forEach(talks, (talk) => {
      const props = JSON.parse(talk.getAttribute('data-react-props'))
      ReactDOM.render(<Talk {...props} />, talk)
    })
  }
}
