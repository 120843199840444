/* global dom */

const onChange = (event) => {
  const input = event.target
  const parent = dom.parents(input, 'c-form__checkbox-group')
  const nearParent = dom.parents(input, 'c-form__checkbox-group__title')
  const children = parent.querySelector('.c-form__checkbox-group__children')
  const expander = nearParent.querySelector('.c-form__checkbox-group__expander')

  if (!children) {
    return
  }

  if (input.checked) {
    expander.innerHTML = '-'
    children.style.display = 'block'
    return
  }

  expander.innerHTML = '+'
  children.style.display = 'none'

  children.querySelectorAll('input').forEach(input => {
    input.checked = false;
  });
}

const inputs = document.querySelectorAll('.c-form__checkbox-group input')

dom.forEach(inputs, (input) => {
  onChange({ target: input })
  input.addEventListener('change', onChange)
})
