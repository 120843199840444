/* global React, PropTypes, i18n, swal, axios */

const t = i18n.t

/*eslint-disable no-unused-vars*/
import FirstStep from './FirstStep.jsx'
import SecondStep from './SecondStep.jsx'
import ThirdStep from './ThirdStep'
import FourthStep from './FourthStep'
/*eslint-enable no-unused-vars*/

class InviteModal extends React.Component {
  constructor() {
    super()

    this.onClose = this.onClose.bind(this)
    this.onNext = this.onNext.bind(this)
    this.onSubmit = this.onSubmit.bind(this)

    this.state = {
      step: 1,
      lastStep: 3,
      submittingInvitation: false,
      creationData: {
        guests: [],
        guestData: [],
        hostData: {},
        datetime: null,
        meetingType: null,
        spotType: 'auto',
        message: null
      }
    }
  }

  toStepHandler = (step) => () => {
    this.setState({ step });
  };

  onClose(event) {
    event.preventDefault()
    this.props.onClose()
  }

  onNext(newData, additionalData) {
    const creationData = Object.assign({}, this.state.creationData, newData)
    const step = this.state.step + 1

    let newState = { creationData, step }
    if (additionalData) {
      newState = Object.assign({}, { creationData, step }, additionalData)
    }

    this.setState(newState)
  }

  async onSubmit(newData) {
    const creationData = Object.assign({}, this.state.creationData, newData)

    await this.setState({
      creationData,
      submittingInvitation: true
    })

    const create = await axios.post('/time_slices/meetings', {
      time_slice: {
        guests: creationData.guests,
        datetime: creationData.datetime,
        spot_type: creationData.spotType,
        message: creationData.message,
        meeting_type: creationData.meetingType,
        spot_custom_text: creationData.spotCustomText,
      }
    })

    const createRs = create.data

    if (createRs.success) {
      const alert = await swal({
        title: t('meeting_created'),
        type: 'success',
        text: t('now_just_wait'),
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: 'OK'
      })

      this.props.onClose(createRs.time_slice_id)
      return
    }

    await this.setState({
      submittingInvitation: false
    })

    swal({
      title: t(createRs.error),
      type: 'warning',
      text: t('please_choose_another_datetime'),
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'OK'
    })
  }

  render() {
    if (this.props.hidden) {
      return ''
    }

    const { userCompany, targetCompany } = this.props

    return <section className="c-modal c-modal--time-slice" onClick={this.onClose}>
      <div className="c-modal__wrapper" onClick={(event) => event.stopPropagation()}>
        <header className="c-modal__header">
          <h1>
            {t('meeting_from_with', {
              host_company: userCompany.name,
              guest_company: targetCompany.name
            })}
          </h1>

          <button type="close" onClick={this.onClose}>&times;</button>
        </header>

        <FirstStep
          isActive={this.state.step === 1}
          userId={this.props.userId}
          companyId={this.props.companyId}
          onNext={this.onNext}
          onlineCanInvite={this.props.onlineCanInvite}
        />

        <SecondStep
          isActive={this.state.step === 2}
          userId={this.props.userId}
          hostData={this.state.creationData.hostData}
          guestData={this.state.creationData.guestData}
          guests={this.state.creationData.guests}
          onPrevious={this.toStepHandler(1)}
          onNext={this.onNext}
          dates={this.props.dates}
          eventAttendanceType={window.eventAttendanceType}
        />
        {this.state.step === 3 && (
          <ThirdStep
            creationData={this.state.creationData}
            onPrevious={this.toStepHandler(2)}
            onNext={this.onNext}
            onSubmit={this.onSubmit}
            onClose={this.onClose}
          />
        )}
        <FourthStep
          isActive={this.state.step === 4}
          onPrevious={this.toStepHandler(2)}
          currentData={this.state.creationData}
          submittingInvitation={this.state.submittingInvitation}
          onSubmit={this.onSubmit}
          userId={this.props.userId}
          companyId={this.props.companyId}
          onNext={this.onNext}
          eventAttendanceType={window.eventAttendanceType}
        />
      </div>
    </section>
  }
}

InviteModal.propTypes = {
  userCompany: PropTypes.object,
  targetCompany: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  hidden: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  online: PropTypes.bool,
}

export default InviteModal
