import React from 'react'
import PropTypes from 'prop-types'
import { t } from '../../packs/lib/i18n.js'

/*eslint-disable no-unused-vars*/
import SendBox from './SendBox.jsx'
/*eslint-enable no-unused-vars*/

class ShowChatContent extends React.Component {
  constructor() {
    super()
    this.scrollToBottom = this.scrollToBottom.bind(this)
    this.messagesEnd = null
  }

  scrollToBottom() {
    if (!this.messagesEnd) {
      return
    }

    this.messagesEnd.scrollIntoView({ behavior: 'smooth' })
  }

  componentDidMount() {
    this.scrollToBottom()
  }

  componentDidUpdate() {
    this.scrollToBottom()
  }

  render () {
    let { status, messages, requesterId } = this.props
    const systemMessageDisabled = true

    if (systemMessageDisabled) {
      messages = messages.filter((x) => !x.systemMessage)
    }

    if (status === 'loading') {
      return <p className="c-messages__show-chat__empty">
        {t('loading')}&hellip;
      </p>
    }

    if (status === 'error') {
      return <p className="c-messages__show-chat__empty">
        {t('messages.show_chat_content.error')}<br/>:-(
      </p>
    }

    if (messages.length === 0) {
      return <React.Fragment>
        <div className="c-messages__thread">
          <p className="c-messages__thread__empty">
            {t('messages.show_chat_content.empty')}
          </p>
        </div>

        <SendBox
          chatId={this.props.chatId}
          afterSubmit={this.props.afterMessageSubmit}
        />
      </React.Fragment>
    }

    return <React.Fragment>
      <div className="c-messages__thread">
        <ul className="u-plain-list">
          {
            messages.map((message) => {
              if (message.systemMessage) {
                try {
                  const json = JSON.parse(message.message)
                  return <li
                    className="c-messages__ping c-messages__ping--system"
                    key={`message_in_thread_${message.id}`}>
                    <p>{t(`messages.show_chat_content.pings.${json.type}.${json.status}`, json.vars)}</p>
                  </li>
                } catch (err) {
                  return <li
                    className="c-messages__ping c-messages__ping--system"
                    key={`message_in_thread_${message.id}`}>
                    <p>{message.message}</p>
                  </li>
                }
              }

              const baseKlass = 'c-messages__ping'
              let klass = baseKlass
              if (message.senderId === requesterId) {
                klass = `${baseKlass} ${baseKlass}--self`
              }

              return <li
                className={klass}
                key={`message_in_thread_${message.id}`}>
                <div className="c-messages__ping__bubble">
                  <div className="c-messages__ping__thumb">
                    <img src={message.avatar} />
                  </div>

                  <div className="c-messages__ping__text">
                    {message.message.split('\n').map((line, lindex) => (
                      <p key={`msg_${message.index}_line_${lindex}`}>{line}</p>
                    ))}
                  </div>
                </div>
                <p className="c-messages__ping__colophon">
                  <small>{message.sender}, {message.sentAt}</small>
                </p>
              </li>
            })
          }
        </ul>

        <div ref={(el) => { this.messagesEnd = el }} />
      </div>

      <SendBox
        chatId={this.props.chatId}
        afterSubmit={this.props.afterMessageSubmit}
      />
    </React.Fragment>
  }
}

ShowChatContent.propTypes = {
  status: PropTypes.string.isRequired,
  chatId: PropTypes.number.isRequired,
  messages: PropTypes.array.isRequired,
  requesterId: PropTypes.number.isRequired,
  afterMessageSubmit: PropTypes.func.isRequired
}

export default ShowChatContent
