/* global React, PropTypes, i18n */

/*eslint-disable no-unused-vars*/
import Calendar from '../../../../components/Calendar.jsx'
/*eslint-enable no-unused-vars*/

const t = i18n.t


class EditDatetime extends React.Component {
  constructor() {
    super()

    this.onTimeSelect = this.onTimeSelect.bind(this)
  }

  onTimeSelect(datetime) {
    this.props.onDatetimePick(datetime)
  }

  render() {
    if (this.props.currentView !== 'EditDatetime') {
      return ''
    }

    const { id, spot, users, status, meeting_type } = this.props.state.data
    const spotType = spot ? 'auto' : 'custom'
    const hostId = users.filter((x) => x.host)[0].id
    const guests = users.filter((x) => !x.host).map((x) => x.id)
    const someOnlineUsers = users.some((x) => x.online)
    const timezones = users.filter((x) => !x.current_user)
      .map((x) => `${x.name} - UTC${x.time_zone_utc_offset}`)
      .join(', ')
    const dateRangeType = meeting_type === 'online' ? 'online_meetings' : 'meetings'

    return <React.Fragment>
      <div className="c-modal__content c-meeting c-meeting--editdatetime">
        <a className="c-meeting__back" href="#" onClick={this.props.backToSummary}>&laquo; {t('go_back')}</a>

        <h2>{t('click_to_change_your_datetime')}</h2>

        {
          someOnlineUsers
            ? <div className="c-alert c-alert--warning">
              {t('be_mindful_of_participants_timezones')}<br/>
              {timezones}
            </div>
            : ''
        }

        {
          status === 'confirmed'
            ? <div className="c-alert c-alert--warning">
              {t('changing_time_and_saving_will_make_pending')}
            </div>
            : <div className="c-alert c-alert--warning">
              {t('you_must_save_after_picking')}
            </div>
        }

        <Calendar
          onTimeSelect={this.onTimeSelect}
          timeSliceId={id}
          ignoreFilledSpots={spotType === 'custom' || meeting_type === 'online'}
          host={hostId}
          guests={guests}
          spotType={spotType}
          dateRangeType={dateRangeType}
          disableModals={true}
        />
      </div>

      <footer className="c-modal__footer">
      </footer>
    </React.Fragment>
  }
}

EditDatetime.propTypes = {
  onPrevious: PropTypes.func.isRequired,
  currentView: PropTypes.string.isRequired
}

export default EditDatetime
