/*eslint-disable no-unused-vars*/
import React from 'react'
import ReactDOM from 'react-dom'
import Schedule from '../components/Schedule'
/*eslint-enable no-unused-vars*/

export default function() {
  const schedule = document.querySelector('[data-react-class="Schedule"]')
  if (schedule) {
    const props = JSON.parse(schedule.getAttribute('data-react-props'))
    ReactDOM.render(<Schedule {...props} />, schedule)
  }
}
