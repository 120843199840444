/* global dom, axios, swal, formToObject, i18n */

const t = i18n.t

const forms = document.querySelectorAll('.js-favorite-toggler')

const onSubmit = async function(event) {
  event.preventDefault()

  const form = event.target
  const button = form.querySelector('button')
  const url = form.getAttribute('action')
  const values = formToObject(form)

  dom.addClass(button, 'c-btn--loading')
  button.disabled = true

  const result = await axios.post(`${url}.json`, values)
  const data = result.data

  button.disabled = false

  if (data.success) {
    if (data.state === 'created') {
      button.setAttribute('class', 'c-btn c-btn--secondary')
      button.innerHTML = `&starf; ${t('favorite_btn.unmark_favorite')}`
      return
    }

    button.setAttribute('class', 'c-btn c-btn--secondary c-btn--negative')
    button.innerHTML = `&starf; ${t('favorite_btn.favorite')}`
    return
  }

  dom.removeClass(button, 'c-btn--loading')
  swal(t('error'), data.error, 'error')
}

dom.forEach(forms, (form) => form.addEventListener('submit', onSubmit))
