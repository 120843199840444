import * as dom from '../lib/dom.js'
import axios from '../lib/axios.js'
import * as i18n from '../lib/i18n.js'
import swal from 'sweetalert2'
import formToObject from 'form_to_object'
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

window.dom = dom
window.axios = axios
window.i18n = i18n
window.swal = swal
window.React = React
window.ReactDOM = ReactDOM
window.PropTypes = PropTypes
window.formToObject = formToObject
