/* global dom */

export default function() {
  const inputs = document.querySelectorAll('[name="ticket_order[ticket_id]"]')
  let currentInput = null

  dom.forEach(inputs, (input) => {
    if (!input.checked) {
      return
    }

    currentInput = input
  })

  if (currentInput) {
    return currentInput
  }

  return inputs[0]
}
