import React from 'react'
import PropTypes from 'prop-types'
import axios from '../../packs/lib/axios.js'

/*eslint-disable no-unused-vars*/
import Modal from '../Modal.jsx'
/*eslint-enable no-unused-vars*/

class GTSModal extends React.Component {
  constructor() {
    super()

    this.fetchContent = this.fetchContent.bind(this)

    this.state = {
      loading: false,
      content: '::unloaded::'
    }
  }

  UNSAFE_componentWillMount() {
    this.fetchContent()
  }

  async fetchContent() {
    const { loading, content } = this.state
    const { busy } = this.props

    if (loading || content !== '::unloaded::') {
      return
    }

    this.setState({ loading: true })

    const url = `/time_slices/global_time_slices/${busy.id}`
    const response = await axios.get(url)

    const newContent = response
      .data
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')

    this.setState({
      loading: false,
      content: newContent
    })
  }

  render() {
    const { busy, onClose } = this.props
    const { loading, content } = this.state

    return <Modal
      title={busy.name}
      isOpen={true}
      componentKlass=""
      footerComponent={() => ''}
      onClose={onClose}>
      <div>
        { loading && <p>Loading...</p> }
        { !loading && <div dangerouslySetInnerHTML={{ __html: content }}></div> }
      </div>
    </Modal>
  }
}

GTSModal.propTypes = {
  busy: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}

export default GTSModal
