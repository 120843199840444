/*
 * Validations creation rules
 *
 * All validators must export a function that:
 *
 * - Takes the following as arguments:
 * - value: string value. Represents the user input
 * - options: object/value given to validator
 *      For instance, if the validator received the following:
 *        presenceIf: { targetName: 'something' }
 *      options will be `{ targetName: 'something' }`
 *      If the validator receives a string, the value will be a string, etc.
 * - fieldName: name of the field being validated
 *
 * - Returns the following values:
 *   - `null` when the validation passes
 *   - The validators name when the validation fails
 *     (`presenceIf` would return 'presenceIf', for instance)
 *
 * IMPORTANT: If your validator is not checking for presence, consider
 *            returning `null` whenever the field/value does not exist.
 *
*/

import emailUniqueness from './emailUniqueness.js'
import presenceIf from './presenceIf.js'
import presenceUnless from './presenceUnless.js'
import cnpjValid from './cnpjValid.js'
import cpfValid from './cpfValid.js'
import passportValid from './passportValid.js'
import valueGreaterThan from './valueGreaterThan.js'
import valueLessThan from './valueLessThan.js'

export default {
  emailUniqueness,
  presenceIf,
  presenceUnless,
  cnpjValid,
  cpfValid,
  passportValid,
  valueGreaterThan,
  valueLessThan
}
