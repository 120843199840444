/* global React, PropTypes, i18n, axios, dom */

/*eslint-disable no-unused-vars*/
import Autosuggest from 'react-autosuggest'
/*eslint-enable no-unused-vars*/

const t = i18n.t

function getSuggestionValue(suggestion) {
  return suggestion.name
}

class CompaniesAutosuggest extends React.Component {
  constructor() {
    super()

    this.state = {
      event_id: null,
      value: '',
      suggestions: [],
      isLoading: false,
      termIncludes: {
        name: true,
        description: false,
        users: false,
        projects: false
      },
      companyBoolean: {
        only_favorite: false,
        only_at_current_event: false
      }
    }

    this.lastRequestId = null
    this.loadSuggestions = this.loadSuggestions.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this)
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this)
    this.renderSuggestion = this.renderSuggestion.bind(this)
  }

  renderSuggestion(suggestion) {
    return (
      <div className="react-autosuggest__company" onClick={() => {
        this.props.onSelect({
          id: suggestion.id,
          name: suggestion.name,
          logo: suggestion.logo,
          term: this.state.value
        })
      }}>
        <img src={suggestion.logo} />
        <span>{suggestion.name}</span>
      </div>
    )
  }

  loadSuggestions(value) {
    // Cancel the previous request
    if (this.lastRequestId !== null) {
      clearTimeout(this.lastRequestId)
    }

    if (!value || value.length < 3) {
      this.setState({
        isLoading: false
      })
      return
    }

    this.setState({
      isLoading: true
    })

    this.lastRequestId = setTimeout(() => {
      let includes = this.state.termIncludes

      if (typeof this.props.termIncludes !== 'undefined') {
        includes = Object.assign(includes, this.props.termIncludes)
      }

      let company_booleans = this.state.companyBoolean
      if (typeof this.props.termIncludes !== 'undefined') {
        company_booleans = Object.assign(company_booleans, this.props.companyBoolean)
      }

      axios.get('/helper_apis/companies_by_name', {
        params: {
          event_id: this.state.event_id,
          term: value,
          includes,
          company_booleans
        }
      })
        .then((response) => {
          this.setState({
            isLoading: false,
            suggestions: response.data
          })
        })
    }, 500)
  }

  onChange(event, { newValue }) {
    event.preventDefault()

    this.setState({
      value: newValue
    })
  }

  onSuggestionsFetchRequested({ value }) {
    if (typeof this.props.onRequest !== 'undefined') {
      this.props.onRequest(value)
    }

    this.loadSuggestions(value)
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: []
    })
  }

  UNSAFE_componentWillMount() {
    let stateChanges = {}

    if (typeof this.props.eventId !== 'undefined') {
      stateChanges.event_id = this.props.eventId
    }

    if (typeof this.props.defaultTerm !== 'undefined') {
      stateChanges.value = this.props.defaultTerm
    }

    if (typeof this.props.termIncludes !== 'undefined') {
      stateChanges.termIncludes = Object
        .assign(this.state.termIncludes, this.props.termIncludes)
    }

    this.setState(stateChanges)
  }

  shouldComponentUpdate() {
    return true
  }

  render() {
    const { value, suggestions, isLoading } = this.state

    const inputProps = {
      placeholder: t('type_to_load'),
      value,
      onChange: this.onChange
    }

    return (
      <div className="c-companies-autosuggest">
        {
          (isLoading)
            ? <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div> </div> : '' }
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps} />
      </div>
    )
  }
}

CompaniesAutosuggest.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onRequest: PropTypes.func,
  termIncludes: PropTypes.object,
  companyBoolean: PropTypes.object,
  defaultTerm: PropTypes.string,
  eventId: PropTypes.number
}

export default CompaniesAutosuggest
