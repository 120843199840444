/* global React, PropTypes, i18n, swal, axios */

/*eslint-disable no-unused-vars*/
import GuestName from './InviteBox/FirstStep/GuestName.jsx'
/*eslint-enable no-unused-vars*/

const t = i18n.t

class EditGuestsBox extends React.Component {
  constructor() {
    super()

    this.onGuestChange = this.onGuestChange.bind(this)
    this.updateUsers = this.updateUsers.bind(this)
    this.onStartLoading = this.onStartLoading.bind(this)
    this.onClose = this.onClose.bind(this)
    this.onModalClose = this.onModalClose.bind(this)
    this.onPostUpdate = this.onPostUpdate.bind(this)
    this.errors = []
    this.hostKey = 'hostGuests'
    this.targetKey = 'targetGuests'
    this.state = {
      status: 'loading',
      data: {
        hostPossibleUsers: [],
        hostInvitedUsers: [],
        targetPossibleUsers: [],
        targetInvitedUsers: [],
        checkedUserIds: {},
        hostId: 0,
      },
      errors: {},
    }
  }

  componentDidMount() {
    this.data()
  }

  async data() {
    if (this.state.status !== 'loading') {
      return ''
    }

    try {
      const availableUsersResponse = await axios.get(`/time_slices/meetings/${this.props.sliceId}/available_users.json`,
        { params: {
          user_id: this.props.userId,
          company_id: this.props.targetCompanyId
        } }
      )
      const invitedGuestsResponse = await axios.get(`/time_slices/meetings/${this.props.sliceId}/invited_guests.json`)
      const availableUsersData = availableUsersResponse.data
      const invitedGuestsData = invitedGuestsResponse.data
      const hostId = invitedGuestsData.host_id
      const checkedHostIds = invitedGuestsData.host_company_guests.filter((x) => x.id !== hostId)
      const checkedTargetIds = invitedGuestsData.target_company_guests
      const checkedUserIds = { hostGuests: checkedHostIds, targetGuests: checkedTargetIds }

      this.setState({
        status: 'loaded',
        data: {
          hostPossibleUsers: availableUsersData.host_company_users,
          hostInvitedUsers: invitedGuestsData.host_company_guests,
          targetPossibleUsers: availableUsersData.target_company_users,
          targetInvitedUsers: invitedGuestsData.target_company_guests,
          checkedUserIds: checkedUserIds,
          hostId: hostId
        },
      })
    } catch (err) {
      this.setState({ status: 'error' })
    }
  }

  async onGuestChange(guest, key) {
    const newState = this.state
    const guest_id = guest.id
    const checkedUserIds = newState.data.checkedUserIds
    if (checkedUserIds[key].includes(guest_id)) {
      if (key === this.targetKey && checkedUserIds.targetGuests.length <= 1) {
        await swal({text: t('edit_guests.cannot_leave_side_empty'), type: 'warning'})
      } else {
        const index = checkedUserIds[key].indexOf(guest_id)
        checkedUserIds[key].splice(index, 1)
      }
    } else {
      checkedUserIds[key].push(guest_id)
    }
    this.setState(newState)
  }

  render_error() {
    return <div className="c-modal__content c-edit_guests">
      <span>{t('error_please_try_later')}</span>
    </div>
  }

  render_loading() {
    return <div className="c-modal__content c-edit_guests">
      <span>{t('loading')}&hellip;</span>
    </div>
  }

  emptyGuestColumn() {
    return <div className="c-modal__content c-invite-modal__edit_guests">
      <div className="c-modal__content__guests">
      </div>
    </div>
  }

  filledGuestColumn(possibleGuestList, invitedGuestList, title, key) {
    return <div key={key}>
      <h3>{title}</h3>
      <ul>
        {
          possibleGuestList.map((guest) => (
            <li key={`invite-modal-guest-${guest.id}`}>
              <label>
                <input type="checkbox"
                  onChange={() => this.onGuestChange(guest, key)}
                  checked={this.state.data.checkedUserIds.hostGuests.includes(guest.id) || this.state.data.checkedUserIds.targetGuests.includes(guest.id) || guest.id === this.state.data.hostId}
                  disabled={!guest.accept_meetings || !guest.can_be_invited || guest.id === this.state.data.hostId || !guest.available}
                />
                <GuestName guest={guest} eventAttendanceType={window.eventAttendanceType} />
              </label>
            </li>
          ))
        }
      </ul>
    </div>
  }

  hostGuestsColumn() {
    if (this.state.data.hostPossibleUsers.length === 0) {
      return this.emptyGuestColumn()
    }

    return this.filledGuestColumn(this.state.data.hostPossibleUsers, this.state.data.hostInvitedUsers, t('edit_guests.guests_from', { company: this.props.hostCompanyName }), this.hostKey)
  }

  targetGuestsColumn() {
    if (this.state.data.targetPossibleUsers.length === 0) {
      return this.emptyGuestColumn()
    }

    return this.filledGuestColumn(this.state.data.targetPossibleUsers, this.state.data.targetInvitedUsers, t('edit_guests.guests_from', { company: this.props.targetCompanyName }), this.targetKey)
  }

  render_complete_page() {
    return <React.Fragment>
      <section className="c-modal c-modal--time-slice" onClick={(event) => this.onModalClose(event)}>
        <div className="c-modal__wrapper" onClick={(event) => event.stopPropagation()}>
          <header className="c-modal__header">
            <h1>
              {t('meeting_from_with', {
                host_company: this.props.hostCompanyName,
                guest_company: this.props.targetCompanyName
              })}
            </h1>

            <button type="close" onClick={this.onClose}>&times;</button>
          </header>

          <div className="c-modal__content c-edit-guests-modal">
            <h2>{t('select_guests')}</h2>
            <br/>
            <h5><b>{t('edit_guests.disabled_warning')}</b></h5>
            <br/>
            <div className="c-modal__content__guests">
              { this.hostGuestsColumn() }
              { this.targetGuestsColumn() }
            </div>

            <footer className="c-modal__footer">
              <button className='c-btn c-btn--save u-float-right' type='alert' disabled={ this.isSaveButtonDisabled() } onClick={ this.updateUsers }>
                { this.saveButtonText() }
              </button>
            </footer>
          </div>
        </div>
      </section>
    </React.Fragment>
  }

  saveButtonText() {
    switch (this.state.status) {
    case 'loading':
      return '...'
    case 'loaded':
      return t('edit_guests.button_text_save')
    case 'error':
      return t('edit_guests.button_text_error')
    default:
      return '...'
    }
  }

  isSaveButtonDisabled() {
    return this.state.status !== 'loaded'
  }

  userIsRemovingThemselves() {
    return this.props.userId !== this.state.data.hostId &&
      !this.state.data.checkedUserIds.hostGuests.includes(this.props.userId) &&
      !this.state.data.checkedUserIds.targetGuests.includes(this.props.userId)
  }

  async updateUsers() {
    const checkedUserIds = this.state.data.checkedUserIds
    const isUserRemovingThemselves = this.userIsRemovingThemselves()
    if (isUserRemovingThemselves) {
      const confirmation = await swal({
        title: t('edit_guests.are_you_sure'),
        text: t('edit_guests.removing_yourself'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('yes_sure'),
        cancelButtonText: t('negative')
      })
      if (!confirmation.value) {
        return
      }
    }
    const newState = this.state
    newState.status = 'loading'
    this.setState(newState)

    axios.post(`/time_slices/meetings/${this.props.sliceId}/update_guests`, {
      host_id: this.state.data.hostId,
      new_users: checkedUserIds.hostGuests.concat(checkedUserIds.targetGuests)
    }
    ).then(() => {
      this.onPostUpdate()
    }).catch(() => {
      const newState = this.state
      newState.status = 'error'
      this.setState(newState)
    })
  }

  async onPostUpdate() {
    await swal({text: t('edit_guests.guest_change_success'), type: 'success'})
    this.resetState()
    location.reload()
  }

  onStartLoading() {
    const newState = this.state
    newState.status = 'loading'
    this.setState(newState)
    this.data()
  }

  onClose() {
    this.resetState()
    this.props.changeParentState('loading')
  }

  onModalClose(event) {
    this.resetState()
    this.props.closeParent(event)
  }

  resetState() {
    this.setState({
      status: 'hidden',
      data: {
        hostPossibleUsers: [],
        hostInvitedUsers: [],
        targetPossibleUsers: [],
        targetInvitedUsers: [],
        checkedUserIds: {},
        hostId: 0,
      },
      errors: {},
    }
    )
    this.errors = []
  }

  render() {
    switch (this.state.status) {
    case 'hidden':
      return ''
    case 'loading':
      return this.render_loading()
    case 'error':
      return this.render_error()
    default:
      return this.render_complete_page()
    }
  }
}

EditGuestsBox.propTypes = {
  userId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  sliceId: PropTypes.number.isRequired,
  hostCompanyName: PropTypes.string.isRequired,
  targetCompanyId: PropTypes.number.isRequired,
  targetCompanyName: PropTypes.string.isRequired,
  changeParentState: PropTypes.func.isRequired,
  closeParent: PropTypes.func.isRequired,
}

export default EditGuestsBox
