/*eslint-disable no-unused-vars*/
import React from 'react'
/*eslint-enable no-unused-vars*/

import PropTypes from 'prop-types'
import { t } from '../../packs/lib/i18n.js'

const SelectedUsers = ({ users, onRemove }) => {
  if (users.length === 0) {
    return <div className="c-messages__new-chat__users c-messages__new-chat__users--empty">
      <p>{ t('messages.selected_users.search_users_above') }</p>
    </div>
  }

  return <ul className="c-messages__new-chat__users">
    {users.map((user) => (
      <li key={`users_for_chat_${user.id}`}>
        <button
          className="c-btn c-btn--sm1"
          type="rm"
          onClick={(event) => {
            event.preventDefault()
            onRemove(user.id)
          }}>
          <span>{user.name}</span><span>|</span><span>&times;</span>
        </button>
      </li>
    ))}
  </ul>
}

SelectedUsers.propTypes = {
  users: PropTypes.array.isRequired,
  onRemove: PropTypes.func.isRequired
}

export default SelectedUsers
