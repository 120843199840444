/* global React, PropTypes, i18n */

/*eslint-disable no-unused-vars*/
import Calendar from '../../../components/Calendar.jsx'
import CalendarDropdown from '../../../components/CalendarDropdown.jsx'
/*eslint-enable no-unused-vars*/

const t = i18n.t

const Footer = (props) => {
  const { onPrevious } = props

  return <footer className="c-modal__footer">
    <button
      className="c-btn c-btn--secondary u-float-left"
      type="previous"
      onClick={onPrevious}>
      {t('back_to_guests')}
    </button>
  </footer>
}

Footer.propTypes = {
  onPrevious: PropTypes.func.isRequired
}

class SecondStep extends React.Component {
  constructor() {
    super()

    this.isCreationDataValid = this.isCreationDataValid.bind(this)
    this.onPrevious = this.onPrevious.bind(this)
    this.onTimeSelect = this.onTimeSelect.bind(this)
    this.changeDateRangeType = this.changeDateRangeType.bind(this)

    this.state = {
      status: 'loading',
      data: {
        busySices: []
      },
      creationData: {
        datetime: null,
        meetingType: null,
        spotType: 'auto'
      },
      dateRangeType: null
    }
  }

  availableMeetingTypes(props) {
    if (props.eventAttendanceType !== 'mixed') {
      return [props.eventAttendanceType]
    }

    if(props.dates['online_meetings'].longRangeDateSelect) {
      return ['physical', 'online']
    } else {
      return ['online', 'physical']
    }
  }

  isCreationDataValid() {
    this.creationData.datetime !== null && this.creationData.meetingType !== null
  }

  onPrevious(e) {
    e.preventDefault()
    this.props.onPrevious()
  }

  onTimeSelect(datetime) {
    this.props.onNext({
      datetime,
      meetingType: this.state.creationData.meetingType,
      spotType: this.state.creationData.spotType
    })
  }

  changeDateRangeType(e, dateRangeType) {
    e.preventDefault()
    const meetingType = dateRangeType === 'online_meetings' ? 'online' : 'physical'
    const spotType = meetingType === 'online' ? 'online' : 'auto'
    const creationData = {
      ...this.state.creationData,
      meetingType,
      spotType
    }
    this.setState({ dateRangeType, creationData })
  }

  UNSAFE_componentWillMount() {
    const availableMeetingTypes = this.availableMeetingTypes(this.props)
    const meetingType = availableMeetingTypes[0]
    const spotType = meetingType === 'online' ? 'online' : 'auto'
    const creationData = { ...this.state.creationData, meetingType, spotType }
    if (meetingType === 'online') {
      this.setState({ dateRangeType: 'online_meetings', creationData })
    } else {
      this.setState({ dateRangeType: 'meetings', creationData })
    }
  }

  render() {
    const props = this.props

    if (!this.props.isActive) {
      return ''
    }

    const status = this.state.status

    if (status === 'error') {
      return <React.Fragment>
        <div className="c-modal__content c-invite-modal__step2">
          <span>{t('error_please_try_later')}</span>
        </div>
        <Footer onPrevious={this.onPrevious} />
      </React.Fragment>
    }

    const availableMeetingTypes = this.availableMeetingTypes(props)

    return <React.Fragment>
      <div className="c-modal__content c-invite-modal__step2">
        <h2>{t('click_to_choose_datetime')}</h2>

        {
          availableMeetingTypes.length === 1 &&
          this.props.eventAttendanceType === 'mixed' &&
          <div className="c-alert">{t(`meeting_type_alert_${availableMeetingTypes[0]}`)}</div>
        }

        {
          availableMeetingTypes.length > 1 &&
          <div className="c-calendar-wrapper">
            <nav className="c-calendar-wrapper__type">
              {
                availableMeetingTypes.map((type) => {
                  const dateRangeType = type === 'physical' ? 'meetings' : 'online_meetings'
                  const buttonTypeClass = this.state.dateRangeType === dateRangeType
                    ? 'c-btn--secondary-selected'
                    : 'c-btn--secondary'

                  console.log(this.props)

                  return <button
                    key={`meeting-types-${type}`}
                    className={`c-btn c-btn--sm1 ${buttonTypeClass}`}
                    onClick={(e) => this.changeDateRangeType(e, dateRangeType)}
                  >
                    {t(`calendar_for_user.${dateRangeType}`)}<br />
                    <span className="date-tab">
                      {
                        t(
                          `calendar_for_user.${dateRangeType}_date`,
                          {
                            date_from: props.dates[dateRangeType]['start_at'],
                            date_to: props.dates[dateRangeType]['end_at']
                          }
                        )
                      }
                    </span>
                  </button>
                })
              }
            </nav>
          </div>
        }

        {this.state.dateRangeType && props.dates[this.state.dateRangeType].longRangeDateSelect ? (
          <CalendarDropdown
            onTimeSelect={this.onTimeSelect}
            host={this.props.userId}
            guests={this.props.guests}
            spotType={this.state.spotType}
            dateRangeType={this.state.dateRangeType}
            availableAttendanceTypes={availableMeetingTypes}
            eventTimeZone={window.eventTimeZone}
            userTimeZone={window.userTimeZone}
            disableModals={true}
            cellDisabledByUser={t('disabled_by_user')}
            {...props}
          />
        ) : (
          <Calendar
            onTimeSelect={this.onTimeSelect}
            host={this.props.userId}
            guests={this.props.guests}
            spotType={this.state.spotType}
            dateRangeType={this.state.dateRangeType}
            availableAttendanceTypes={availableMeetingTypes}
            eventTimeZone={window.eventTimeZone}
            disableModals={true}
            cellDisabledByUser={t('disabled_by_user')}
            {...props}
          />
        )}
      </div>
      <Footer onPrevious={this.onPrevious} />
    </React.Fragment>
  }
}

SecondStep.propTypes = {
  eventAttendanceType: PropTypes.string.isRequired,
  guestData: PropTypes.array.isRequired,
  guests: PropTypes.array.isRequired,
  hostData: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
}

export default SecondStep
