import * as dom from '../lib/dom.js'
import { t } from '../lib/i18n.js'
import swal from 'sweetalert2'

const onSubmit = async function(event) {
  event.preventDefault()

  const form = event.target

  const confirmation = await swal({
    title: t('are_you_sure_question'),
    text: t('irreversible_action'),
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: t('yes_sure'),
    cancelButtonText: t('cancel')
  })

  if (!confirmation.value) {
    return
  }

  form.submit()
}

const forms = document.querySelectorAll('.js-confirmable-form')
dom.forEach(forms, (form) => form.addEventListener('submit', onSubmit))
