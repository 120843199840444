import React from 'react'
import PropTypes from 'prop-types'
import { t } from '../../../lib/i18n.js'

import swal from 'sweetalert2'
import axios from '../../../lib/axios.js'

class RegisterBtn extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false
    }

    this.token = this.token.bind(this)
    this.loggedIn = this.loggedIn.bind(this)
  }

  loggedIn() {
    if (this.props.forIframe === true) {
      return false
    }

    return this.props.userSignedIn === true
  }

  token() {
    const csrf = this.props.csrfToken

    if (typeof csrf === 'undefined' && typeof window !== 'undefined') {
      return document.querySelector('[name=csrf-token]').getAttribute('content')
    }

    return csrf
  }

  render() {
    const {
      id,
      isAttendee,
      timeSliceId,
      userCanAttend,
      placesLeft,
      forIframe,
      fullTalkWarning,
      bookingEnabled,
      bookingDisabledWarning
    } = this.props

    if (forIframe === true) {
      return ''
    }

    return (
      <React.Fragment>
        {isAttendee && this.loggedIn()
          ? <form action={`/time_slices/talks/${timeSliceId}`} method="POST" acceptCharset="utf-8" className="c-form--btn" onSubmit={(e) => {
            e.preventDefault()

            const form = e.target

            swal({
              title: t('are_you_sure_question'),
              text: '',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: t('yes_sure'),
              cancelButtonText: t('cancel')
            }).then((confirmation) => {
              if (!confirmation.value) {
                return
              }

              form.submit()
            })
          }}>
            <input type="hidden" name="utf8" value="✓" />
            <input type="hidden" name="time_slice[talk_id]" value={id} />
            <input type="hidden" name="authenticity_token" value={this.token()} />
            <input type="hidden" name="_method" value="delete" />
            <input type="hidden" name="back" value="true" />
            <button type="submit" className="c-btn c-btn__attendee">
              <span>{t('schedule.talk.attendee')}</span>
              <small>{t('schedule.talk.click_to_sign_out')}</small>
            </button>
          </form>
          : ''}

        {!isAttendee && placesLeft < 1 && this.loggedIn()
          ? !bookingEnabled
              ? <span className="c-btn c-btn__attendee c-btn--disabled">
                  <div dangerouslySetInnerHTML={ { __html: bookingDisabledWarning } } />
                </span>
              : <span className="c-btn c-btn__attendee c-btn--disabled">
                  <div dangerouslySetInnerHTML={ { __html: fullTalkWarning } } />
                </span>
          : ''
        }

        {!isAttendee && placesLeft > 0 && this.loggedIn() && userCanAttend
          ? <form
            action="/time_slices/talks"
            method="POST"
            acceptCharset="utf-8"
            onSubmit={(e) => {
              e.preventDefault()
              this.setState({ loading: true })

              axios
                .post('/time_slices/talks.json', {
                  time_slice: {
                    talk_id: id,
                  },
                  utf8: '✓',
                  authenticity_token: this.token()
                })
                .then((data) => {
                  this.setState({ loading: false })

                  const talkAlertKey = window.eventAttendanceType === 'online'
                    ? 'talk_success_online'
                    : 'talk_success'

                  if (data.data.success) {
                    swal({
                      title: '',
                      type: 'success',
                      text: t(talkAlertKey),
                      showCloseButton: true,
                      showCancelButton: false,
                      focusConfirm: false,
                      confirmButtonText: 'OK'
                    })
                      .then(() => {
                        document && document.location.reload(true)
                      })
                  } else {
                    swal({
                      title: data.data.message || t('talk_' + data.data.error),
                      type: 'error',
                      text: '',
                      showCloseButton: true,
                      showCancelButton: false,
                      focusConfirm: false,
                      confirmButtonText: 'OK'
                    })
                  }
                })
            }}
            className="c-form--btn">
            <input type="hidden" name="utf8" value="✓" />
            <input type="hidden" name="time_slice[talk_id]" value={id} />
            <input type="hidden" name="authenticity_token" value={this.token()} />
            {
              !this.state.loading
                ? <button type="submit" className="c-btn c-btn__attendee">
                  <span>{t('schedule.talk.attend')}</span>
                </button>
                : <button type="submit" className="c-btn c-btn__attendee c-btn--loading" disabled="disabled">
                  <span>{t('schedule.talk.attend')}</span>
                </button>
            }
          </form>
          : ''}

        {(!isAttendee && placesLeft > 0 && this.loggedIn() && !userCanAttend) || !this.loggedIn()
          ? <a href="#"
            onClick={(e) => {
              e.preventDefault()

              swal({
                title: '',
                type: 'info',
                text: t('schedule.talk.in_order_to_book'),
                showCloseButton: true,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: t('schedule.talk.register_here'),
                cancelButtonText: t('schedule.talk.login'),
              }).then((response) => {
                if (response.value === true) {
                  window.location = '/accounts/new'
                }
                else if (response.dismiss == 'cancel') {
                  window.location = '/auth/users/sign_in'
                }
              })
            }}
            className="c-btn c-btn__attendee">
            <span>{t('schedule.talk.attend')}</span>
          </a>
          : ''}
      </React.Fragment>
    )
  }
}

RegisterBtn.propsTypes = {
  id: PropTypes.integer,
  csrfToken: PropTypes.string,
  isAttendee: PropTypes.string,
  forIframe: PropTypes.boolean,
  timeSliceId: PropTypes.integer,
  userCanAttend: PropTypes.boolean,
  userSignedIn: PropTypes.boolean,
  placesLeft: PropTypes.integer,
  fullTalkWarning: PropTypes.string,
  bookingEnabled: PropTypes.boolean,
  bookingDisabledWarning: PropTypes.string
}

export default RegisterBtn
