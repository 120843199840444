import axios from '../../packs/lib/axios.js'

const MOBILE_SCREEN_WIDTH = 540;
const DEFAULT_MAX_DAYS_IN_CALENDAR = 4;

const isMobileBrowser = () => typeof window !== 'undefined' && window.innerWidth <= MOBILE_SCREEN_WIDTH
const today = () => {
  const year = new Date().getFullYear()
  const monthIndex = new Date().getMonth() + 1
  const month = (monthIndex < 10) ? `0${monthIndex}` : monthIndex
  const dayIndex = new Date().getDate()
  const day = (dayIndex < 10) ? `0${dayIndex}` : dayIndex

  return `${year}-${month}-${day}`
}
const calcMaxDaysInCalendar = ({ days, visual_day_spread }) => {
  if (isMobileBrowser()) {
    return 1
  }

  let maxDaysInCalendar = visual_day_spread || DEFAULT_MAX_DAYS_IN_CALENDAR

  return (days.length < maxDaysInCalendar)
    ? days.length
    : maxDaysInCalendar
}
const calendarPageIndex = (days, maxDays) => {
  let index = days.indexOf(today())

  if (index >= 0) {
    return Math.trunc(index / maxDays)
  }

  if (days[days.length - 1] < today()) {
    return Math.trunc((days.length - 1) / maxDays)
  }

  return 0
}

const fetchCalendarData = async (params) => {
  let data
  try {
    const response = await axios.get('/calendar.json', { params })
    data = response.data
  } catch(_e) {
    return { status: 'error' }
  }

  const days = data.config.days
  const maxDays = calcMaxDaysInCalendar(data.config)
  const index = calendarPageIndex(days, maxDays)

  return {
    index,
    previousMaxDays: maxDays,
    maxDays,
    data,
    status: 'loaded',
  }
}

export default fetchCalendarData
