const truncate = (str, limit) => {
  if (str.length <= limit) {
    return str
  }

  return `${str.substring(0, limit - 3)}...`
}

export {
  truncate
}
