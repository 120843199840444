/* global swal, i18n */

const t = i18n.t

const suggestPossibleActions = async () => {

  let needReview = t('event_preferences_alert.options.need_review')
  needReview = needReview.replace("{url}", videoTutorialURL)

  const alertOptions = {
    'info_is_correct': t('event_preferences_alert.options.info_is_correct'),
    'need_review': needReview,
    'review_later': t('event_preferences_alert.options.review_later')
  }

  let contentHTML = t('event_preferences_alert.content_html');
  contentHTML = contentHTML.replace("{url}", videoTutorialURL)

  const { value: chosenOption } = await swal.fire({
    title: t('event_preferences_alert.title'),
    html: `<div class="swal2-event-preferences-content">${contentHTML}</div>`,
    input: 'radio',
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    inputOptions: alertOptions,
    inputValidator: (value) => {
      if (!value) {
        return t('event_preferences_alert.please_choose_an_option')
      }
    }
  })

  return chosenOption
}

const handleNeedReview = async () => {
  
  const inputName = 'input[name="user_preferences_for_event[online_meeting_info]"]'
  const inputEl = document.querySelector(inputName)
  const { value: onlineMeetingUrl } = await swal.fire({
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    title: t('event_preferences_alert.options_output.need_review'),
    input: 'text',
    inputValue: inputEl.value,
    confirmButtonText: t('event_preferences_alert.save_preferences'),
    inputValidator: (value) => {
      if (!value || value.trim() === '') {
        return t('event_preferences_alert.please_write_something')
      }
    }
  })

  inputEl.value = onlineMeetingUrl
}

const handleChosenOption = async (chosenOption) => {

  let htmlText = t(`event_preferences_alert.options_output.${chosenOption}`)
  htmlText = htmlText.replace("{url}", videoTutorialURL)

  await swal.fire({
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    title: t('event_preferences_alert.confirmation'),
    html: htmlText,
    confirmButtonText: t('event_preferences_alert.save_preferences')
  })
}

const confirmOnlineMeetingUrlFlow = async () => {
  const chosenOption = await suggestPossibleActions()

  if (chosenOption == 'need_review') {
    await handleNeedReview()
  } else {
    await handleChosenOption(chosenOption)
  }
}

const onPreferencesSubmit = (values) => {
  const form = document.querySelector('.c-form--user-preferences-for-event')
  const { attendance_type } = values.user_preferences_for_event
  const onlineMeetingInfo = !!document.querySelector('[name="user_preferences_for_event[online_meeting_info]"]')

  /*if (attendance_type != 'online' || !onlineMeetingInfo) {
    form.submit()
    return
  }*/

  confirmOnlineMeetingUrlFlow().then(() => form.submit())
}

export default onPreferencesSubmit
