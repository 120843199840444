/* global React, PropTypes */

/*eslint-disable no-unused-vars*/
import ActionButtons from './InviteBox/ActionButtons.jsx'
import InviteModal from './InviteBox/InviteModal.jsx'
import ShowModal from './InviteBox/ShowModal.jsx'
/*eslint-enable no-unused-vars*/

class InviteBox extends React.Component {
  constructor() {
    super()

    this.onInviteClick = this.onInviteClick.bind(this)
    this.onShowClick = this.onShowClick.bind(this)
    this.updateHostId = this.updateHostId.bind(this)

    this.state = {
      userCompany: null,
      targetCompany: null,
      timeSliceId: null,
      inviteModalHidden: true,
      showModalHidden: true,
      actionRefresh: 1,
      online: false,
      hostId: 0,
    }
  }

  componentDidMount() {
    this.updateHostId(this.props.hostId)
  }

  onInviteClick(receivedState) {
    this.setState({
      userCompany: receivedState.userCompany,
      targetCompany: receivedState.targetCompany,
      inviteModalHidden: false,
      startFreshInviteModal: true,
      online: receivedState.online
    })
  }

  onShowClick(receivedState) {
    this.setState({
      userCompany: receivedState.userCompany,
      targetCompany: receivedState.targetCompany,
      online: receivedState.online,
      timeSliceId: receivedState.timeSlice.id,
      showModalHidden: false
    })
  }

  UNSAFE_componentWillMount() {
    if (this.props.sliceId && this.props.sliceId > 0) {
      this.setState({ timeSliceId: this.props.sliceId })
    }
  }

  updateHostId(hostId) {
    const newState = this.state
    newState.hostId = hostId
    this.setState(newState)
  }

  render() {
    const props = this.props
    const sliceId = this.state.timeSliceId || props.sliceId
    const abProps = Object.assign({}, props, { sliceId })
    const klass = this.props.chat
      ? 'c-invite-box-comp c-invite-box-comp--chat'
      : 'c-invite-box-comp'

    return (
      <div className={klass}>
        <ActionButtons
          actionRefresh={this.state.actionRefresh}
          onInviteClick={this.onInviteClick}
          onShowClick={this.onShowClick}
          updateParent={this.updateHostId}
          {...abProps} />

        {
          !this.state.showModalHidden &&
          <ShowModal
            onClose={() => {
              this.setState({
                showModalHidden: true,
                actionRefresh: this.state.actionRefresh + 1
              })
            }}
            timeSliceId={this.state.timeSliceId}
            hidden={this.state.showModalHidden}
            userCompany={this.state.userCompany}
            targetCompany={this.state.targetCompany}
            online={this.state.online}
            {...props } />
        }

        {
          !this.state.inviteModalHidden &&
          <InviteModal
            onClose={(newTimeSliceId) => {
              const timeSliceId = newTimeSliceId ? newTimeSliceId : this.state.timeSliceId

              this.setState({
                inviteModalHidden: true,
                timeSliceId,
                actionRefresh: this.state.actionRefresh + 1
              })
            }}
            hidden={this.state.inviteModalHidden}
            userCompany={this.state.userCompany}
            targetCompany={this.state.targetCompany}
            online={this.state.online}
            {...props } />
        }
      </div>
    )
  }
}

InviteBox.propTypes = {
  userId: PropTypes.number,
  companyId: PropTypes.number,
  sliceId: PropTypes.number,
  chat: PropTypes.bool,
  online: PropTypes.bool,
  hostId: PropTypes.number,
  userCompanyId: PropTypes.number,
  userCompanyName: PropTypes.string,
  hostCompanyName: PropTypes.string,
  targetCompanyId: PropTypes.number,
  targetCompanyName: PropTypes.string,
}

export default InviteBox
