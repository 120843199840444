import * as dom from '../../lib/dom.js'
import setValidations from '../lib/setValidations.js'

const constraints = {
  'project.pictures': {
    presence: { allowEmpty: false }
  },
  'project.name': {
    presence: true
  },
  'project.translations.en.description': {
    presence: true,
    length: { maximum: 1000 }
  },
  'project.translations.pt-BR.description': {
    length: { maximum: 1000 }
  },
  'project.translations.es.description': {
    length: { maximum: 1000 }
  },
  'project.project_types': {
    presence: { allowEmpty: false }
  }
}

dom.ready(() => setValidations('.c-form--project', constraints, undefined, undefined, true))
