/* global dom */

import setValidations from '../lib/setValidations.js'

const t = i18n.t

const constraints = {
  'company.picture': {
    presence: true
  },
  'company.name': {
    presence: true
  },
  'company.translations.en.description': {
    presence: true,
    length: { maximum: 1000 }
  },
  'company.translations.pt-BR.description': {
    length: { maximum: 1000 }
  },
  'company.translations.es.description': {
    length: { maximum: 1000 }
  },
  'company.data.people_size': {
    presence: true
  },
  'company.data.contact_email': {
    presence: true,
    email: true
  },
  'company.data.country': {
    presence: true,
    exclusion: {
      within: ['none']
    }
  },
  'company.data.state': {
    presence: true,
    exclusion: {
      within: ['none']
    }
  },
  'company.data.city': {
    presence: true
  },
  'company.occupation_areas': {
    presence: true
  },
  'company.data.cnpj': {
    length: { maximum: 14 },
    presenceIf: {
      targetName: 'company.data.brazilian_company_without_cnpj',
      targetChecked: false
    },
    cnpjValid: {
      ignoreEmpty: true
    }
  },
  'company.data.brazilian_company_without_cnpj': {
    presenceIf: {
      targetName: 'company.data.cnpj',
      targetMustHaveValue: ''
    }
  }
}

const onFormError = () => {
  swal(
    t('form_errors'),
    t('please_review_form'),
    'warning'
  )
}

const onFormSuccess = () => {
  document.querySelector('.c-form--company').submit()
}

const onCountryChange = (event) => {
  let country = event.target.value
  let withoutCNPJ = document.getElementById('company_data_brazilian_company_without_cnpj')

  if (withoutCNPJ) {
    if (country == 'BR') {
      withoutCNPJ.checked = false
    }
    else {
      withoutCNPJ.checked = true
    }
  }
}

dom.ready(() => setValidations('.c-form--company', constraints, onFormSuccess, onFormError))

document.addEventListener('DOMContentLoaded', function (e) {
  document.addEventListener('input', function (event) {

    // Only run on our select menu
    if (event.target.id !== 'company_data_country') return

    var element = document.getElementById('brazilian_fields')

    if (event.target.value == 'BR') {
      element.classList.remove('u-hidden')
    } else {
      element.classList.add('u-hidden')
    }

  }, false)

  const numbersOnly = document.querySelector('.numbers-only')

  if (numbersOnly) {
    numbersOnly.addEventListener('keypress', function preventNonNumbersInInput(event) {
      var characters = String.fromCharCode(event.which)
      if (!(/[0-9]/.test(characters))) {
        event.preventDefault()
      }
    })
    numbersOnly.addEventListener('paste', function pasteTest(event) {
      window.setTimeout(() => {
        var characters = event.target.value
        window.setTimeout(() => {
          if (!(/^\d+$/.test(characters))) {
            event.target.value = event.target.value.replace(/\D/g, '')
          }
        })
      })
    })
  }

  const selectors = document.querySelectorAll('.js-country-selector')
  dom.forEach(selectors, (selector) => {
    selector.addEventListener('change', onCountryChange)
    const event = new Event('change')
    selector.dispatchEvent(event)
  })
})
