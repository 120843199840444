/* global dom, swal, i18n */

import { DirectUpload } from '@rails/activestorage'

const t = i18n.t

class Uploader {
  constructor(file, input) {
    this.input = input
    this.url = dom.data(this.input, 'direct-upload-url')
    this.file = file
    this.box = dom.parents(this.input, 'js-uploader')
    this.container = this.box.querySelector('.c-form__uploader__files')
    this.uploader = new DirectUpload(this.file, this.url, this)
    this.uniqId = this.uniqid()
    this.uniqClass = `c-form__uploader__file--${this.uniqId}`
  }

  uniqid() {
    const now = new Date().getTime()
    var n = Math.floor(Math.random() * 11)
    var k = Math.floor(Math.random() * 1000000)
    return now * n * k
  }

  wrapper(content) {
    const wrapper = document.querySelector(`.${this.uniqClass}`)
    const newHTML = `
      <div class="c-form__uploader__file__progress"></div>
      ${content}
    `

    if (wrapper) {
      wrapper.innerHTML = `
        <div class="c-form__uploader__file__progress"></div>
        ${content}
      `

      return
    }

    let el = document.createElement('DIV')
    el.setAttribute('class', `c-form__uploader__file ${this.uniqClass}`)
    el.innerHTML = newHTML
    this.container.appendChild(el)
  }

  addStubToContainer() {
    this.wrapper(`
      <div class="c-form__uploader__file__img-stub"></div>
    `)
  }

  addItemToContainer(blob) {
    const reader = new FileReader()

    reader.onload = (event) => {
      const dataUrl = event.target.result

      this.wrapper(`
        <img src="${dataUrl}" class="c-form__uploader__file__preview">
        <input name="${this.input.name}" type="hidden" value="${blob.signed_id}">
        <button class="js-multiupload-remover" type="remove">&times;</button>
      `)

      const form = dom.parents(this.box, 'c-form')
      const evt = new Event('uploader:revalidateForm')
      form.dispatchEvent(evt)
    }

    reader.readAsDataURL(this.file)
  }

  fileSize(format = 'mb') {
    const sizeInBytes = this.file.size
    const sizeInKb = sizeInBytes / 1000
    const sizeInMb = sizeInKb / 1000

    if (format === 'kb') {
      return parseInt(sizeInKb, 10)
    }

    if (format === 'mb') {
      return parseInt(sizeInMb, 10)
    }

    return sizeInBytes
  }

  sizeIsValid() {
    const maxSizeStr = dom.data(this.input, 'max-size', '20mb')
    const format = maxSizeStr.replace(/[\d]/gi, '')
    const maxSize = parseInt(maxSizeStr.replace(/[\D]/gi, ''), 10)
    const currentSize = this.fileSize(format)
    const valid = currentSize <= maxSize

    if (!valid) {
      swal(t('attention'), t('file_max_size', { size: maxSizeStr }), 'warning')
    }

    return valid
  }

  quantityIsValid() {
    const maxFiles = parseInt(dom.data(this.input, 'max-files', '10'), 10)

    const uploadedCount = this
      .container
      .querySelectorAll('.c-form__uploader__file')
      .length

    const uploadingCount = this.input.files.length
    const total = uploadedCount + uploadingCount

    const valid = (total <= maxFiles)

    if (!valid) {
      swal(t('attention'), t('file_max_quantity', { quantity: maxFiles }), 'warning')
    }

    return valid
  }

  isValid() {
    if (!this.sizeIsValid()) {
      return
    }

    return true
  }

  upload() {
    if (!this.isValid()) {
      return
    }

    this.addStubToContainer()

    this.uploader.create((error, blob) => {
      if (error) {
        this.remove()
        return
      }

      this.addItemToContainer(blob)
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', (event) => {
      this.directUploadDidProgress(event)
    })
  }

  directUploadDidProgress(event) {
    const prog = parseInt(100.0 * event.loaded / event.total, 10)
    const wrapper = document.querySelector(`.${this.uniqClass}`)
    let progressBar = wrapper.querySelector('.c-form__uploader__file__progress')
    progressBar.style.width = `${prog}%`
  }
}

const renderUploader = (input) => {
  if (input.getAttribute('multiple') !== 'multiple') {
    return
  }

  document.body.addEventListener('click', (event) => {
    const target = dom.findTarget(event.target, 'js-multiupload-remover')
    if (!target) {
      return
    }

    event.preventDefault()
    const parent = dom.parents(target, 'c-form__uploader__file')
    dom.remove(parent)
  })

  input.addEventListener('change', () => {
    const quantityValidate = new Uploader(input.files[0], input)

    if (!quantityValidate.quantityIsValid()) {
      input.value = null
      return
    }

    Array.from(input.files).forEach(file => {
      const uploader = new Uploader(file, input)
      uploader.upload()
    })

    input.value = null
  })
}

document.addEventListener('i18n:onReady', () => {
  const uploaderInputs = document.querySelectorAll('[data-direct-upload-url]')
  dom.forEach(uploaderInputs, renderUploader)
})
