/* global React, PropTypes */


/*eslint-disable no-unused-vars*/
import CompaniesAutosuggest from './CompaniesAutosuggest.jsx'
/*eslint-enable no-unused-vars*/

class Omnibox extends React.Component {
  constructor() {
    super()

    this.onSelect = this.onSelect.bind(this)
    this.onRequest = this.onRequest.bind(this)
    this.updateIncludes = this.updateIncludes.bind(this)
    this.inputs = this.inputs.bind(this)

    this.state = {
      term: '',
      termIncludes: {
        name: true,
        description: true,
        users: true,
      },
      companyBoolean: {
        only_favorite: true,
        only_at_current_event: true
      },
      attachedEvents: false
    }
  }

  onRequest(term) {
    this.setState({ term })
  }

  onSelect(company) {
    window.open(`/companies/${company.id}?only_users_in_event=${
      this.state.companyBoolean.only_at_current_event ? '1' : '0'}`,
      '_blank').focus()
    this.setState({ term: company.name })
  }

  input(key) {
    const selector = `[name="search[term_includes][${key}]"]`
    const all = document.querySelectorAll(selector)
    return all[all.length - 1]
  }

  companyBooleanInput(key) {
    const selector = `[name="search[company_boolean][${key}]"]`
    const all = document.querySelectorAll(selector)
    return all[all.length - 1]
  }

  inputs() {
    const company = this.input('companies')
    const users = this.input('users')
    const onlyFavorite = this.companyBooleanInput('only_favorite')
    const onlyAtCurrentEvent = this.companyBooleanInput('only_at_current_event')

    return {
      company,
      users,
      onlyAtCurrentEvent,
      onlyFavorite
    }
  }

  UNSAFE_componentWillMount() {
    const inputs = this.inputs()
    const keys = Object.keys(inputs)

    if (!this.state.attachedEvents) {
      keys.forEach((key) => {
        inputs[key].addEventListener('change', () => this.updateIncludes())
      })
    }

    this.updateIncludes()
  }

  updateIncludes() {
    const inputs = this.inputs()
    const company = inputs.company.checked
    const users = inputs.users.checked
    const only_favorite = inputs.onlyFavorite.checked
    const only_at_current_event = inputs.onlyAtCurrentEvent.checked

    const termIncludes = {
      name: true,
      description: company,
      users,
    }

    const companyBoolean = {
      only_favorite,
      only_at_current_event
    }

    this.setState({ termIncludes, companyBoolean, attachedEvents: true })
  }

  render() {
    return <div>
      <input type="hidden" name="search[term]" value={this.state.term} />
      <CompaniesAutosuggest
        eventId={window.eventId}
        termIncludes={this.state.termIncludes}
        companyBoolean={this.state.companyBoolean}
        defaultTerm={this.props.term}
        onRequest={this.onRequest}
        onSelect={this.onSelect} />
    </div>
  }
}

Omnibox.propTypes = {
  term: PropTypes.string
}

export default Omnibox
