import * as dom from '../lib/dom.js'

const triggerClick = (event) => {
  if (document.body.offsetWidth >= 720) {
    return
  }

  event.preventDefault()

  const btn = dom.findTarget(event.target, 'js-main-header-trigger')
  const parent = document.querySelector('.c-main-header__nav')

  if (dom.hasClass(parent, 'c-main-header__nav--active')) {
    dom.removeClass(parent, 'c-main-header__nav--active')
    dom.removeClass(btn, 'c-main-header__trigger--active')
    return
  }

  dom.addClass(parent, 'c-main-header__nav--active')
  dom.addClass(btn, 'c-main-header__trigger--active')
}

const submenuClick = (event) => {
  if (document.body.offsetWidth >= 720) {
    return
  }

  event.preventDefault()
  const parent = dom.parents(event.target, 'c-main-header__nav__item')
  const klass = 'c-main-header__nav__item--active'

  if (dom.hasClass(parent, klass)) {
    dom.removeClass(parent, klass)
    return
  }

  dom.addClass(parent, klass)
}

const bindSubTrigger = (subTrigger) => {
  subTrigger.addEventListener('click', submenuClick)
}

const trigger = document.querySelector('.js-main-header-trigger')
trigger.addEventListener('click', triggerClick)

const submenuTriggers = document.querySelectorAll('.js-main-header-submenu-trigger')
dom.forEach(submenuTriggers, bindSubTrigger)
