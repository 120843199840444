import validator from 'validator'

// FIXME: Country codes for the validator package don't include all the
// countries in the country selector, so we have to filter which passportCodes
// can be automatically validated.
const passportCountryCodes = [
  'AM', 'AR', 'AT', 'AU', 'AZ', 'BE', 'BG', 'BR', 'BY', 'CA', 'CH', 'CN', 'CY',
  'CZ', 'DE', 'DK', 'DZ', 'EE', 'ES', 'FI', 'FR', 'GB', 'GR', 'HR', 'HU', 'IE',
  'IN', 'ID', 'IR', 'IS', 'IT', 'JM', 'JP', 'KR', 'KZ', 'LI', 'LT', 'LU',
  'LV', 'LY', 'MT', 'MZ', 'MY', 'MX', 'NL', 'NZ', 'PH', 'PK', 'PL', 'PT', 'RO',
  'RU', 'SE', 'SL', 'SK', 'TH', 'TR', 'UA', 'US', 'ZA',
]

export default function(value, options, fieldName) {
  return null
}

