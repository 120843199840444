import React from 'react'
import PropTypes from 'prop-types'
import { t } from '../../packs/lib/i18n.js'
import axios from '../../packs/lib/axios.js'
import swal from 'sweetalert2'

class SendBox extends React.Component {
  constructor() {
    super()

    this.onSubmit = this.onSubmit.bind(this)

    this.state = {
      sending: false,
      message: ''
    }
  }

  async onSubmit(event) {
    event.preventDefault()

    if (this.state.sending) {
      return
    }

    if (this.state.message.trim() === '') {
      swal(t('error'), t('messages.send_box.message_required'), 'warning')
    }

    this.setState({ sending: true })

    const url = `/messages/${this.props.chatId}/message_pings`

    let response = null

    try {
      response = await axios.post(url, {
        message: { message: this.state.message }
      })
    } catch(err) {
      alert(t('error'), t('unknown_error'), 'error')
      return
    }

    if (!response.data.success) {
      alert(t('error'), response.data.error, 'error')
      return
    }

    this.setState({
      sending: false,
      message: ''
    }, () => {
      this.props.afterSubmit(response.data.message)
    })
  }

  render() {
    let btnKlass = 'c-btn'
    let btnDisabled = false
    if (this.state.sending) {
      btnKlass = 'c-btn c-btn--loading'
      btnDisabled = true
    } else if (this.state.message.trim() === '') {
      btnKlass = 'c-btn c-btn--disabled'
      btnDisabled = true
    }

    return <div className="c-message__send-box">
      <textarea value={this.state.message} onChange={(event) => {
        this.setState({ message: event.target.value })
      }}></textarea>

      {
        btnDisabled
          ? <button
            className={btnKlass}
            type="submit"
            disabled
          >{t('messages.send_box.send')}</button>
          : <button
            className="c-btn"
            type="submit"
            onClick={this.onSubmit}
          >{t('messages.send_box.send')}</button>
      }
    </div>
  }
}

SendBox.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  chatId: PropTypes.number.isRequired
}

export default SendBox
