/* global PropTypes */

const DateBox = ({ date, isActive, onClick }) => (
  <div
    onClick={onClick}
    className={`date${isActive ? ' date__active' : ''}`}>
    {date}
  </div>
)

DateBox.propsTypes = {
  date: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func
}

export default DateBox
