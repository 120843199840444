import React from 'react'
import PropTypes from 'prop-types'
import { t } from '../packs/lib/i18n.js'
import axios from '../packs/lib/axios.js'
import swal from 'sweetalert2'
import localforage from 'localforage'

/*eslint-disable no-unused-vars*/
import Calendar from './Calendar.jsx'
import CalendarDropdown from './CalendarDropdown.jsx'
/*eslint-enable no-unused-vars*/

const dateRangeTypeKey = "DateRangeType"

class CalendarForUser extends React.Component {
  constructor() {
    super()
    this.onTimeSelect = this.onTimeSelect.bind(this)
    this.changeDateRangeType = this.changeDateRangeType.bind(this)
    this.state = {
      dateRangeType: null,
      dates: {}
    }
  }

  async UNSAFE_componentWillMount() {
    let dateRangeType = await localforage.getItem(dateRangeTypeKey)
    const currentDate = Object.values(this.props.dates).find(d => d.current === true)

    if (currentDate) {
      const indexDate = Object.values(this.props.dates).indexOf(currentDate)
      dateRangeType = Object.keys(this.props.dates)[indexDate]
    }

    let type = this.props.availableAttendanceTypes[0]

    const defaultRangeType = type === 'physical' ?
      'meetings' : type === 'talks' ? 'talks' : 'online_meetings'

    dateRangeType = dateRangeType || defaultRangeType

    this.setState({ dateRangeType })
  }

  async onTimeSelect(datetime, cb) {
    swal({
      title: t('calendar_for_user.block_title'),
      text: t('calendar_for_user.optional_and_only_you_can_see'),
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: t('calendar_for_user.confirm'),
      cancelButtonText: t('calendar_for_user.cancel'),
      showLoaderOnConfirm: true,
      preConfirm: (observation) => {
        return axios.post('/time_slices/blocks', {
          time_slice: {
            datetime,
            observation,
            date_range_type: this.state.dateRangeType
          }
        })
        .then((response) => {
          if (!response.data.success) {
            throw new Error(t(response.data.error))
          }

          return response.data
        })
        .catch((error) => {
          swal(t('error'), error.message, 'error')

          return false
        })
      },
      allowOutsideClick: () => !swal.isLoading()
    })
    .then((data) => {
      if (!data.value) {
        return
      }

      swal(t('calendar_for_user.block_created'), '', 'success')
      cb(data.value.react_prop)
    })
  }

  async changeDateRangeType(e, dateRangeType) {
    e.preventDefault()
    this.setState({ dateRangeType })
    await localforage.setItem(dateRangeTypeKey, dateRangeType)
  }

  render() {
    const props = this.props

    if (!this.state.dateRangeType) {
      return ''
    }

    return <div className="c-calendar-wrapper">
      <nav className="c-calendar-wrapper__type">
        {
          this.props.availableAttendanceTypes.map((type) => {
            const dateRangeType = type === 'physical'
              ? 'meetings'
              : type === 'talks'
                ? 'talks'
                : 'online_meetings'

            const buttonTypeClass = this.state.dateRangeType === dateRangeType
              ? 'c-btn--secondary-selected'
              : 'c-btn--secondary'

            return <button
              key={`date_range_button_${type}`}
              className={`c-btn c-btn--sm1 ${buttonTypeClass}`}
              onClick={(e) => this.changeDateRangeType(e, dateRangeType)}
            >
              {t(`calendar_for_user.${dateRangeType}`)}<br />
              <span className="date-tab">
                {
                  t(
                    `calendar_for_user.${dateRangeType}_date`,
                    {
                      date_from: props.dates[dateRangeType]['start_at'],
                      date_to: props.dates[dateRangeType]['end_at']
                    }
                  )
                }
              </span>
            </button>
          })
        }
      </nav>

      {this.state.dateRangeType && props.dates[this.state.dateRangeType].longRangeDateSelect ? (
        <CalendarDropdown
          onTimeSelect={this.onTimeSelect}
          dateRangeType={this.state.dateRangeType}
          disableIfNoMeetings={true}
          listOnlineMeetings={true}
          customDatepickerPlaceholder={t('select_day_placeholder_block_only')}
          {...props}
        />
      ) : (
        <Calendar
          onTimeSelect={this.onTimeSelect}
          dateRangeType={this.state.dateRangeType}
          {...props}
        />
      )}
    </div>
  }
}

CalendarForUser.propTypes = {
  selectableHoverText: PropTypes.string,
  host: PropTypes.number.isRequired,
  availableAttendanceTypes: PropTypes.array.isRequired,
  dates: PropTypes.object.isRequired,
}

export default CalendarForUser
