/*eslint-disable no-unused-vars*/
import React from 'react'
import ReactDOM from 'react-dom'
import DeleteAccountButton from '../components/DeleteAccountButton'
/*eslint-enable no-unused-vars*/

export default function() {
  const button = document.querySelector('[data-react-class="DeleteAccountButton"]')

  if (button) {
    const props = JSON.parse(button.getAttribute('data-react-props'))
    ReactDOM.render(<DeleteAccountButton {...props} />, button)
  }
}
