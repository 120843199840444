import axios from '../lib/axios.js'

function enablePushNotifications() {
  function hasSupport() {
    return 'serviceWorker' in navigator && 'PushManager' in window
  }

  if (hasSupport() !== true) {
    return
  }

  function sendSubscriptionToServer(subscription) {
    let subJson = subscription.toJSON()

    const params = {
      subscription: {
        endpoint: subJson.endpoint,
        keys: {
          auth: subJson.keys.auth,
          p256dh: subJson.keys.p256dh
        }
      }
    }

    axios.post('/web_push/subscriptions', params)
  }

  function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')
    const rawData = atob(base64)
    const outputArray = new Uint8Array(rawData.length)
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }

  function getSubSuccess(swRegistration, subscription) {
    let isSubscribed = !(subscription === null)
    let applicationServerKey = window.vapidKey

    if (!isSubscribed) {
      return swRegistration
        .pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(applicationServerKey)
        })
        .then(sendSubscriptionToServer)
        .catch(() => console.log('Push notifications blocked'))
    }

    sendSubscriptionToServer(subscription)
  }

  function getSubError(err) {
    console.log('getSubscription failed', err)
  }

  function addedServiceWorker(swRegistration) {
    swRegistration.pushManager
      .getSubscription()
      .then(function(sub) {
        getSubSuccess(swRegistration, sub)
      })
      .catch(getSubError)
  }

  function addServiceWorkerError(err) {
    console.log('serviceWorker.register failed', err)
  }

  navigator
    .serviceWorker
    .register('/service-worker.js', { scope: './' })
    .then(addedServiceWorker)
    .catch(addServiceWorkerError)
}

enablePushNotifications()
