import React from 'react'
import PropTypes from 'prop-types'
import { t } from '../../packs/lib/i18n.js'
import axios from '../../packs/lib/axios.js'
import swal from 'sweetalert2'

/*eslint-disable no-unused-vars*/
import EntityAutosuggest from '../../packs/components/EntityAutosuggest.jsx'
import SelectedUsers from './SelectedUsers.jsx'
/*eslint-enable no-unused-vars*/

class NewChat extends React.Component {
  constructor(props) {
    super(props)

    this.onUserSelect = this.onUserSelect.bind(this)
    this.onUserRemove = this.onUserRemove.bind(this)
    this.updateInputValue = this.updateInputValue.bind(this)
    this.submit = this.submit.bind(this)

    this.state = {
      submitting: false,
      users: props.suggestedUsers || [],
      title: null,
      initialMessage: null
    }
  }

  onUserSelect({ id, name }) {
    const users = [].concat(this.state.users, [{ id, name }])
    this.setState({ users })
  }

  onUserRemove(id) {
    const users = this.state.users.filter((user) => user.id !== id)
    this.setState({ users })
  }

  updateInputValue(field, value) {
    const changeObj = {}
    changeObj[field] = value
    this.setState(changeObj)
  }

  async submit(event) {
    event.preventDefault()

    if (this.state.submitting) {
      return
    }

    if (this.state.users.length === 0) {
      swal(t('error'), t('messages.new_chat.participants_required'), 'warning')
      return
    }

    if (!this.state.initialMessage) {
      swal(t('error'), t('messages.new_chat.message_required'), 'warning')
      return
    }

    this.setState({ submitting: true })

    const url = '/messages.json'
    const response = await axios.post(url, {
      chat: {
        user_ids: this.state.users.map((x) => x.id),
        initial_message: this.state.initialMessage,
        title: this.state.title
      }
    })

    if (!response.data.success) {
      swal(t('error'), response.data.error, 'error')
      return
    }

    this.setState({ submitting: false }, () => {
      this.props.afterCreation(response.data.id)
    })
  }

  render() {
    return <section className="c-messages__current-chat c-messages__current-chat--active">
      <header className="c-messages__current-chat__header">
        <button
          type="back"
          className="c-btn c-btn--negative c-btn--sm1 c-btn--borderless"
          onClick={(event) => {
            event.preventDefault()
            this.props.backToList()
          }}
        >&laquo;</button>

        <h1>{ t('messages.new_chat.title') }</h1>
      </header>

      <main className="c-messages__current-chat__content c-messages__new-chat c-form">
        <div className="c-messages__current-chat__wrapper">
          <div className="c-messages__new-chat__step c-form__field">
            <label>{ t('messages.new_chat.participants') }</label>

            <EntityAutosuggest
              url="/helper_apis/users_by_name?include_companies=1"
              selectedIds={this.state.users.map((x) => x.id)}
              onSelect={this.onUserSelect}
              resetAfterSelect={true}
            />

            <SelectedUsers
              users={this.state.users}
              onRemove={this.onUserRemove}
            />
          </div>

          {
            this.state.users.length <= 1
              ? ''
              : <div className="c-messages__new-chat__step">
                <div className="c-form__field">
                  <label>{ t('messages.new_chat.choose_title') }</label>
                  <input
                    type="text"
                    onChange={(event) => this.updateInputValue('title', event.target.value)}
                  />
                </div>
              </div>
          }

          <div className="c-messages__new-chat__step">
            <div className="c-form__field">
              <label>{ t('messages.new_chat.initial_message') }</label>
              <textarea
                onChange={(event) => this.updateInputValue('initialMessage', event.target.value)}
              ></textarea>
            </div>
          </div>

          <div className="u-ta-right">
            {
              this.state.submitting
                ? <button
                  className="c-btn c-btn--loading"
                  disabled
                  onClick={(e) => e.preventDefault()}
                  type="submit">
                  {t('sending')}&hellip;
                </button>
                : <button
                  className="c-btn"
                  onClick={this.submit}
                  type="submit">
                  {t('messages.new_chat.submit')}
                </button>
            }
          </div>
        </div>
      </main>
    </section>
  }
}

NewChat.propTypes = {
  afterCreation: PropTypes.func.isRequired,
  backToList: PropTypes.func.isRequired
}

export default NewChat
