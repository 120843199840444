/*eslint-disable no-unused-vars*/
import React from 'react'
import ReactDOM from 'react-dom'
import CalendarForUser from '../../components/CalendarForUser.jsx'
/*eslint-enable no-unused-vars*/

export default function() {
  const target = document.querySelector('[data-react-class="CalendarForUser"]')

  if (target) {
    const props = JSON.parse(target.getAttribute('data-react-props'))
    ReactDOM.render(<CalendarForUser {...props} />, target)
  }
}
