messageActionClick = (event) => {
    const id = event.target.dataset.id
    const href = event.target.dataset.href
    
    messageClosed(id)
    window.location.href = href
}

messageIgnoreClick = (event) => {
    const id = event.target.dataset.id
    messageClosed(id)
}

messageClosed = (idMessage) => {
    const box = document.getElementById(idMessage)
    const message = box.getElementsByClassName("showActionMessage")[0].textContent.trim()
    const messagesClosed = getMessageClosedStoraged()

    messagesClosed[idMessage] = message
    saveMessageClosedStoraged(messagesClosed)
    
    box.parentNode.removeChild(box)
}

getMessageClosedStoraged = () => {
    const messagesClosed = localStorage.getItem("matchmaking-messageclosed")

    if (messagesClosed)
        return JSON.parse(messagesClosed)
    
    return {}
}

saveMessageClosedStoraged = (messagesClosed) => {
    localStorage.setItem("matchmaking-messageclosed", JSON.stringify(messagesClosed));
}

document.addEventListener("DOMContentLoaded", function(event) {
    
    const messagesClosed = getMessageClosedStoraged()
    let messageActionBoxes = document.getElementsByClassName("messageActionBox")
    const listToRemove = []

    for (let box of messageActionBoxes) {
        let id = box.id
        let message = box.getElementsByClassName("showActionMessage")[0].textContent.trim()
        
        if (id in messagesClosed && messagesClosed[id] == message) {
            listToRemove.push(id)
        }
    }

    for (let idToRemove of listToRemove) {
        const toRemove = document.getElementById(idToRemove)
        toRemove.parentNode.removeChild(toRemove)
    }
    

    let actionsButtons = document.getElementsByClassName("messageActionButton")
    for (let button of actionsButtons) {
        button.addEventListener("click", messageActionClick, false);
    }

    let ignoreButtons = document.getElementsByClassName("messageIgnoreButton")
    for (let button of ignoreButtons) {
        button.addEventListener("click", messageIgnoreClick, false);
    }
});