/* global React, PropTypes, i18n, axios */

import TableAlert from './FirstStep/TableAlert.jsx'
import GuestName from './FirstStep/GuestName.jsx'
import gatherOnlineInformation from './FirstStep/gatherOnlineInformation.js'

const t = i18n.t

class FirstStep extends React.Component {
  constructor() {
    super()

    this.changeCreationValue = this.changeCreationValue.bind(this)
    this.onNext = this.onNext.bind(this)
    this.isCreationDataValid = this.isCreationDataValid.bind(this)
    this.onGuestChange = this.onGuestChange.bind(this)
    this.errors = []
    this.state = {
      status: 'loading',
      data: {
        hostCompanyUsers: [],
        targetCompanyUsers: []
      },
      error: null,
      creationData: {
        guests: [],
        guestData: [],
        hostData: {}
      }
    }
  }

  isCreationDataValid() {
    const data = this.state.creationData
    this.errors = []

    if (data.guests.length < 1) {
      this.errors.push('needs_target_company_guests')
    }

    const targetCompanyGuests = this
      .state
      .data
      .targetCompanyUsers.map((x) => x.id)
      .filter((x) => data.guests.includes(x))

    if (targetCompanyGuests <= 0 && !this.errors.includes('needs_target_company_guests')) {
      this.errors.push('needs_target_company_guests')
    }

    return this.errors.length === 0
  }

  changeCreationValue(val) {
    let creationData = Object.assign({}, this.state.creationData, val)
    this.setState({ creationData })
  }

  onNext(e) {
    e.preventDefault()
    let { creationData } = this.state
    this.props.onNext(creationData)
  }

  componentDidMount() {
    this.data()
  }

  async data() {
    if (this.state.status !== 'loading') {
      return ''
    }

    try {
      const response = await axios.get('/time_slices/possible_guests.json', {
        params: {
          user_id: this.props.userId,
          company_id: this.props.companyId
        }
      })
      const data = response.data
      const hostData = data.host_company_users.find((x) => x.id === this.props.userId)
      const creationData = { ...this.state.creationData, hostData }

      this.setState({
        status: 'loaded',
        data: {
          hostCompanyUsers: data.host_company_users,
          targetCompanyUsers: data.target_company_users,
          buyableSpotsCount: data.buyable_spots_count
        },
        creationData
      })
    } catch (err) {
      this.setState({
        status: 'error',
        error: err.response.data.error || t('error_please_try_later'),
      })
    }
  }

  onGuestChange(guest) {
    const selectedGuests = this.state.creationData.guests
    let creationData = Object.assign({}, this.state.creationData)

    if (selectedGuests.includes(guest.id)) {
      creationData.guests = creationData
        .guests
        .filter((x) => x !== guest.id)

      creationData.guestData = creationData
        .guestData
        .filter((x) => x.id !== guest.id)

      this.setState({ creationData })
      return
    }

    creationData.guests.push(guest.id)
    creationData.guestData.push(guest)

    this.setState({ creationData })
  }

  guestGroups(hostId, data) {
    const hostGuests = data.hostCompanyUsers.filter((x) => x.id !== hostId)
    const targetGuests = data.targetCompanyUsers.filter((x) => x.id !== hostId)

    return [
      {
        title: t('host_guests'),
        guests: hostGuests
      },
      {
        title: t('target_guests'),
        guests: targetGuests
      }
    ]
  }

  render() {
    const { status, data, error } = this.state

    if (!this.props.isActive) {
      return ''
    }

    if (status === 'error') {
      return <div className="c-modal__content c-invite-modal__step1">
        <span>{error}</span>
      </div>
    }

    if (status === 'loading') {
      return <div className="c-modal__content c-invite-modal__step1">
        <span>{t('loading')}&hellip;</span>
      </div>
    }

    const selectedGuests = this.state.creationData.guests
    const guestGroups = this.guestGroups(this.props.userId, data)
    const onlyGuestCompanyUsers = guestGroups[0].guests.length === 0

    return <React.Fragment>
      <div className="c-modal__content c-invite-modal__step1">
        <h2>{t('select_guests')}</h2>
        <div className="c-modal__content__guests">
          { guestGroups.map((guestGroup, index) => {
            if (guestGroup.guests.length === 0) {
              return ''
            }

            return <div key={`guest_group_${index}`}>
              {
                onlyGuestCompanyUsers
                  ? ''
                  : <h3>{guestGroup.title}</h3>
              }
              <ul>
                {
                  guestGroup.guests.map((guest) => (
                    <li key={`invite-modal-guest-${guest.id}`}>
                      <label>
                          <input type="checkbox"
                            onChange={() => this.onGuestChange(guest)}
                            checked={selectedGuests.includes(guest.id)}
                            disabled={!guest.accept_meetings || !guest.can_be_invited}
                          />
                        <GuestName guest={guest} eventAttendanceType={window.eventAttendanceType} />
                      </label>
                    </li>
                  ))
                }
              </ul>
            </div>
          }) }
        </div>

        <TableAlert buyableSpotsCount={data.buyableSpotsCount} />
      </div>
      <footer className="c-modal__footer">
        {
          this.isCreationDataValid()
            ? <button
              className="c-btn c-btn--secondary u-float-right"
              type="next"
              onClick={this.onNext}>
              {t('choose_datetime')}
            </button>
            : <button
              className="c-btn c-btn--secondary c-btn--disabled u-float-right"
              type="next"
              disabled>
              {t('choose_datetime')} &raquo;
            </button>
        }
      </footer>
    </React.Fragment>
  }
}

FirstStep.propTypes = {
  userId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  onNext: PropTypes.func.isRequired,
}

export default FirstStep
