/*eslint-disable no-unused-vars*/
import React from 'react'
import ReactDOM from 'react-dom'
import RegisterBtn from '../components/Schedule/RegisterBtn'
/*eslint-enable no-unused-vars*/

export default function() {
  const registerBtn = document.querySelector('[data-react-class="RegisterBtn"]')
  if (registerBtn) {
    const props = JSON.parse(registerBtn.getAttribute('data-react-props'))
    ReactDOM.render(<RegisterBtn {...props} />, registerBtn)
  }
}
