import calendar from '../react-automontables/calendar.js'
import inviteBox from '../react-automontables/invite-box.js'
import omnibox from '../react-automontables/omnibox.js'
import messages from '../react-automontables/messages.js'
import schedule from '../react-automontables/schedule.js'
import talk from '../react-automontables/schedule_talk.js'
import registerBtn from '../react-automontables/talk_register_btn.js'
import readMoreBtn from '../react-automontables/ticket_read_more_btn.js'
import duplicatedDocumentModal from '../react-automontables/duplicated-document-modal.js'
import deleteAccountButton from '../react-automontables/delete-account-button.js'

export default function() {
  calendar()
  inviteBox()
  omnibox()
  messages()
  schedule()
  talk()
  registerBtn()
  readMoreBtn()
  duplicatedDocumentModal()
  deleteAccountButton()
}
