import React from 'react'
import PropTypes from 'prop-types'

class Modal extends React.Component {
  constructor() {
    super()

    this.onClose = this.onClose.bind(this)
  }

  onClose(event) {
    event.preventDefault()
    this.props.onClose()
  }

  render() {
    const {
      title,
      children,
      footerComponent,
      componentKlass,
      isOpen
    } = this.props

    if (!isOpen) {
      return ''
    }

    const contKlass = componentKlass
      ? `c-modal__content ${componentKlass}`
      : 'c-modal__content'

    const Footer = footerComponent

    return <section className="c-modal c-modal--show-ts" onClick={this.onClose}>
      <div className="c-modal__wrapper" onClick={(event) => event.stopPropagation()}>
        <header className="c-modal__header">
          <h1>{title}</h1>
          <button type="close" onClick={this.onClose}>&times;</button>
        </header>

        <div className={contKlass}>
          {children}
        </div>

        <footer className="c-modal__footer">
          {
            footerComponent
              ? <Footer />
              : ''
          }
        </footer>
      </div>
    </section>
  }
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  componentKlass: PropTypes.string,
  children: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  footerComponent: PropTypes.func
}

export default Modal
