/* global React, PropTypes, i18n, swal, axios */

const t = i18n.t

const TimeSliceStatus = ({ timeSlice, possibleActions, gURL, iCalendar }) => {
  if (!timeSlice.id) {
    return ''
  }

  const { status, starts_at, user_status } = timeSlice
  const userStatus = (user_status === 'canceled') ? 'denied' : user_status

  if (status === 'canceled' || status === 'auto_canceled') {
    return <React.Fragment>
      <div
        className="c-invite-box__status c-invite-box__status--canceled">
        <p><strong>{t(status)}</strong></p>
      </div>
    </React.Fragment>
  }

  if (userStatus === 'denied') {
    return ''
  }

  let statusText = t(status)

  if (status === 'pending' && userStatus != status) {
    statusText = `${statusText} - ${t('waiting_for_other_side')}`
  }

  return <React.Fragment>
    <div
      className={`c-invite-box__status c-invite-box__status--${status}`}>
      <p><strong>{statusText}</strong></p>
      <p><small><strong>{t('starts_at')}</strong>: {starts_at}</small></p>
      <p><small><strong>{t('your_status')}</strong>: {t(userStatus)}</small></p>
      { !possibleActions.includes('invite') &&
        <div className="c-invite-box__status--calendar">
            <a href={gURL} target="_blank">Google</a>
            <a href={iCalendar} target="_blank">Outlook / iCal</a>
        </div>
      }
    </div>
  </React.Fragment>
}

class ActionButtons extends React.Component {
  constructor() {
    super()

    this.data = this.data.bind(this)
    this.onInviteClick = this.onInviteClick.bind(this)
    this.handleActionClick = this.handleActionClick.bind(this)
    this.handleStateAction = this.handleStateAction.bind(this)

    this.state = {
      status: 'loading',
      userCompany: { id: null, name: null },
      targetCompany: { id: null, name: null },
      timeSlice: {
        id: null,
        status: null
      },
      multipleTimeSlices: false,
      processingAction: false,
      possibleActions: ['invite'],
      gURL: null,
      iCalendar: null
    }
  }

  componentDidMount() {
    this.data()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.actionRefresh !== this.props.actionRefresh) {
      this.data()
    }
  }

  async data() {
    try {
      const response = await axios.get('/time_slices/user_vs_company.json', {
        params: {
          user_id: this.props.userId,
          company_id: this.props.companyId,
          slice_id: this.props.sliceId
        }
      })

      this.props.updateParent(response.data.time_slice.host_id)

      // TODO: This is a hack. Move this to react code, when meetings page become react also
      const sliceDate = document.querySelector(`#slice-${this.props.sliceId} .slice__date`)
      const sliceTime = document.querySelector(`#slice-${this.props.sliceId} .slice__time-spread`)
      sliceDate && (sliceDate.innerHTML = response.data.time_slice.human_time_attributes.date)
      sliceTime && (sliceTime.innerHTML = `${response.data.time_slice.human_time_attributes.starts_at_hour} - ${response.data.time_slice.human_time_attributes.ends_at_hour}`)

      this.setState({
        status: 'loaded',
        userCompany: response.data.user_company,
        targetCompany: response.data.target_company,
        timeSlice: response.data.time_slice,
        possibleActions: response.data.possible_actions,
        processingAction: false,
        multipleTimeSlices: response.data.multiple_time_slices,
        gURL: response.data.gURL,
        iCalendar: response.data.iCalendar
      })
    } catch (err) {
      this.setState({
        status: 'error',
        processingAction: false,
      })
    }
  }

  onInviteClick(event) {
    event.preventDefault()
    this.props.onInviteClick(this.state)
  }

  async handleStateAction(action) {
    if (this.state.processingAction) {
      return
    }

    await this.setState({ processingAction: true })

    let message = ''

    if (action == 'cancel' || action == 'deny') {
      const confirmation = await swal({
        title: t('are_you_sure_question'),
        text: '',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t('yes_sure'),
        cancelButtonText: t('negative')
      })

      if (!confirmation.value) {
        await this.setState({ processingAction: false })
        return
      }

      /*const messageQuestion = await swal({
        title: t(`do_you_want_to_send_a_message_${action}`),
        text: t('the_message_is_optional'),
        input: 'textarea',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: t(`${action}_meeting`),
        cancelButtonText: t('give_up_and_keep_meeting')
      })

      if (messageQuestion.dismiss) {
        await this.setState({ processingAction: false })
        return
      }

      message = messageQuestion.value*/
      message = ""
    }

    const timeSliceId = this.state.timeSlice.id
    const url = `/time_slices/${timeSliceId}/users/${this.props.userId}`
    const response = await axios.patch(url, {
      time_slice_users: { action, message }
    })

    if (!response.data.success) {
      swal({
        title: t('error'),
        type: 'warning',
        text: t(response.data.error),
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: 'OK'
      })

      await this.setState({ processingAction: false })

      return
    }

    this.data()
  }

  handleActionClick(event, action) {
    event.preventDefault()

    switch (action) {
    case 'edit':
      this.props.onShowClick(this.state)
      break
    case 'show':
      this.props.onShowClick(this.state)
      break
    case 'cancel':
      this.handleStateAction(action)
      break
    case 'confirm':
      this.handleStateAction(action)
      break
    case 'deny':
      this.handleStateAction(action)
      break
    case 'review':
      this.handleStateAction(action)
      break
    default:
      // hey
    }
  }

  render() {
    const {
      status,
      possibleActions,
      timeSlice,
      multipleTimeSlices,
      userCompany,
      targetCompany,
      gURL,
      iCalendar
    } = this.state

    const { sliceId } = this.props

    if (status === 'error') {
      return <button type="alert" className="c-btn c-btn--disabled" disabled>
        {t('error_please_try_later')}
      </button>
    }

    if (status === 'loading') {
      return <button type="alert" className="c-btn c-btn--disabled" disabled>
        {t('loading')}...
      </button>
    }

    if (userCompany && userCompany.id === targetCompany.id) {
      return <div className="c-invite-box">
        <span className="c-invite-box__status c-invite-box__status--pending">
          {t('this_is_your_company')}
        </span>
      </div>
    }

    if ((!sliceId || sliceId <= 0) && multipleTimeSlices) {
      return <div className="c-invite-box">
        <span className="c-invite-box__status c-invite-box__status--pending">
          {t('multiple_meetings_with_company')}
          <small className="u-display-block u-mt1"><a href="/meetings">{t('check_them_here')}</a></small>
        </span>
      </div>
    }

    if (this.props.chat) {
      if (possibleActions.includes('invite')) {
        return (
          <button
            type="alert"
            onClick={this.onInviteClick}
            className="c-btn c-btn--invite">
              {t('invite')}
          </button>
        )
      }

      return (
        <button
          type="alert"
          onClick={(e) => this.handleActionClick(e, 'show')}
          className="c-btn c-btn--show">
          {t('show_meeting')}
        </button>
      )
    }

    return <div className="c-invite-box">
      {<TimeSliceStatus timeSlice={timeSlice} possibleActions={possibleActions} gURL={gURL} iCalendar={iCalendar} />}

      {
        possibleActions.includes('invite') && timeSlice.is_latest
          ? <button
            type="alert"
            onClick={this.onInviteClick}
            className="c-btn c-btn--invite">
            {t('invite')}</button>
          : ''
      }

      <div className="c-invite-box__actions">
        {
          possibleActions
            .filter(x => x !== 'invite')
            .map((action, index) => {
              return <button
                key={`actions-${index}`}
                type="alert"
                onClick={(e) => this.handleActionClick(e, action)}
                className={`c-btn c-btn--${action}`}>
                {t(action)}</button>
            })
        }
      </div>
    </div>
  }
}

ActionButtons.propTypes = {
  userId: PropTypes.number,
  companyId: PropTypes.number,
  sliceId: PropTypes.number,
  chat: PropTypes.bool,
  updateParent: PropTypes.func
}

export default ActionButtons
