/* global dom, formToObject */

import validate from './validate.js'
import errorPlacer from './errorPlacer.js'

let validateFormTO = null
let validationRunOnce = false
var formsValidators = {}

const validateForm = function(form, constraints, autoSubmit, onSuccess, onError, disableButtonsOnSubmit = false) {
  if (typeof onSuccess === 'undefined') {
    onSuccess = () => {
      form.submit()
    }
  }

  if (typeof onError === 'undefined') {
    onError = () => {
      if (disableButtonsOnSubmit) {
        enableButtons(form);
      }
    }
  }

  validationRunOnce = true

  if (validateFormTO !== null) {
    clearTimeout(validateFormTO)
    validateFormTO = null
  }

  validateFormTO = setTimeout(async function() {
    clearTimeout(validateFormTO)
    validateFormTO = null

    const values = formToObject(form)

    try {
      await validate.async(values, constraints, { format: 'custom' })
      errorPlacer('clear_errors')

      if (autoSubmit) {
        onSuccess(values)
      }
    } catch(errors) {
      try {
        errorPlacer(errors)
      } catch (errs) {
        console.warn(errs)
      }

      if (autoSubmit) {
        onError(errors)
      }

      return false
    }

    return true
  }, 300)
}

const revalidateForm = function(form, constraints, onSuccess, onError) {
  if (!validationRunOnce) {
    return
  }

  validateForm(form, constraints, false, onSuccess, onError)
}

const onSubmit = function(event, constraints, onSuccess, onError, disableButtonsOnSubmit = false) {
  event.preventDefault()

  if (disableButtonsOnSubmit) {
    disableButtons(event.target);
  }

  validateForm(event.target, constraints, true, onSuccess, onError, disableButtonsOnSubmit)
}

export default function(selector, constraints, onSuccess, onError, disableButtonsOnSubmit = false) {
  const form = document.querySelector(selector)

  if (!form) {
    return
  }

  const fields = form.querySelectorAll('select, input, textarea')

  if (selector in formsValidators) {
    form.removeEventListener('submit', formsValidators[selector].submit)
    form.removeEventListener('uploader:revalidateForm', formsValidators[selector].revalidateForm)

    dom.forEach(fields, (field) => {
      field.removeEventListener('change', formsValidators[selector].revalidateForm)
      field.removeEventListener('blur', formsValidators[selector].revalidateForm)
      field.removeEventListener('keyup', formsValidators[selector].revalidateForm)
      field.removeEventListener('keydown', formsValidators[selector].revalidateForm)
    })

    delete formsValidators[selector]
  }

  var formEvents = {
    submit: (event) => onSubmit(event, constraints, onSuccess, onError, disableButtonsOnSubmit),
    revalidateForm: () => { revalidateForm(form, constraints) }
  }

  form.addEventListener('submit', formEvents.submit)
  form.addEventListener('uploader:revalidateForm', formEvents.revalidateForm)

  dom.forEach(fields, (field) => {
    field.addEventListener('change', formEvents.revalidateForm)
    field.addEventListener('blur', formEvents.revalidateForm)
    field.addEventListener('keyup', formEvents.revalidateForm)
    field.addEventListener('keydown', formEvents.revalidateForm)
  })

  formsValidators[selector] = formEvents
}

function enableButtons(form) {
  const buttons = form.querySelectorAll('button');
  buttons.forEach(button => {
    button.disabled = false;
  });
}

function disableButtons(form) {
  const buttons = form.querySelectorAll('button');
  buttons.forEach(button => {
    button.disabled = true;
  });
}
