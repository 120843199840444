import * as dom from '../../lib/dom.js'
import ReactDOM from 'react-dom'

/*eslint-disable no-unused-vars*/
import CompanyPicker from '../../components/CompanyPicker.jsx'
/*eslint-enable no-unused-vars*/

document.addEventListener('i18n:onReady', () => {
  const companyPicker = document.querySelector('.js-company-picker')
  if (companyPicker) {
    ReactDOM.render(<CompanyPicker />, companyPicker)
  }
})

const getRepValue = () => {
  const repInputs = document.querySelectorAll('[name="company_choice[represent_a_company_fake]"]')
  let val = null

  dom.forEach(repInputs, (input) => {
    if (!input.checked) {
      return
    }

    val = input.value
  })

  return val ? val : '0'
}

const onInputChange = (event) => {
  const form = document.querySelector('.c-form--company-choices')

  if (getRepValue() === '1') {
    dom.removeClass(form, 'u-hidden')
    return
  }

  dom.addClass(form, 'u-hidden')
}



const sameCompanyClick = () => {
  /*document.getElementById('divCoChoiceSame').style.display = "block"

  let divCoChoice = document.getElementById('divCoChoice')
  divCoChoice.parentNode.removeChild(divCoChoice)

  let divMyCompany = document.getElementById('divMyCompany')
  divMyCompany.parentNode.removeChild(divMyCompany)*/

  
}

const otherCompanyClick = () => {
  document.getElementById('divCoChoice').style.display = "block"
  let divMyCompany = document.getElementById('divMyCompany')
  divMyCompany.parentNode.removeChild(divMyCompany)
}

dom.ready(() => {
  const repInputs = document.querySelectorAll('[name="company_choice[represent_a_company_fake]"]')
  dom.forEach(repInputs, (input) => {
    input.addEventListener('change', onInputChange)
  })

  if (repInputs.length > 0) {
    onInputChange()
  }

  if (document.getElementById('divMyCompany')) {
    document.getElementById('divCoChoice').style.display = "none";
  }

  let buttonCompanyYes = document.getElementById('buttonCompanyYes')
  let buttonCompanyNo = document.getElementById('buttonCompanyNo')


  if (buttonCompanyYes)
    document.getElementById('buttonCompanyYes').addEventListener('click', sameCompanyClick)

  if (buttonCompanyNo)
    document.getElementById('buttonCompanyNo').addEventListener('click', otherCompanyClick)
})
