/* global dom, axios, swal, i18n */

import setValidations from '../../lib/setValidations.js'
import getCheckoutType from './getCheckoutType.js'

const t = i18n.t
let submitting = false

const constraints = {
  'ticket_order.agreements.terms_of_service': {
    presence: true,
    inclusion: {
      within: ['1']
    }
  },
  'ticket_order.agreements.know_the_conditions': {
    presence: true,
    inclusion: {
      within: ['1']
    }
  }
}

const constraintsSpeakers = {
  ...constraints,
  'ticket_order.agreements.image_rights': {
    presence: true,
    inclusion: {
      within: ['1']
    }
  }
}

const handleStripePayment = async ({ external_invoice_id }) => {
  const stripe = Stripe(window.stripePublicKey)
  return stripe.redirectToCheckout({ sessionId: external_invoice_id });
}

const handleSubmissionSuccess = () => {
  swal({
    title: t('order_created'),
    type: 'success',
    text: t('order_instructions'),
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    confirmButtonText: t('order_ok')
  })
    .then(() => {
      window.location = '/company_choices/new'
    })
}

const setGlobalState = () => {
  const submitBtn = document.querySelector('.c-form--ticket-choices button[type=submit]')

  const loading = () => {
    submitting = true
    submitBtn.innerHTML = `${t('processing')}...`
    submitBtn.setAttribute('disabled', 'disabled')
    dom.addClass(submitBtn, 'c-btn--loading')
  }

  const done = () => {
    submitting = false
    submitBtn.innerHTML = t('confirm_ticket_choice')
    submitBtn.removeAttribute('disabled')
    dom.removeClass(submitBtn, 'c-btn--loading')
  }

  return {
    loading,
    done
  }
}

const onValidationSuccess = async function(params) {
  if (submitting) {
    return
  }

  setGlobalState().loading()

  const submitBtn = document.querySelector('.c-form--ticket-choices button[type=submit]')
  const isFree = submitBtn.getAttribute('data-is-free') === 'true'

  const form = document.querySelector('.c-form--ticket-choices')
  const url = form.getAttribute('action')

  try {
    const response = await axios.post(url, params)

    setGlobalState().done()

    if (response.data.success) {
      if (response.data.redirect_link) {
        window.location.href = response.data.redirect_link
        return
      }

      if (response.data.processor === 'stripe') {
        return handleStripePayment(response.data)
      }

      handleSubmissionSuccess()
      return
    }

    swal({
      title: t('error'),
      type: 'error',
      text: response.data.error,
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'OK',
    })
  } catch (err) {
    setGlobalState().done()

    swal({
      title: t('error'),
      type: 'error',
      text: t('unknown_error'),
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      confirmButtonText: 'OK'
    })
  }
}

dom.ready(() => {
  setValidations('.c-form--ticket-choices', constraints, onValidationSuccess)
})

export default {
  constraints,
  constraintsSpeakers,
  onValidationSuccess
}