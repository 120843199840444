/*eslint-disable no-unused-vars*/
import React from 'react'
import MessagesNavWrapper from './MessagesNavWrapper.jsx'
/*eslint-enable no-unused-vars*/

import PropTypes from 'prop-types'
import { t } from '../../packs/lib/i18n.js'
import { truncate } from '../../packs/lib/string.js'

const MessagesNav = ({ loading, chats, newMessageInstance, onSelect, currentChatId }) => {
  if (chats.length === 0) {
    return(
      <MessagesNavWrapper
        newMessageInstance={newMessageInstance}
        isEmpty={true}
      >
        <p>
          {loading ? '...' : t('messages.messages_nav.no_chats_yet')}
        </p>
      </MessagesNavWrapper>
    )
  }

  return <MessagesNavWrapper
    newMessageInstance={newMessageInstance}
    isEmpty={false}>
    <ul className="c-messages__chats__list">
      { chats.map((chat, index) => {
        let thumbKlass = 'c-messages__prechat__thumb'
        thumbKlass = (chat.multipleUsers) ? `${thumbKlass} ${thumbKlass}--multiple` : thumbKlass
        const last = chat.lastMessage
        const msgLimit = 26 - last.sender.length - ': '.length

        let liKlass = 'c-messages__prechat'
        if (chat.id === currentChatId) {
          liKlass = `${liKlass} ${liKlass}--selected`
        } else if (chat.unreadCount > 0) {
          liKlass = `${liKlass} ${liKlass}--unread`
        }

        return <li className={liKlass} key={`chat_preview_${index}`}>
          <div
            className="c-messages__prechat__item_container"
            onClick={() => { onSelect(chat.id) }}
          >
            <div className={thumbKlass}>
              <img src={chat.avatar} />
            </div>

            <div className="c-messages__prechat__content">
              <h2>{chat.title} <span className="c-messages__prechat__company">({chat.companyName})</span></h2>
              {
                last.sender !== ''
                  ? <p><strong>{last.sender}:</strong> {truncate(last.message, msgLimit)}</p>
                  : <p>{t('messages.messages_nav.conversation_empty')}</p>
              }
            </div>

            <div className="c-messages__prechat__time">
              <p>{last.sentAt}</p>
            </div>
          </div>
        </li>
      })}
    </ul>
  </MessagesNavWrapper>
}

MessagesNav.propTypes = {
  loading: PropTypes.bool,
  chats: PropTypes.array.isRequired,
  newMessageInstance: PropTypes.func.isRequired,
  currentChatId: PropTypes.number
}

export default MessagesNav
