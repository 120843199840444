import React from 'react'
import PropTypes from 'prop-types'
import { t } from '../../packs/lib/i18n.js'

const MessagesNavWrapper = ({ newMessageInstance, children, isEmpty }) => {
  const baseKlass = 'c-messages__chats'
  const klass = (isEmpty) ? `${baseKlass} ${baseKlass}--empty` : `${baseKlass}`

  return <nav className={klass}>
    <header className="c-messages__chats__header">
      <h1>{ t('messages.messages_nav_wrapper.title') }</h1>
      <button
        type="add"
        className="c-btn c-btn--negative c-btn--sm1"
        onClick={newMessageInstance}
      >+</button>
    </header>

    <div className="c-messages__chats__content">
      {children}
    </div>
  </nav>
}

MessagesNavWrapper.propTypes = {
  children: PropTypes.object.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  newMessageInstance: PropTypes.func.isRequired
}

export default MessagesNavWrapper
