import React from 'react'
import PropTypes from 'prop-types'
import { t } from '../packs/lib/i18n.js'

/*eslint-disable no-unused-vars*/
import ShowModal from '../packs/components/InviteBox/ShowModal.jsx'
/*eslint-enable no-unused-vars*/

class CalendarDropdownMeeting extends React.Component {
  constructor() {
    super()

    this.state = {
      modalHidden: true
    }
  }

  busyToHumanTime(busy) {
    const attr = busy.human_time_attributes
    return [attr.date, attr.starts_at_hour].join(' - ')
  }

  render() {
    const { busy } = this.props

    return <React.Fragment>
      <div
      className='calendar-dropdown-online-meeting c-btn--secondary'
      onClick={(e) => {
        if (!busy.type.includes('meeting') &&
            busy.type !== 'global_time_slice') {
          return
        }

        if (this.props.disableModals) {
          return
        }

        e.preventDefault()

        this.setState({ modalHidden: false })
      }}
    >
      <div className='meeting-time'>{this.busyToHumanTime(busy)}</div>
      <div className='meeting-name'>{busy.name} </div>
      <div className={`meeting-status c-inline-alert c-inline-alert--${busy.status}`}>{t(busy.status)}</div>

      </div>
      <div>
      {
        busy.type.includes('meeting') &&
          <ShowModal
            onClose={() => this.setState({ modalHidden: true })}
            hidden={this.state.modalHidden}
            userId={this.props.host}
            timeSliceId={busy.id}
            userCompany={busy.user_company}
            targetCompany={busy.target_company}
            companyId={busy.company_id}
            hostId={busy.host_id}
            userCompanyId={busy.user_company.id}
            userCompanyName={busy.user_company.name}
            hostCompanyName={busy.host_company_name}
            targetCompanyId={busy.target_company_id}
            targetCompanyName={busy.target_company_name}
          />
      }
      </div>
      </React.Fragment>
  }
}

CalendarDropdownMeeting.propTypes = {
  host: PropTypes.number.isRequired,
  busy: PropTypes.object.isRequired,
  onRemoveRequest: PropTypes.func,
  disableModals: PropTypes.bool
}

export default CalendarDropdownMeeting
