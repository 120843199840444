/* global swal, dom, axios, i18n, formToObject */

const t = i18n.t

const onSubmit = async function(event) {
  event.preventDefault()
  const form = event.target
  const url = form.getAttribute('action')
  const input = form.querySelector('[name="member[admin]"]')
  const button = form.querySelector('button')
  const makeText = form.getAttribute('data-make-text')
  const unmakeText = form.getAttribute('data-unmake-text')

  if (dom.hasClass(button, 'c-btn--loading')) {
    return
  }

  dom.addClass(button, 'c-btn--loading')
  button.disabled = true

  const result = await axios.patch(`${url}.json`, formToObject(form))

  dom.removeClass(button, 'c-btn--loading')
  button.disabled = false

  if (!result.data) {
    swal(
      t('error'),
      t('update_error'),
      'error'
    )
    return
  }

  if (input.value === '1') {
    input.value = '0'
    button.innerHTML = unmakeText
    dom.removeClass(button, 'c-btn--negative')
    return
  }

  input.value = '1'
  button.innerHTML = makeText
  dom.addClass(button, 'c-btn--negative')
}

const forms = document.querySelectorAll('.js-toggle-user-admin')
dom.forEach(forms, (form) => form.addEventListener('submit', onSubmit))
